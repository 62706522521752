import { trackPageViewInGA } from './googleAnalyticsHelper';
import { trackPageViewInMixpanel } from './mixpanelHelper';
import { trackPageViewInBraze } from './brazeHelper';

/**
 * @param {string} path
 * @param {Object} additionalData
 */
export function logNavigationEvent(path, additionalData = null) {
  trackPageViewInMixpanel(path, additionalData);
  trackPageViewInGA(path);
  trackPageViewInBraze(path);
}

/**
 * @param {string} path
 */
export function logNavigationError(path) {
  trackPageViewInMixpanel('/404', { error: '404', 'path error': path });
}
