// @flow

import * as React from 'react';

export const SPINNER_COLORS = {
  DARK: '#008767',
  LIGHT: '#ffffff',
};

const Spinner = ({ show = false, color = SPINNER_COLORS.DARK }) => {
  if (show) {
    return (
      <svg
        x="0px"
        y="0px"
        width="40px"
        height="40px"
        viewBox="0 0 50 50"
        className="spinner"
      >
        <path
          fill={color}
          d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
        >
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 25 25"
            to="360 25 25"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    );
  }
  return null;
};

export default Spinner;
