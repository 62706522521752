import { track, WEBREG_PREFIX } from '../../../utils/mixpanelHelper';
import { str } from '../../../i18n/utils';
import { I18N_STR } from '../../../i18n/strings';
import {
  setBrazeAttribute,
  BRAZE_ATTRIBUTES,
} from '../../../utils/brazeHelper';

export class AccessCodeSubmissionTracker {
  constructor(code, location, isPrefilled) {
    this.payload = {
      accessCode: code.trim().toLowerCase(),
      'Page Path': location.pathname.trim().toLowerCase(),
      prefilled: isPrefilled.toString(),
    };
  }

  trackValid() {
    const eventPayload = {
      ...this.payload,
      result: 'valid',
    };
    this.trackInMixpanel(true, eventPayload);
    setBrazeAttribute(BRAZE_ATTRIBUTES.ACCESS_CODE, this.payload.accessCode);
  }

  trackNotStarted() {
    const eventPayload = {
      ...this.payload,
      result: 'not-started',
    };
    this.trackInMixpanel(true, eventPayload);
  }

  trackInitiated() {
    const eventPayload = {
      ...this.payload,
      result: null,
      message: 'req-initiated',
    };
    this.trackInMixpanel('', eventPayload);
  }

  trackFull() {
    const eventPayload = {
      ...this.payload,
      result: 'full',
    };
    this.trackInMixpanel(true, eventPayload);
  }

  trackExpired() {
    const eventPayload = {
      ...this.payload,
      result: 'expired',
    };
    this.trackInMixpanel(true, eventPayload);
  }

  trackFail() {
    const eventPayload = {
      ...this.payload,
      result: 'fail',
      message: 'access code is not recognized',
    };
    this.trackInMixpanel(true, eventPayload);
  }

  trackServerError() {
    const eventPayload = {
      ...this.payload,
      result: 'error',
      message: str(I18N_STR.SERVER_ERROR),
    };
    this.trackInMixpanel(false, eventPayload);
  }

  trackInMixpanel(isSuccess, data) {
    const eventPayload = {
      success: isSuccess,
      ...data,
    };
    track(`${WEBREG_PREFIX}:form-submit`, eventPayload);
  }
}
