import { generateNumberRange } from '../app/components/option-range-util';

export const PERSONALIZATION_QUESTIONS = {
  'num-usage-daily': {
    key: 'numCigsDaily',
    options: generateNumberRange(1, 50, 1, 0).map(item => {
      return { key: item, label: item, selected: false };
    }),
  },
  'days-per-week': {
    key: 'daysPerWeek',
    options: generateNumberRange(1, 7, 1, 0).map(item => {
      return { key: item, label: item, selected: false };
    }),
  },
  'avg-pack-cost': {
    key: 'avgPackCost',
    options: generateNumberRange(2, 15, 0.25, 2).map(item => {
      return { key: item, label: item, selected: false };
    }),
  },
  'started-at': {
    key: 'ageStartedSmoking',
    options: generateNumberRange(1, 85, 1, 0).map(item => {
      return { key: item, label: item, selected: false };
    }),
  },
};

export function makeQuestionsAndOptions() {
  const keys = Object.keys(PERSONALIZATION_QUESTIONS);
  const store = {};

  keys.forEach(key => {
    const item = PERSONALIZATION_QUESTIONS[key];
    store[item.key] = item.options;
  });

  return store;
}
