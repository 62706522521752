import React from 'react';
import classnames from 'classnames';
import HeroOtherImage from '../../../../media/landing-page/hero-other-image.png';
import HeroOtherImageWebP from '../../../../media/landing-page/hero-other-image.webp';

import styles from '../../../../css/UnifiedLanding.module.scss';

export default function HeroOther() {
  return (
    <div className={classnames(styles.heroRightImage, styles.other)}>
      <div>
        <picture>
          <source srcSet={HeroOtherImageWebP} type="image/webp" />
          <img src={HeroOtherImage} alt="" height="618" width="591" />
        </picture>
      </div>
    </div>
  );
}
