import { ACTION_TYPES } from '../../../../state/actions';
import { store } from '../../../../state/store';
import { MESSAGE } from '../../../constants';
import { getAvailabilityDate } from '../common-components/AvailabilityInfo';
import { str } from '../../../i18n/utils';
import { I18N_STR } from '../../../i18n/strings';

export function isAccessCodeActiveByDate(start, end) {
  const now = new Date();
  const startTime = new Date(start);
  const endTime = new Date(end);
  return now >= startTime && now < endTime;
}

export function dispatchAccessCodeFetchSuccess(res) {
  store.dispatch({ type: ACTION_TYPES.SET_ACCESS_CODE_CONFIG, payload: res });
}

export function dispatchAccessCodeExpired() {
  store.dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: {
      message: str(I18N_STR.ACCESS_CODE_EXPIRED),
      type: MESSAGE.TYPE.ERROR,
    },
  });
}

export function dispatchAccessCodeFetchFail() {
  store.dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: {
      message: str(I18N_STR.ACCESS_CODE_NOT_RECOGNIZED),
      type: MESSAGE.TYPE.ERROR,
    },
  });
}

export function dispatchAccessCodeNotStarted(startTime) {
  store.dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: {
      message: getAvailabilityDate(startTime),
      type: MESSAGE.TYPE.INFO,
    },
  });
}
