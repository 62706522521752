import React from 'react';
import { useSelector } from 'react-redux';

export const MessageBar = () => {
  const message = useSelector(state => state.message);
  return (
    <div
      className={`server-message type-${message?.type}`}
      aria-live="assertive"
    >
      {message?.content}
    </div>
  );
};
