import * as React from 'react';
import { REG_LAST_STEP_NUM } from '../../../constants';
import PortalModal from '../../../components/PortalModal';
import { validateEmail } from '../../../utils/validation';
import { submitToShortlist } from '../account-creation/utils';
import { track, WEBREG_PREFIX } from '../../../utils/mixpanelHelper';
import ButtonWithSpinner from '../../../components/ButtonWithSpinner';
import { I18N_STR } from '../../../i18n/strings';
import { str } from '../../../i18n/utils';

type Props = {
  currentFrame: number,
  accessCode: string,
};

type State = {
  email: string,
  email_msg: string,
  email_valid: boolean,
  isUserTryingToLeave: boolean,
  exitIntentModalActivatedOnce: boolean,
  submissionMessage: string,
  mouseX: number,
  mouseY: number,
  isSendingData: boolean,
};

const MOUSEY_TRESHOLD = 10;

export default class ExitIntentModal extends React.Component<Props, State> {
  state = {
    email: '',
    email_msg: '',
    email_valid: false,
    isUserTryingToLeave: false,
    exitIntentModalActivatedOnce: false,
    submissionMessage: '',
    mouseX: 0,
    mouseY: 0,
    isSendingData: false,
    canBeActivated: false,
  };

  static defaultProps = {
    currentFrame: 1,
    accessCode: '',
  };

  //refs
  backdrop: ?HTMLDivElement;

  onMouseMove = (e: MouseEvent) => {
    this.setState({
      mouseX: e.clientX,
      mouseY: e.clientY,
    });
  };

  onMouseLeave = (e: MouseEvent) => {
    if (e.clientY <= MOUSEY_TRESHOLD) {
      if (
        this.state.isUserTryingToLeave === false &&
        this.state.exitIntentModalActivatedOnce === false &&
        this.props.currentFrame < REG_LAST_STEP_NUM - 1 &&
        this.state.canBeActivated
      ) {
        // Frame 4 is Account Creation Form
        const timeout = this.props.currentFrame < 4 ? 1000 : 5000;
        window.setTimeout(() => {
          if (this.state.mouseY < MOUSEY_TRESHOLD) {
            this.setState({
              isUserTryingToLeave: true,
              exitIntentModalActivatedOnce: true,
            });
          }
        }, timeout);
      }
    }
  };

  componentDidMount() {
    window.setTimeout(() => {
      //   this.setState({canBeActivated: true})
    }, 30000);
    // This is done to catch when the mouse leaves the browser UP towards the X button to close the tab/window.. exit intent
    // $FlowFixMe
    document
      .querySelector('html')
      .addEventListener('mousemove', this.onMouseMove);
    // $FlowFixMe
    document
      .querySelector('html')
      .addEventListener('mouseleave', this.onMouseLeave, false);
  }

  componentWillUnmount() {
    // $FlowFixMe
    document
      .querySelector('html')
      .removeEventListener('mousemove', this.onMouseMove);
    // $FlowFixMe
    document
      .querySelector('html')
      .removeEventListener('mouseleave', this.onMouseLeave);
  }

  handleEmailCheck = (e: SyntheticInputEvent<HTMLInputElement>) => {
    (e.currentTarget: HTMLInputElement); // eslint-disable-line no-unused-expressions
    if (validateEmail(e.currentTarget.value) === false) {
      this.setState({
        email_msg: str(I18N_STR.ENTER_VALID_EMAIL),
        email_valid: false,
      });
    } else {
      this.setState({
        email_msg: '',
        email_valid: true,
      });
    }
  };

  submitEmailToShortlist = (e: SyntheticEvent<HTMLButtonElement>) => {
    if (validateEmail(this.state.email) === true) {
      this.setState({ isSendingData: true });
      submitToShortlist(
        'ExitIntent',
        `Page: ${this.props.currentFrame}`,
        this.state.email,
        this.props.accessCode
      )
        .then(res => {
          this.setState(
            () => {
              if (!!res.result && res.result === 'success') {
                return {
                  submissionMessage: str(I18N_STR.THANKS),
                  isSendingData: false,
                };
              } else {
                return {
                  submissionMessage: str(
                    I18N_STR.SOMETHING_WRONG_CONTACT_SUPPORT
                  ),
                  isSendingData: false,
                };
              }
            },
            () => {
              window.setTimeout(() => {
                this.closeModal();
              }, 1500);
            }
          );
        })
        .catch(err => {
          this.setState({
            submissionMessage: str(I18N_STR.SOMETHING_WRONG_CONTACT_SUPPORT),
            isSendingData: false,
          });
          console.error(err);
        });
      track(`${WEBREG_PREFIX}:exit-intent-submit`);
    } else {
      this.setState({
        email_msg: str(I18N_STR.ENTER_VALID_EMAIL),
        email_valid: false,
      });
    }
  };

  handleOnChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    (e.currentTarget: HTMLInputElement); // eslint-disable-line no-unused-expressions
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  closeModal() {
    this.setState({ isUserTryingToLeave: false });
  }

  closeModalFromBackdrop = (e: SyntheticEvent<HTMLDivElement>) => {
    (e.currentTarget: HTMLDivElement); // eslint-disable-line no-unused-expressions
    if (e.target === this.backdrop) {
      this.closeModal();
    }
  };

  render() {
    if (
      this.state.isUserTryingToLeave === false ||
      this.props.currentFrame > 4
    ) {
      return null;
    }
    return (
      <PortalModal>
        <div
          className="obstacle-modal exit-intent"
          onClick={this.closeModalFromBackdrop}
          ref={node => (this.backdrop = node)}
        >
          <div className="inner-modal wrapper">
            <button
              aria-label={str(I18N_STR.close)}
              className="close-modal-button"
              onClick={() => {
                track(`${WEBREG_PREFIX}:close-modal-button`);
                this.closeModal();
              }}
            />
            <h3>{str(I18N_STR.exitIntentModalTitle)}</h3>
            <p>{str(I18N_STR.exitIntentModalCopy)}</p>
            <div className="input-with-button">
              <input
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleOnChange}
              />
              <ButtonWithSpinner
                className="shortlist-submit-button"
                label={str(I18N_STR.submit)}
                isSendingData={this.state.isSendingData}
                onClick={this.submitEmailToShortlist}
              />
            </div>
            {this.state.email_msg && (
              <div>
                <span className="input-message">{this.state.email_msg}</span>
              </div>
            )}
            {this.state.submissionMessage && (
              <div>
                <span className="-input-message">
                  {this.state.submissionMessage}
                </span>
              </div>
            )}
          </div>
        </div>
      </PortalModal>
    );
  }
}
