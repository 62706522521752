import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './state/store';
import App from './app/App';
import './css/index.scss';

const container = document.getElementById('root');
const root = createRoot(container);

if (root) {
  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
} else {
  throw new Error('no Root element');
}
