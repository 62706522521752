import { track, WEBREG_PREFIX } from '../../../../utils/mixpanelHelper';
import { store } from '../../../../../state/store';
import { ACTION_TYPES } from '../../../../../state/actions';
import { MESSAGE } from '../../../../constants';
import {
  setBrazeAttribute,
  logBrazeEvent,
  BRAZE_ATTRIBUTES,
  BRAZE_EVENTS,
  addToAppLinkRequestSubscriptionGroup,
} from '../../../../utils/brazeHelper';
import { str } from '../../../../i18n/utils';
import { I18N_STR } from '../../../../i18n/strings';

export function sendDownloadAppSms(phoneNumber, showNotifications = false) {
  setBrazeAttribute(BRAZE_ATTRIBUTES.PHONE, phoneNumber);
  if (addToAppLinkRequestSubscriptionGroup()) {
    logBrazeEvent(BRAZE_EVENTS.REQUEST_APP_LINK_SMS);
    track(`${WEBREG_PREFIX}:successful-sms-complete-reg`, {
      value: phoneNumber,
    });
    if (showNotifications) {
      store.dispatch({
        type: ACTION_TYPES.SET_MESSAGE,
        payload: {
          message: str(I18N_STR.SMS_SENT),
          type: MESSAGE.TYPE.SUCCESS,
        },
      });
    }
  } else {
    track(`${WEBREG_PREFIX}:failed-sms-complete-reg`, {
      value: phoneNumber,
    });
    // not visibly showing error since those may happen on signup and may confuse the user
    if (showNotifications) {
      store.dispatch({
        type: ACTION_TYPES.SET_MESSAGE,
        payload: {
          message: str(I18N_STR.SOMETHING_WRONG_CONTACT_SUPPORT),
          type: MESSAGE.TYPE.ERROR,
        },
      });
    }
  }
}
