import React from 'react';
import { track, WEBREG_PREFIX } from '../../../utils/mixpanelHelper';
import styles from '../../../../css/UnifiedLanding.module.scss';
import { useI18n } from '../../../i18n/utils';
import { I18N_STR } from '../../../i18n/strings';

export default function TargetSpanishFlyer() {
  const { str } = useI18n();
  return (
    <a
      className={styles.flyerLink}
      href="https://s3-us-west-2.amazonaws.com/static.pivot.co/partners/200715-Marketing-Target-Campaign-Flyer-Spanish.pdf"
      download
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => {
        track(`${WEBREG_PREFIX}:target-spanish-flyer-click`, {
          'Page Path': location.pathname,
        });
      }}
    >
      <span>Folleto de Pivot en Español</span>
      <span className="screen-reader-text">
        {str(I18N_STR.downloadSpanishFlyer)}
      </span>
    </a>
  );
}
