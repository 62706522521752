import React, { useEffect, useRef, useState } from 'react';
import { track, WEBREG_PREFIX } from '../../../utils/mixpanelHelper';
import PortalModal from '../../../components/PortalModal';
import styles from '../../../../css/UnifiedLanding.module.scss';
import { useI18n } from '../../../i18n/utils';
import { I18N_STR } from '../../../i18n/strings';

export default function TargetReasonableAlternative() {
  const { str } = useI18n();
  const raButtonRef = useRef(); // "RA" stands for "Reasonable Alternative"
  const [raModalOn, setRaModalOn] = useState(false);

  const toggleModal = () => {
    setRaModalOn(() => {
      const eventName = raModalOn
        ? `${WEBREG_PREFIX}:RA-modal-closed`
        : `${WEBREG_PREFIX}:RA-modal-opened`;
      track(eventName);
      if (raModalOn && raButtonRef?.current) {
        raButtonRef.current.focus();
      }
      return !raModalOn;
    });
  };

  return (
    <>
      <section className={styles.programDisclaimer}>
        <div className="wrapper">
          <h3>{str(I18N_STR.targetRasModalTitle)}</h3>
          {str(I18N_STR.targetRasModalCopy)}{' '}
          <button
            onClick={toggleModal}
            aria-label={str(I18N_STR.targetRasModalButtonAlt)}
            ref={raButtonRef}
          >
            {str(I18N_STR.targetRasModalButton)}
          </button>
          .
        </div>
      </section>
      <RAModal show={raModalOn} closeModal={toggleModal} />
    </>
  );
}

export function RAModal({ closeModal, show }) {
  const { str } = useI18n();
  const backdropRef = useRef();
  const contentRef = useRef();

  useEffect(() => {
    if (show) {
      if (contentRef?.current) {
        contentRef.current.focus();
      }
    }
  }, [show]);

  const close = () => {
    closeModal();
  };

  const closeModalFromBackdrop = e => {
    if (e.target === backdropRef.current) {
      close();
    }
  };

  return show ? (
    <PortalModal>
      <div
        className="obstacle-modal ra-modal"
        onClick={closeModalFromBackdrop}
        ref={backdropRef}
      >
        <div
          className="inner-modal wrapper"
          aria-modal="true"
          tabIndex="-1"
          role="dialog"
          ref={contentRef}
        >
          <button className="close-modal-button" onClick={close}>
            <span className="screen-reader-text">{str(I18N_STR.close)}</span>
          </button>
          <h3>{str(I18N_STR.raModalTitle)}</h3>
          <p>
            <strong>{str(I18N_STR.raModalDescription)}</strong>
          </p>
          <ul>
            <li>{str(I18N_STR.raModalItem1)}</li>
            <li>{str(I18N_STR.raModalItem2)}</li>
            <li>{str(I18N_STR.raModalItem3)}</li>
            <li>{str(I18N_STR.raModalItem4)}</li>
            <li>{str(I18N_STR.raModalItem5)}</li>
          </ul>
          <p>{str(I18N_STR.raModalDisclaimer)}</p>
        </div>
      </div>
    </PortalModal>
  ) : null;
}
