import { str } from './i18n/utils';
import { I18N_STR } from './i18n/strings';

export const PAGES = {
  START: {
    url: '/',
    title: str(I18N_STR.PAGE_START_TITLE),
    description: str(I18N_STR.PAGE_START_DESCRIPTION),
  },
  JOIN: {
    url: '/join',
    title: str(I18N_STR.PAGE_JOIN_TITLE),
    description: str(I18N_STR.PAGE_JOIN_DESCRIPTION),
  },
  AMAZON: {
    url: '/amazon',
    title: str(I18N_STR.PAGE_AMAZON_TITLE),
    description: str(I18N_STR.PAGE_AMAZON_DESCRIPTION),
  },
  PARTNER_LANDING: {
    url: '/partner',
    title: str(I18N_STR.PAGE_PARTNER_LANDING_TITLE),
    description: str(I18N_STR.PAGE_PARTNER_LANDING_DESCRIPTION),
  },
  PARTNER_LANDING_STREAMLINED: {
    url: '/claim',
    title: str(I18N_STR.PAGE_PARTNER_LANDING_STREAMLINED_TITLE),
    description: str(I18N_STR.PAGE_PARTNER_LANDING_STREAMLINED_DESCRIPTION),
  },
  VIRGIN_PULSE_LANDING: {
    url: '/vp',
    title: str(I18N_STR.PAGE_VIRGIN_PULSE_LANDING_TITLE),
    description: str(I18N_STR.PAGE_VIRGIN_PULSE_LANDING_DESCRIPTION),
  },
  COVERAGE: {
    url: '/coverage',
    title: str(I18N_STR.PAGE_COVERAGE_TITLE),
    description: str(I18N_STR.PAGE_COVERAGE_DESCRIPTION),
  },
  NO_COVERAGE: {
    url: '/no-coverage',
    title: str(I18N_STR.PAGE_NO_COVERAGE_TITLE),
    description: str(I18N_STR.PAGE_NO_COVERAGE_DESCRIPTION),
  },
  THANK_YOU: {
    url: '/thank-you',
    title: str(I18N_STR.PAGE_THANK_YOU_TITLE),
    description: str(I18N_STR.PAGE_THANK_YOU_DESCRIPTION),
  },
  NICOTINE_USAGE_SELECTION: {
    url: '/usage',
    title: str(I18N_STR.PAGE_NICOTINE_USAGE_SELECTION_TITLE),
    description: str(I18N_STR.PAGE_NO_COVERAGE_DESCRIPTION),
  },
  NICORETTE_NICOTINE_USAGE_SELECTION: {
    url: '/nicorette-usage',
    title: str(I18N_STR.PAGE_NICORETTE_NICOTINE_USAGE_SELECTION_TITLE),
    description: str(
      I18N_STR.PAGE_NICORETTE_NICOTINE_USAGE_SELECTION_DESCRIPTION
    ),
  },
  ELIGIBILITY_INTRO: {
    url: '/eligibility',
    title: str(I18N_STR.PAGE_ELIGIBILITY_INTRO_TITLE),
    description: str(I18N_STR.PAGE_ELIGIBILITY_INTRO_DESCRIPTION),
  },
  ELIGIBILITY_PRIMARY: {
    url: '/eligibility-primary',
    title: str(I18N_STR.PAGE_ELIGIBILITY_PRIMARY_TITLE),
    description: str(I18N_STR.PAGE_ELIGIBILITY_PRIMARY_DESCRIPTION),
  },
  ELIGIBILITY_SECONDARY: {
    url: '/eligibility-secondary',
    title: str(I18N_STR.PAGE_ELIGIBILITY_SECONDARY_TITLE),
    description: str(I18N_STR.PAGE_ELIGIBILITY_SECONDARY_DESCRIPTION),
  },
  REVIEW_ELIGIBILITY: {
    url: '/review',
    title: str(I18N_STR.PAGE_REVIEW_ELIGIBILITY_TITLE),
    description: str(I18N_STR.PAGE_REVIEW_ELIGIBILITY_DESCRIPTION),
  },
  ELIGIBILITY_APPROVED: {
    url: '/eligibility-approved',
    title: str(I18N_STR.PAGE_ELIGIBILITY_APPROVED_TITLE),
    description: str(I18N_STR.PAGE_ELIGIBILITY_APPROVED_DESCRIPTION),
  },
  APPROVED: {
    url: '/approved',
    title: str(I18N_STR.PAGE_APPROVED_TITLE),
    description: str(I18N_STR.PAGE_APPROVED_DESCRIPTION),
  },
  CREATE_ACCOUNT: {
    url: '/create',
    title: str(I18N_STR.PAGE_CREATE_ACCOUNT_TITLE),
    description: str(I18N_STR.PAGE_CREATE_ACCOUNT_DESCRIPTION),
  },
  CREATE_ACCOUNT_FOX: {
    url: '/create-fox',
    title: str(I18N_STR.PAGE_CREATE_ACCOUNT_FOX_TITLE),
    description: str(I18N_STR.PAGE_CREATE_ACCOUNT_FOX_DESCRIPTION),
  },
  DOWNLOAD_APP: {
    url: '/download-app',
    title: str(I18N_STR.PAGE_DOWNLOAD_APP_TITLE),
    description: str(I18N_STR.PAGE_DOWNLOAD_APP_DESCRIPTION),
  },
  COMPLETE_REGISTRATION: {
    url: '/complete',
    title: str(I18N_STR.PAGE_COMPLETE_REGISTRATION_TITLE),
    description: str(I18N_STR.PAGE_COMPLETE_REGISTRATION_DESCRIPTION),
  },
  REGISTRATION_ERROR: {
    url: '/registration-error',
    title: str(I18N_STR.PAGE_REGISTRATION_ERROR_TITLE),
    description: str(I18N_STR.PAGE_REGISTRATION_ERROR_DESCRIPTION),
  },
  SIGNUP_ONLY: {
    url: '/signup',
    title: str(I18N_STR.PAGE_SIGNUP_ONLY_TITLE),
    description: str(I18N_STR.PAGE_SIGNUP_ONLY_DESCRIPTION),
  },
  CONFIRMED: {
    url: '/confirmed',
    title: str(I18N_STR.PAGE_CONFIRMED_TITLE),
    description: str(I18N_STR.PAGE_CONFIRMED_DESCRIPTION),
  },
  ELIGIBILITY_TAKEN: {
    url: '/eligibility-taken',
    title: str(I18N_STR.PAGE_ELIGIBILITY_TAKEN_TITLE),
    description: str(I18N_STR.PAGE_ELIGIBILITY_TAKEN_DESCRIPTION),
  },
  LOGIN: {
    url: '/login',
    title: str(I18N_STR.PAGE_LOGIN_TITLE),
    description: str(I18N_STR.PAGE_LOGIN_DESCRIPTION),
  },
  FORGOT_PASSWORD: {
    url: '/forgot-password',
    title: str(I18N_STR.PAGE_FORGOT_PASSWORD_TITLE),
    description: str(I18N_STR.PAGE_FORGOT_PASSWORD_DESCRIPTION),
  },
  RESET_PASSWORD: {
    url: '/reset-password',
    title: str(I18N_STR.PAGE_RESET_PASSWORD_TITLE),
    description: str(I18N_STR.PAGE_RESET_PASSWORD_DESCRIPTION),
  },
  PROFILE: {
    url: '/profile',
    title: str(I18N_STR.PAGE_PROFILE_TITLE),
    description: str(I18N_STR.PAGE_PROFILE_DESCRIPTION),
  },
  SUBSCRIPTION_RENEWAL: {
    url: '/subscription-renewal',
    title: str(I18N_STR.PAGE_SUBSCRIPTION_RENEWAL_TITLE),
    description: str(I18N_STR.PAGE_SUBSCRIPTION_RENEWAL_DESCRIPTION),
  },
  REORDER_SENSOR: {
    url: '/reorder-sensor',
    title: str(I18N_STR.PAGE_REORDER_SENSOR_TITLE),
    description: str(I18N_STR.PAGE_REORDER_SENSOR_DESCRIPTION),
  },
  CHANGE_ADDRESS: {
    url: '/change-address',
    title: str(I18N_STR.PAGE_CHANGE_ADDRESS_TITLE),
    description: str(I18N_STR.PAGE_CHANGE_ADDRESS_DESCRIPTION),
  },
  RECONFIRM: {
    url: '/reconfirm',
    title: str(I18N_STR.PAGE_RECONFIRM_TITLE),
    description: str(I18N_STR.PAGE_RECONFIRM_DESCRIPTION),
  },
  SHORTLIST: {
    url: '/shortlist',
    title: str(I18N_STR.PAGE_SHORTLIST_TITLE),
    description: str(I18N_STR.PAGE_SHORTLIST_DESCRIPTION),
  },
  NRT: {
    url: '/nrt',
    title: str(I18N_STR.PAGE_NRT_TITLE),
    description: str(I18N_STR.PAGE_NRT_DESCRIPTION),
  },
  NRT_FAQ: {
    url: '/nrt/faq',
    title: str(I18N_STR.PAGE_NRT_FAQ_TITLE),
    description: str(I18N_STR.PAGE_NRT_FAQ_DESCRIPTION),
  },
  SHIPPING: {
    url: 'shipping',
    title: str(I18N_STR.PAGE_SHIPPING_TITLE),
    description: str(I18N_STR.PAGE_SHIPPING_DESCRIPTION),
  },
  PERSONAL: {
    url: 'personal',
    title: str(I18N_STR.PAGE_PERSONAL_TITLE),
    description: str(I18N_STR.PAGE_PERSONAL_DESCRIPTION),
  },
  CREDENTIALS: {
    url: 'credentials',
    title: str(I18N_STR.PAGE_CREDENTIALS_TITLE),
    description: str(I18N_STR.PAGE_CREDENTIALS_DESCRIPTION),
  },
  RE_ENROLLMENT: {
    url: '/re-enrollment',
    title: str(I18N_STR.PAGE_RE_ENROLLMENT_TITLE),
    description: str(I18N_STR.PAGE_RE_ENROLLMENT_DESCRIPTION),
  },
  RE_ENROLLMENT_FAILED: {
    url: '/re-enrollment-failed',
    title: str(I18N_STR.PAGE_RE_ENROLLMENT_FAILED_TITLE),
    description: str(I18N_STR.PAGE_RE_ENROLLMENT_FAILED_DESCRIPTION),
  },
  RE_ENROLLMENT_NOT_AVAILABLE: {
    url: '/re-enrollment-not-available',
    title: str(I18N_STR.PAGE_RE_ENROLLMENT_NOT_AVAILABLE_TITLE),
    description: str(I18N_STR.PAGE_RE_ENROLLMENT_NOT_AVAILABLE_DESCRIPTION),
  },
  PRIVACY_POLICY: {
    url: '/privacy-policy',
    title: str(I18N_STR.PAGE_PRIVACY_POLICY_TITLE),
    description: str(I18N_STR.PAGE_PRIVACY_POLICY_DESCRIPTION),
  },
  TERMS_AND_CONDITIONS: {
    url: '/terms-conditions',
    title: str(I18N_STR.PAGE_TERMS_AND_CONDITIONS_TITLE),
    description: str(I18N_STR.PAGE_TERMS_AND_CONDITIONS_DESCRIPTION),
  },
};
