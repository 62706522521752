import React from 'react';
import BreathSensorAnimation from '../../../../media/landing-page/breath-sensor-video.gif';

import styles from '../../../../css/UnifiedLanding.module.scss';

export default function HeroSensorAnimation() {
  return (
    <div className={styles.heroRightImage}>
      <div>
        <picture>
          <img src={BreathSensorAnimation} alt="" height="680" width="1440" />
        </picture>
      </div>
    </div>
  );
}
