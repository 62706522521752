import React from 'react';
import Spinner from './Spinner';

export default function SuspensePreloader() {
  return (
    <div className="suspense-preloader">
      <Spinner show />
    </div>
  );
}
