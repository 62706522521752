import {
  orderManagementRootEndpoint,
  userManagementRootEndpoint,
} from '../../../config';
import { checkSessionWithPromise } from '../../utils/AWSCognitoHelper';
import { fetcher } from '../../utils/fetcher';

export function updateUserAddress(userSession, address) {
  const { jwtToken, payload } =
    userSession.cognitoObject.signInUserSession.accessToken;

  const requestBody = {
    shippingAddress: {
      streetAddressLine1: address.streetAddress1,
      streetAddressLine2: address.streetAddress2,
      city: address.city,
      state: address.state,
      postalCode: address.zip,
      country: 'United States',
    },
  };

  return fetcher(`${userManagementRootEndpoint}/v3/users/${payload.sub}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwtToken}`,
    },
    body: JSON.stringify(requestBody),
  })
    .then(res => res.json())
    .catch(err => {
      return err;
    });
}

export async function getProfile(_userId, _authToken) {
  const { userId, authToken } = await getCredentials(_userId, _authToken);

  return fetcher(`${userManagementRootEndpoint}/v1/users/${userId}/profile`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then(res => res.json())
    .catch(err => {
      return err;
    });
}

export async function getUser(_userId, _authToken) {
  const { userId, authToken } = await getCredentials(_userId, _authToken);

  return fetcher(`${userManagementRootEndpoint}/v3/users/${userId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then(res => res.json())
    .catch(err => {
      return err;
    });
}

export async function getFeatures() {
  const { userId, authToken } = await getCredentials();

  return fetch(`${userManagementRootEndpoint}/v1/users/${userId}/features`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then(res => res.json())
    .catch(err => {
      return err;
    });
}

export async function getProgramMembership(_userId, _authToken) {
  const { userId, authToken } = await getCredentials(_userId, _authToken);

  return fetcher(
    `${userManagementRootEndpoint}/v1/users/${userId}/program-membership`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    }
  )
    .then(res => res.json())
    .catch(err => {
      return err;
    });
}

export async function getReEnrollmentStatus() {
  const userSession = await checkSessionWithPromise();
  const { jwtToken, payload } =
    userSession.cognitoObject.signInUserSession.accessToken;

  return fetcher(
    `${userManagementRootEndpoint}/v1/users/${payload.sub}/re-enrollment`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
    }
  )
    .then(res => res.json())
    .catch(err => {
      return err;
    });
}

export async function reEnrollUser() {
  const userSession = await checkSessionWithPromise();
  const { jwtToken, payload } =
    userSession.cognitoObject.signInUserSession.accessToken;

  return fetcher(
    `${userManagementRootEndpoint}/v1/users/${payload.sub}/re-enrollment/re-enroll`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ userId: payload.sub }),
    }
  )
    .then(res => res.json())
    .catch(err => {
      return err;
    });
}

async function getCredentials(_userId, _authToken) {
  let userId;
  let authToken;

  if (_userId && _authToken) {
    userId = _userId;
    authToken = _authToken;
  } else {
    let userSession = await checkSessionWithPromise();
    const { jwtToken, payload } =
      userSession.cognitoObject.signInUserSession.accessToken;
    userId = payload.sub;
    authToken = jwtToken;
  }
  return {
    userId,
    authToken,
  };
}

export async function getUserPaymentMethods() {
  const { userId, authToken } = await getCredentials();

  return fetcher(
    `${orderManagementRootEndpoint}/v1/users/${userId}/payment-methods`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    }
  )
    .then(res => res.json())
    .catch(err => {
      return err;
    });
}

export async function getBillingPortalSession(returnUrl) {
  const { userId, authToken } = await getCredentials();

  return fetcher(
    `${orderManagementRootEndpoint}/v1/users/${userId}/create-billing-portal-session`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ returnUrl }),
    }
  )
    .then(res => res.json())
    .catch(err => {
      return err;
    });
}
