import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  createRef,
} from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { track, WEBREG_PREFIX } from '../../../utils/mixpanelHelper';
import { ReactComponent as IconLighter } from '../../../../media/icon-lighter.svg';
import { ReactComponent as IconHandOk } from '../../../../media/icon-hand-ok.svg';
import { ReactComponent as IconGraph } from '../../../../media/icon-graph.svg';
import { ReactComponent as IconNoSmoke } from '../../../../media/icon-no-smoke.svg';
import { ReactComponent as IconSpeaker } from '../../../../media/icon-speaker.svg';
import {
  logBrazeEvent,
  setBrazeAttribute,
  BRAZE_EVENTS,
  BRAZE_ATTRIBUTES,
} from '../../../utils/brazeHelper';
import { str, useI18n } from '../../../i18n/utils';
import { I18N_STR } from '../../../i18n/strings';

export function useElementInView(_ref, visibilityThresholdPercent = 25) {
  const [inView, setInView] = useState(false);
  const visibilityThreshold = visibilityThresholdPercent * 0.01;

  useLayoutEffect(() => {
    if (!_ref?.current) {
      return;
    }

    const observer = new IntersectionObserver(
      entries => {
        const isVisible = entries[0].isIntersecting;
        if (isVisible !== inView) {
          setInView(isVisible);
        }
      },
      { threshold: visibilityThreshold }
    );

    observer.observe(_ref?.current);

    return () => observer.disconnect();
  }, [inView, setInView, _ref, visibilityThreshold]);

  return inView;
}

export default function InteractiveCTA({ cta, inModal, cssClassName = '' }) {
  const windowWidth = window.innerWidth;
  const responsiveWidth = 500;
  const isDesktop = windowWidth > responsiveWidth;
  const location = useLocation();
  const containerRef = useRef();
  const isElementInView = useElementInView(containerRef);
  const [wasElementInView, setElementInView] = useState(isElementInView);
  const [visibleItems, setVisibleItems] = useState(
    SECTIONS.map(() => !inModal && isDesktop)
  );
  const btnRefs = useRef(SECTIONS.map(() => createRef()));
  const [isPristine, setPristine] = useState(true);
  const { str } = useI18n();

  useEffect(() => {
    if (!isDesktop) {
      return;
    }
    const selectedItems = visibleItems.filter(item => item === true);

    if (wasElementInView === false && isElementInView === true) {
      setElementInView(true);

      if (selectedItems.length === visibleItems.length) {
        visibleItems.forEach((item, i) => {
          const delay = i * 150 + 300;
          setTimeout(() => {
            const items = visibleItems.map((item, j) => j > i);
            setVisibleItems(items);
          }, delay);
        });
      }
    } else if (wasElementInView === true && isElementInView === false) {
      setElementInView(false);

      if (selectedItems.length === 0) {
        const items = new Array(visibleItems.length).fill(true);
        setVisibleItems(items);
      }
    }
  }, [isElementInView]);

  useEffect(() => {
    if (!isPristine) {
      const selectedIndex = visibleItems.findIndex(item => item === true);
      if (btnRefs.current[selectedIndex]?.current) {
        btnRefs.current[selectedIndex].current.focus();
      }
    }
  }, [visibleItems]);

  const gotoNextPage = index => {
    track(`${WEBREG_PREFIX}:click-interactive-cta-button`, {
      'Page Path': location.pathname,
      selection: SECTIONS[index].question,
      location: inModal ? 'popup' : 'main',
    });
    cta();
  };

  const seeAnswer = index => {
    const items = visibleItems.map((item, i) => i === index);
    const question = SECTIONS[index].question;
    setVisibleItems(items);
    setPristine(false);
    track(`${WEBREG_PREFIX}:click-interactive-cta-answer`, {
      'Page Path': location.pathname,
      selection: question,
      location: inModal ? 'popup' : 'main',
    });
    logBrazeEvent(BRAZE_EVENTS.CLICKED_INTERACTIVE_CTA_CARD, {
      selection: question,
      location: inModal ? 'popup' : 'main',
    });
    setBrazeAttribute(
      BRAZE_ATTRIBUTES.INTERACTIVE_CTA_CARD_CLICKS,
      question,
      true
    );
  };

  const isVisible = index => {
    return visibleItems[index];
  };

  return (
    <section
      className={classnames('interactive-cta', cssClassName)}
      ref={containerRef}
    >
      <div className="wrapper">
        <h2>{str(I18N_STR.iCtaTitle)}</h2>
        <div className="questions-wrapper">
          {SECTIONS.map((item, index) => {
            return (
              <div
                key={index}
                className={classnames('question', {
                  'is-active': isVisible(index),
                })}
              >
                <div className="card">
                  <div className="card-inner">
                    <div className="card-front">
                      <button
                        className="show-answer-btn"
                        onClick={() => seeAnswer(index)}
                      >
                        <div className="img-container">{item.icon}</div>
                        <span>{item.question}</span>
                      </button>
                    </div>
                    <div className="card-back" aria-live="assertive">
                      {isVisible(index) && (
                        <>
                          <h3>{item.title}</h3>
                          <p>{item.answer}</p>
                          <button
                            className="next-frame-button"
                            onClick={() => gotoNextPage(index)}
                            ref={btnRefs.current[index]}
                          >
                            {item.buttonLabel}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

const SECTIONS = [
  {
    icon: <IconLighter />,
    question: str(I18N_STR.iCtaQuestion1),
    title: str(I18N_STR.iCtaTitle1),
    answer: str(I18N_STR.iCtaAnswer1),
    buttonLabel: str(I18N_STR.iCtaButton1),
  },
  {
    icon: <IconSpeaker />,
    question: str(I18N_STR.iCtaQuestion2),
    title: str(I18N_STR.iCtaTitle2),
    answer: str(I18N_STR.iCtaAnswer2),
    buttonLabel: str(I18N_STR.iCtaButton2),
  },
  {
    icon: <IconGraph />,
    question: str(I18N_STR.iCtaQuestion3),
    title: str(I18N_STR.iCtaTitle3),
    answer: str(I18N_STR.iCtaAnswer3),
    buttonLabel: str(I18N_STR.iCtaButton3),
  },
  {
    icon: <IconHandOk />,
    question: str(I18N_STR.iCtaQuestion4),
    title: str(I18N_STR.iCtaTitle4),
    answer: str(I18N_STR.iCtaAnswer4),
    buttonLabel: str(I18N_STR.iCtaButton4),
  },
  {
    icon: <IconNoSmoke />,
    question: str(I18N_STR.iCtaQuestion5),
    title: str(I18N_STR.iCtaTitle5),
    answer: str(I18N_STR.iCtaAnswer5),
    buttonLabel: str(I18N_STR.iCtaButton5),
  },
];
