import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { PAGES } from '../page-urls';
import { getFeatures as getUserAllowedNrtOrders } from '../containers/profile/api';
import { useI18n } from '../i18n/utils';
import { I18N_STR } from '../i18n/strings';

export default function AccountNav() {
  const loggedInUser = useSelector(
    state => state.loggedInUser,
    /* the Equality Function is needed here to prevent re-renders because the check if the user is logged in
       also sets the creds back to redux (side effect) */
    (newState, currentState) => {
      return newState?.sub === currentState?.sub;
    }
  );
  const [allowedNrtOrders, setAllowedNrtOrders] = useState(0);
  const showNav = allowedNrtOrders > 0;
  const { str } = useI18n();

  useEffect(() => {
    if (loggedInUser) {
      getUserAllowedNrtOrders().then(res => {
        const allowedOrders = res.data?.nrt?.allowedOrders;
        if (allowedOrders > 0) {
          setAllowedNrtOrders(allowedOrders);
        }
      });
    }
  }, [loggedInUser]);

  return showNav ? (
    <nav className="account-nav">
      <NavLink
        to={PAGES.PROFILE.url}
        className={({ isActive }) => (isActive ? 'selected' : undefined)}
      >
        {str(I18N_STR.profile)}
      </NavLink>
      <NavLink
        to={PAGES.NRT.url}
        className={({ isActive }) => (isActive ? 'selected' : undefined)}
      >
        {str(I18N_STR.medication)}
      </NavLink>
    </nav>
  ) : null;
}
