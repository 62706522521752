import { useEffect, useRef } from 'react';
import PortalModal from '../../../components/PortalModal';
import { track, WEBREG_PREFIX } from '../../../utils/mixpanelHelper';
import { userManagementRootEndpoint, virginPulse } from '../../../../config';
import { VP_AUTH_STATES } from '../virgin-pulse/useVirginPulse';
import { ReactComponent as VPLogo } from '../../../../media/vp-logo.svg';
import { useI18n } from '../../../i18n/utils';
import { I18N_STR } from '../../../i18n/strings';

export default function VirginPulseAuthenticateModal({ authState }) {
  const modalRef = useRef();
  const { str } = useI18n();

  useEffect(() => {
    if (authState === VP_AUTH_STATES.SESSION_EXPIRED && modalRef?.current) {
      modalRef.current.focus();
    }
  }, [authState]);

  return authState === VP_AUTH_STATES.SESSION_EXPIRED ? (
    <PortalModal onTop={true}>
      <div className="obstacle-modal vp-modal">
        <div className="inner-modal wrapper" ref={modalRef}>
          <VPLogo />
          <h3>{str(I18N_STR.vpAuthModalTitle)}</h3>
          <p>{str(I18N_STR.vpAuthModalCopy)}</p>
          <div className="form-footer">
            <button
              className="cs-button"
              onClick={() => {
                track(`${WEBREG_PREFIX}:vpp-modal-login-attempt`);
                window.location.replace(
                  `${userManagementRootEndpoint}/v1/login/saml?provider=${virginPulse.authProvider}`
                );
              }}
            >
              {str(I18N_STR.vpAuthModalButton)}
            </button>
          </div>
        </div>
      </div>
    </PortalModal>
  ) : null;
}
