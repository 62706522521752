// @flow

import * as React from 'react';
import * as ReactDOM from 'react-dom';

type Props = {
  children?: React.Node,
  onTop: boolean,
};

type State = {};

const modalRoot: ?HTMLElement = document.getElementById('modal-root');

export default class PortalModal extends React.Component<Props, State> {
  el: HTMLElement;

  static defaultProps = {
    onTop: false,
  };

  constructor(props: Props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    //flow refinement
    if (!!modalRoot) {
      if (this.props?.onTop) {
        modalRoot.appendChild(this.el);
      } else {
        modalRoot.insertBefore(this.el, modalRoot.children[0]);
      }
    }
  }

  componentWillUnmount() {
    //flow refinement
    if (!!modalRoot) {
      modalRoot.removeChild(this.el);
    }
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}
