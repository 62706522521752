import React, { createRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { ACTION_TYPES } from '../../../state/actions';
import { ACCESS_CODES_API_RES_TYPES, ADDICTION_TYPES } from '../../constants';
import { getUrlParam } from '../../utils/queryVarsUtils';
import { AccessCodeForm } from './common-components/AccessCodeForm';
import { AvailabilityInfo } from './common-components/AvailabilityInfo';
import TitleH1 from './common-components/TitleH1';
import { AccessCodeSubmissionTracker } from './common-utils/AccessCodeSubmissionTracker';
import {
  dispatchAccessCodeExpired,
  dispatchAccessCodeFetchFail,
  dispatchAccessCodeFetchSuccess,
  isAccessCodeActiveByDate,
} from './common-utils/AccessCodeUtils';
import {
  setAccessCodeCookieFromUrl,
  getAccessCodeConfig,
} from './common-utils/dynamicRegistrationHelper';
import Wrapper from './common-components/Wrapper';
import { StickyHeader } from './common-components/StickyHeader';
import { GreenTitle } from './landing-content-bands/band-green-title';
import PrivacyPolicy from './landing-content-bands/band-privacy-policy';
import { SocialProof } from './landing-content-bands/band-social-proof';
import { useRegistrationNavigator } from './use-registration-navigator';
import InteractiveCTA from './landing-content-bands/band-interactive-cta';
import { ResponsiveHeroImage } from './common-components/responsive-hero-image';
import { useI18n } from '../../i18n/utils';
import { I18N_STR } from '../../i18n/strings';

const SideBySide = React.lazy(
  () => import('./landing-content-bands/band-side-by-side')
);

export default function LandingSimpleSignup() {
  const { str } = useI18n();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const accessCodeConfig = useSelector(state => state.accessCodeConfig);
  const [accessCode, setAccessCode] = useState(
    Boolean(params.code) ? params.code.toLowerCase() : ''
  );
  const [allowAccess, setAllowAccess] = useState(true);
  const accessCodeFromUrl = getUrlParam('access_code');
  const accessCodeInputRef = createRef();
  const { navigator } = useRegistrationNavigator();

  useEffect(() => {
    if (accessCodeFromUrl) {
      setAccessCode(accessCodeFromUrl);
      setAccessCodeCookieFromUrl(accessCodeFromUrl);
    }
  }, []);

  const checkAccessCode = (code = accessCode) => {
    const tracker = new AccessCodeSubmissionTracker(
      code,
      location,
      Boolean(accessCodeFromUrl)
    );
    tracker.trackInitiated();

    getAccessCodeConfig(code)
      .then(res => {
        if (res.status === ACCESS_CODES_API_RES_TYPES.SUCCESS) {
          dispatchAccessCodeFetchSuccess(res);
          if (res.data.status === ACCESS_CODES_API_RES_TYPES.EXPIRED) {
            tracker.trackExpired();
            dispatchAccessCodeExpired();
          } else if (res.data.status === ACCESS_CODES_API_RES_TYPES.FULL) {
            tracker.trackFull();
            navigator.gotoShortlistPage();
          } else {
            const { startTime, endTime, availableNicotineAddictionTypes } =
              res.data;
            if (isAccessCodeActiveByDate(startTime, endTime)) {
              const addictionTypeKey = Object.keys(
                availableNicotineAddictionTypes
              )[0];

              dispatch({
                type: ACTION_TYPES.SELECT_USAGE_TYPE,
                payload: addictionTypeKey,
              });

              if (addictionTypeKey === ADDICTION_TYPES.SMOKING) {
                dispatch({ type: ACTION_TYPES.REQUEST_SMOKING_HISTORY });
              }

              tracker.trackValid();
              navigator.gotoCreateAccountPage(code);
            } else {
              tracker.trackNotStarted();
              setAllowAccess(false);
            }
          }
        }
        if (res.status === ACCESS_CODES_API_RES_TYPES.FAIL) {
          tracker.trackFail();
          dispatchAccessCodeFetchFail();
        }
      })
      .catch(err => {
        tracker.trackServerError();
        console.log(err);
      });
  };

  const interactiveCTAClickHandler = () => {
    if (accessCode) {
      checkAccessCode();
    } else {
      window.scrollTo(0, 0);
      if (accessCodeInputRef?.current) {
        accessCodeInputRef.current.focus();
      }
    }
  };

  const title = str(I18N_STR.lpSimpleSignUpTitle);

  return (
    <>
      <StickyHeader
        showButton={allowAccess && accessCodeFromUrl}
        buttonCta={() => checkAccessCode}
      />
      <Wrapper pageName="page-access-code">
        <div className="title-for-mobile">
          <TitleH1 title={title} />
        </div>
        <ResponsiveHeroImage />
        <div className="copy-section">
          <div className="content">
            <TitleH1 title={title} />
            <p>{str(I18N_STR.lpSimpleSignUpCopy)}</p>
            {allowAccess ? (
              <AccessCodeForm
                accessCodeFromUrl={accessCodeFromUrl}
                accessCode={accessCode}
                setAccessCode={setAccessCode}
                clickHandler={checkAccessCode}
                _ref={accessCodeInputRef}
              />
            ) : (
              <AvailabilityInfo availabilityDate={accessCodeConfig.startTime} />
            )}
          </div>
        </div>
      </Wrapper>
      <SocialProof />
      <GreenTitle />
      <SideBySide accessCode={accessCode} />
      <InteractiveCTA cta={interactiveCTAClickHandler} />
      <PrivacyPolicy />
    </>
  );
}
