import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

const SCROLL_EVENT_TYPE = 'scroll';

function getPixelsToScroll() {
  const viewPortHeight = window.innerHeight;
  const contentHeight = Math.floor(
    document.body.getBoundingClientRect().height
  );
  return contentHeight < viewPortHeight ? 0 : contentHeight - viewPortHeight;
}

export function usePageScrolled(initialState = false, useWindowHeight = false) {
  const [showActionBar, setShowActionBar] = useState(initialState);

  const handleScroll = useCallback(() => {
    const threshold = useWindowHeight ? getPixelsToScroll() / 2 : 1;

    if (window.scrollY < threshold && showActionBar === true) {
      setShowActionBar(false);
    }
    if (window.scrollY >= threshold && showActionBar === false) {
      setShowActionBar(true);
    }
  }, [showActionBar]);

  useLayoutEffect(() => {
    window.addEventListener(SCROLL_EVENT_TYPE, handleScroll);
    return () => window.removeEventListener(SCROLL_EVENT_TYPE, handleScroll);
  }, [showActionBar, handleScroll]);

  return showActionBar;
}

export function usePageScrolledWithTopBuffer(
  initialState = false,
  ref,
  topBuffer = 0
) {
  const [isInBuffer, setIsInBuffer] = useState(initialState);
  const [initialDistanceFromTop, setInitialDistanceFromTop] = useState();
  const [initialElementHeight, setInitialElementHeight] = useState();
  const [didScroll, setDidScroll] = useState(false);

  const handleScroll = useCallback(() => {
    if (!didScroll && ref?.current) {
      const elementBoundingClientRect = ref?.current?.getBoundingClientRect();
      setInitialDistanceFromTop(elementBoundingClientRect.top);
      setInitialElementHeight(elementBoundingClientRect.height);
      setDidScroll(true);
      return;
    }

    const elementDistanceFromTop = Math.floor(
      ref?.current?.getBoundingClientRect().top
    );

    if (window.scrollY <= initialDistanceFromTop - initialElementHeight) {
      setIsInBuffer(false);
    } else if (elementDistanceFromTop < topBuffer) {
      setIsInBuffer(true);
    }
  }, [ref, isInBuffer, didScroll]);

  useEffect(() => {
    handleScroll();
  }, []);

  useLayoutEffect(() => {
    window.addEventListener(SCROLL_EVENT_TYPE, handleScroll);
    return () => window.removeEventListener(SCROLL_EVENT_TYPE, handleScroll);
  }, [isInBuffer, handleScroll]);

  return isInBuffer;
}
