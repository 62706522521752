export class UserNotFoundError extends Error {
  constructor(email) {
    super(`User with email ${email} does not exist`);
    this.name = UserNotFoundError.name;
  }
}

export class UserAlreadyVerifiedError extends Error {
  constructor(email) {
    super(`User with email ${email} has already been verified`);
    this.name = UserAlreadyVerifiedError.name;
  }
}
