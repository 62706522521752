import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { PAGES } from '../page-urls';
import PivotAndVPLogo from '../../media/pivot-and-vp-logos.svg';
import Logo from '../../media/pivot-logo-dark.svg';
import { useI18n } from '../i18n/utils';
import { I18N_STR } from '../i18n/strings';

export function HeaderLogo({ cssStyleName = null }) {
  const accessCodeConfig = useSelector(state => state.accessCodeConfig);
  const accessCode = accessCodeConfig?.accessCode;
  const alias =
    accessCodeConfig?.alias ||
    accessCodeConfig?.webTemplate?.alias?.toLowerCase();
  const isVirginPulse = useSelector(state => state.isVirginPulse);
  const partnerUrlPrefix = isVirginPulse
    ? PAGES.VIRGIN_PULSE_LANDING.url
    : PAGES.PARTNER_LANDING.url;
  const accessCodeParam = accessCode ? `?access_code=${accessCode}` : '';
  const homeLink = alias
    ? `${partnerUrlPrefix}/${alias}${accessCodeParam}`
    : '';
  const partnerLogo = isVirginPulse
    ? PivotAndVPLogo
    : accessCodeConfig?.webTemplate?.logoUrl || null;
  const { str } = useI18n();

  return (
    <a
      href={`${window.location.origin}${homeLink}`}
      className={classnames('header-logo', cssStyleName)}
    >
      <img
        src={partnerLogo || Logo}
        alt={str(I18N_STR.logoAlt)}
        width="155"
        height="34"
      />
    </a>
  );
}
