import React from 'react';
import classnames from 'classnames';
import HamburgerMenu from '../../../components/HamburgerMenu';
import { HeaderLogo } from '../../../components/HeaderLogo';
import styles from '../../../../css/UnifiedLanding.module.scss';

export default function FixedHeader({ callToAction }) {
  return (
    <>
      <header className={classnames('brand-bar', styles.brandBar)}>
        <div className={classnames('wrapper', styles.mainHeader)}>
          <HeaderLogo cssStyleName={styles.headerLogo} />
          <HamburgerMenu callToAction={callToAction} />
        </div>
      </header>
    </>
  );
}
