import React, { memo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

export const ScreenReaderPageTitle = memo(props => {
  const ref = useRef();
  const pageTitle = useSelector(state => state.screenReaderPageTitle);

  useEffect(() => {
    let timeout;
    timeout = setTimeout(() => {
      if (ref?.current) {
        ref.current.focus();
      }
    }, 250);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [pageTitle]);

  return (
    <span
      ref={ref}
      tabIndex="0"
      className="screen-reader-text page-title"
      aria-live="assertive"
    >
      {pageTitle || ''}
    </span>
  );
});
