import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { PAGES } from '../page-urls';
import { track, WEBREG_PREFIX } from '../utils/mixpanelHelper';
import styles from '../../css/UnifiedLanding.module.scss';
import { LANGUAGES, useI18n } from '../i18n/utils';
import { I18N_STR } from '../i18n/strings';
import { webUiUrl } from '../../config';

export default function HamburgerMenu({ callToAction, homeLink }) {
  const location = useLocation();
  const navigate = useNavigate();
  const loggedInUser = useSelector(state => state.loggedInUser);
  const accessCodeConfig = useSelector(state => state.accessCodeConfig);
  const [isOpen, setOpen] = useState(() => false);
  const { str } = useI18n();

  const handleHamMenuButtonClick = e => {
    e.preventDefault();
    setOpen(() => {
      document.body.classList.toggle('navIsOpen');
      return !isOpen;
    });
  };

  const onClickHandler = () => {
    track(`${WEBREG_PREFIX}:landing-cta-click`, {
      'Page Path': location.pathname,
      accessCode: accessCodeConfig?.accessCode || '',
      buttonLocation: 'header',
      label: str(I18N_STR.getPivot, LANGUAGES.EN),
    });
    if (callToAction) {
      callToAction();
    } else if (homeLink) {
      return navigate(homeLink);
    }
  };

  return loggedInUser ? null : (
    <>
      <nav
        className={classnames(styles.mobileNav, {
          [styles.navIsOpen]: isOpen,
        })}
      >
        <button onClick={onClickHandler}>{str(I18N_STR.getPivot)}</button>
        <Link to={PAGES.LOGIN.url}>{str(I18N_STR.renewSubscription)}</Link>
        <Link to={`${webUiUrl}/login`}>{str(I18N_STR.login)}</Link>
      </nav>
      <button
        className={styles.hamMenuButton}
        onClick={handleHamMenuButtonClick}
        aria-label={str(I18N_STR.openMenu)}
      >
        <span
          className={classnames(styles.topLine, {
            [styles.navIsOpen]: isOpen,
          })}
        />
        <span
          className={classnames(styles.bottomLine, {
            [styles.navIsOpen]: isOpen,
          })}
        />
      </button>
    </>
  );
}
