// @flow

import * as React from 'react';

export function generateRange(
  start: number,
  end: number,
  increment: number = 1,
  prepend: string = '',
  round: number = 0,
  optionsBefore: Array<React.Element<'option'>> = [],
  optionsAfter: Array<React.Element<'option'>> = []
): Array<React.Element<'option'>> {
  let range = optionsBefore;
  while (start <= end) {
    let temp = start;
    range.push(
      <option value={temp} key={temp}>
        {prepend + temp.toFixed(round)}
      </option>
    );
    start += increment;
  }
  if (optionsAfter.length > 0) {
    range = range.concat(optionsAfter);
  }
  return range;
}

export function generateNumberRange(
  start: number,
  end: number,
  increment: number = 1,
  round: number = 0
): Array<string> {
  let range = [];
  while (start <= end) {
    let temp = start;
    range.push(`${temp.toFixed(round)}`);
    start += increment;
  }
  return range;
}
