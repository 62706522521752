import React from 'react';
import { useSelector } from 'react-redux';
import ExitIntentModal from '../modals/ExitIntentModal';
import VirginPulseSessionTimeoutModal from '../modals/VirginPulseSessionTimeoutModal';

export default function Wrapper({ children, pageName, style = {} }) {
  const accessCodeConfig = useSelector(state => state.accessCodeConfig);
  return (
    <div className={pageName} style={style}>
      <div className="wrapper slide-up-on-entry">{children}</div>
      <ExitIntentModal
        currentFrame={null}
        accessCode={accessCodeConfig && accessCodeConfig.accessCode}
      />
      <VirginPulseSessionTimeoutModal />
    </div>
  );
}
