import { differenceInYears } from 'date-fns';
import isEmail from 'validator/es/lib/isEmail';
import {
  AGE_CATEGORIES,
  AGE_CATEGORY,
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
  STATES,
  YEAR_IN_MS,
} from '../constants';
import { dateFromDateObj } from '../containers/register/account-creation/utils';

export type DateObject = {
  day: number,
  month: number,
  year: number,
};

export function validatePassword(
  password: string,
  minLength: number = MIN_PASSWORD_LENGTH
): boolean {
  if (!password) {
    return false;
  }
  const value = password.trim();
  return (
    /[A-Z]/.test(value) &&
    /[a-z]/.test(value) &&
    /\d/.test(value) &&
    value.length >= minLength &&
    value.length <= MAX_PASSWORD_LENGTH
  );
}

export function validateEmail(email: string): boolean {
  if (!email) {
    return false;
  }
  const value = email.trim();
  return isEmail(value);
}

export function validateName(name: string, minLength: number = 0): boolean {
  if (!name) {
    return false;
  }
  const value = name.trim();
  // eslint-disable-next-line no-useless-escape
  const lettersOnly = /^([^!"#$%&\(\)*+./0-9:;<=>?@\[\\\]\^_`\{\}\|\~])*$/;
  return lettersOnly.test(value) && value.length >= minLength;
}

export function validateZip(zip: string): boolean {
  if (!zip) {
    return false;
  }
  const value = zip.trim();
  return /^\d{5}(-\d{4})?$/.test(value);
}

export function validateTelephone(telephone): boolean {
  if (!telephone) {
    return false;
  }
  const value = telephone.trim();
  // eslint-disable-next-line no-useless-escape
  return /^(\+?1(\s|-)?)?(\(\d{3}\)|\d{3})(-|\s)?\d{3}-?\d{4}$/.test(value);
}

export function validateStreetAddress(address: string): boolean {
  if (!address) {
    return false;
  }
  const value = address.trim();
  // using lookahead assertions to check for their existence of any digit and any letter with specific order (fix for PO Box addresses)
  return /^(?=.*[a-zA-Z])(?=.*[0-9])/.test(value);
}

export function validateCity(city: string): boolean {
  if (!city) {
    return false;
  }
  const value = city.trim();
  return /^[a-zA-Z\u0080-\u024F]+(?:([\ \-\']|(\.\ ))[a-zA-Z\u0080-\u024F]+)*$/gim.test(
    value
  );
}

export function validateState(state: string) {
  if (!state) {
    return false;
  }
  const value = state.trim();
  const states = Object.keys(STATES);
  return states.includes(value);
}

export function validateGender(gender: string) {
  if (!gender) {
    return false;
  }
  const value = gender.trim();
  return ['Male', 'Female', 'Other'].includes(value);
}

export function validateMinStringLength(
  s: string,
  numChars: number = 2
): boolean {
  return Boolean(s) && s.trim().length >= numChars;
}

export function validateMaxStringLength(s: string, numChars: number): boolean {
  return Boolean(s) && s.trim().length <= numChars;
}

export function validateStringLength(s: string, numChars: number): boolean {
  return Boolean(s) && s.trim().length === numChars;
}

export function isValidDate(dateObject: DateObject): boolean {
  const userBirthday = dateFromDateObj(dateObject);

  // Validation that checks for "carried over" dates like Feb 31st which translates to March 3rd
  return (
    userBirthday.getFullYear() === dateObject.year &&
    userBirthday.getMonth() === dateObject.month &&
    userBirthday.getDate() === dateObject.day
  );
}

export function isAgeInProgramRange(
  dateObject: DateObject,
  ageCategory = AGE_CATEGORY.ADULT
): boolean {
  const { minAge, maxAge } = getMinAndMaxAgeForAgeCategory(ageCategory);
  const userDOB = dateFromDateObj(dateObject);
  const currentAge = differenceInYears(new Date(), userDOB);
  return minAge <= currentAge && currentAge <= maxAge;
}

function getMinAndMaxAgeForAgeCategory(ageCategory: string): {
  minAge: number,
  maxAge: number,
} {
  const validAgeCategory =
    !ageCategory || Object.keys(AGE_CATEGORIES).includes(ageCategory) === false
      ? AGE_CATEGORY.ADULT
      : ageCategory;

  return {
    minAge: AGE_CATEGORIES[validAgeCategory].MIN,
    maxAge: AGE_CATEGORIES[validAgeCategory].MAX,
  };
}

export function getAgeFromDateString(dateString: string): boolean {
  const userBirthday = new Date(dateString);

  const diff = new Date() - userBirthday;
  const age = Math.floor(diff / YEAR_IN_MS);
  return age;
}

export function makeDateObjectFromDateString(dateString: string): DateObject {
  if (!dateString) {
    return {
      day: null,
      month: null,
      year: null,
    };
  }
  const [mm, dd, yyyy] = dateString.split('/');
  return {
    day: parseInt(dd, 10),
    month: parseInt(mm, 10),
    year: parseInt(yyyy, 10),
  };
}

export function validateActivationCode(
  s: string,
  minNumChars: number = 3,
  maxNumChars = 25
) {
  return (
    s.length >= minNumChars && s.length <= maxNumChars
    //&& /^[a-zA-Z0-9]*$/.test(s)
  );
}
