import { useSelector } from 'react-redux';
import { isValidElement } from 'react';

export const LANGUAGES = {
  EN: 'en',
  ES: 'es',
};

const DEFAULT_LANG = LANGUAGES.EN;
const SUPPORTED_LANGS = Object.values(LANGUAGES);

export const getLocale = () => {
  const { language, languages } = navigator;
  let languageCode = navigator
    ? languages?.map(lang => lang.split(/-|_/)[0])[0]
    : DEFAULT_LANG;

  if (!SUPPORTED_LANGS.includes(languageCode)) {
    languageCode = DEFAULT_LANG;
  }

  return {
    language,
    languages,
    languageCode,
  };
};

export function useI18n() {
  const locale = useSelector(state => state.locale);
  const langCode = locale?.languageCode ?? DEFAULT_LANG;

  const str = (strings, forcedLanguage = undefined) => {
    if (!strings) {
      return '--Missing Key--';
    }
    return strings[forcedLanguage ?? langCode ?? DEFAULT_LANG];
  };

  const strReplace = (strings, forcedLanguage = undefined, replacements) => {
    const inputString = str(strings, forcedLanguage);

    if (!replacements || replacements.length === 0) {
      return inputString;
    }

    return inputString?.replace(/%/g, match => {
      const index = replacements.findIndex(value => value !== undefined);
      return index !== -1 ? replacements[index] : match;
    });
  };

  const reactNodeToString = reactNode => {
    let string = '';
    if (typeof reactNode === 'string') {
      string = reactNode;
    } else if (typeof reactNode === 'number') {
      string = reactNode.toString();
    } else if (reactNode instanceof Array) {
      reactNode.forEach(function (child) {
        string += reactNodeToString(child);
      });
    } else if (isValidElement(reactNode)) {
      string += reactNodeToString(reactNode.props.children);
    }
    return string;
  };

  return {
    str,
    strReplace,
    reactNodeToString,
    langCode,
  };
}

/**
 * @param {Object} strings - the I18N_STR object contains both languages
 * @param {string} strings.en - english string
 * @param {string} strings.es - spanish string
 * @param {string} forcedLanguage - language code, the language to use
 * @returns {ReactNode}
 * */
export function str(strings, forcedLanguage = undefined) {
  const { languageCode } = getLocale();
  return strings[forcedLanguage ?? languageCode ?? DEFAULT_LANG];
}

/**
 * @param {Object} strings - the I18N_STR object contains both languages
 * @param {string} strings.en - english string, should contain "%" placeholders
 * @param {string} strings.es - spanish string, should contain "%" placeholders
 * @param {string} forcedLanguage - language code, the language to use
 * @param {string[]} replacements - string array
 * @returns {ReactNode}
 * */
export function strReplace(strings, forcedLanguage = undefined, replacements) {
  const inputString = str(strings, forcedLanguage);

  if (!replacements || replacements.length === 0) {
    return inputString;
  }

  return inputString?.replace(/%/g, match => {
    const index = replacements.findIndex(value => value !== undefined);
    return index !== -1 ? replacements[index] : match;
  });
}

/**
 * @param {ReactNode} reactNode
 * @returns {string}
 * */

export const reactNodeToString = reactNode => {
  let string = '';
  if (typeof reactNode === 'string') {
    string = reactNode;
  } else if (typeof reactNode === 'number') {
    string = reactNode.toString();
  } else if (reactNode instanceof Array) {
    reactNode.forEach(function (child) {
      string += reactNodeToString(child);
    });
  } else if (isValidElement(reactNode)) {
    string += reactNodeToString(reactNode.props.children);
  }
  return string;
};
