import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PAGES } from '../page-urls';
import { track, WEBREG_PREFIX } from '../utils/mixpanelHelper';
import { useI18n } from '../i18n/utils';
import { I18N_STR } from '../i18n/strings';

const HIDE_LOGIN_ON_PAGES = [PAGES.LOGIN.url];

export const LoginButton = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const loggedInUser = useSelector(state => state.loggedInUser);
  const { str } = useI18n();

  const onClickHandler = () => {
    track(`${WEBREG_PREFIX}:button-click`, {
      'Page Path': location.pathname,
      buttonName: loggedInUser ? 'profile' : 'login',
    });

    navigate(loggedInUser ? PAGES.PROFILE.url : PAGES.LOGIN.url);
  };

  return loggedInUser ||
    HIDE_LOGIN_ON_PAGES.includes(location.pathname) ? null : (
    <button className="login-button" onClick={onClickHandler}>
      {str(I18N_STR.login)}
    </button>
  );
};
