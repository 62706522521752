import ReactGA from 'react-ga';
import { googleAnalyticsId } from '../../config';

ReactGA.initialize(googleAnalyticsId, { debug: false });

export function trackPageViewInGA(path) {
  ReactGA.pageview(path);
}

export function trackEventInGa(
  eventCategory,
  eventAction,
  eventLabel,
  eventValue
) {
  ReactGA.event({
    category: eventCategory,
    action: eventAction,
    label: eventLabel || '',
    value: eventValue || '',
  });
}

export function trackRegistrationMilestone(accessCode, utmSource, utmCampaign) {
  trackEventInGa(
    'Registration',
    'CompleteRegistration',
    utmSource,
    utmCampaign
  );

  trackEventInGa(
    'Registration',
    'CompleteRegistrationByAccessCode',
    accessCode
  );
}

export function trackDTCRegistrationEvent(utmSource, utmCampaign) {
  trackEventInGa(
    'Registration',
    'CompleteRegistrationPayment',
    utmSource,
    utmCampaign
  );
}
