import { useEffect, useRef } from 'react';
import PortalModal from '../../../components/PortalModal';
import { track, WEBREG_PREFIX } from '../../../utils/mixpanelHelper';
import { userManagementRootEndpoint, virginPulse } from '../../../../config';
import { ChatSupportLink } from '../common-components/ChatSupportLink';
import { VP_AUTH_STATES } from '../virgin-pulse/useVirginPulse';
import { ReactComponent as Image } from '../../../../media/cookies.svg';
import { useI18n } from '../../../i18n/utils';
import { I18N_STR } from '../../../i18n/strings';

export default function VirginPulseThirdPartyCookiesModal({ authState }) {
  const modalRef = useRef();
  const { str } = useI18n();

  useEffect(() => {
    if (
      authState === VP_AUTH_STATES.THIRD_PARTY_COOKIE_NOT_SUPPORTED &&
      modalRef?.current
    ) {
      modalRef.current.focus();
    }
  }, [authState]);

  return authState === VP_AUTH_STATES.THIRD_PARTY_COOKIE_NOT_SUPPORTED ? (
    <PortalModal onTop={true}>
      <div className="obstacle-modal vp-modal cookies-modal">
        <div className="inner-modal wrapper" ref={modalRef}>
          <Image />
          <h3>{str(I18N_STR.vpCookiesModalTitle)}</h3>
          <p>{str(I18N_STR.vpCookiesModalCopy)}</p>
          <div className="form-footer">
            <ChatSupportLink label={str(I18N_STR.contactSupport)} />
            <button
              className="cs-button"
              onClick={() => {
                track(`${WEBREG_PREFIX}:vpp-modal-3rd-party-cookies`);
                window.location.replace(
                  `${userManagementRootEndpoint}/v1/login/saml?provider=${virginPulse.authProvider}`
                );
              }}
            >
              {str(I18N_STR.vpCookiesModalButton)}
            </button>
          </div>
        </div>
      </div>
    </PortalModal>
  ) : null;
}
