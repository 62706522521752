import React, { useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import mailcheck from 'mailcheck';
import { ACTION_TYPES } from '../../../../state/actions';
import LabelAndError from '../../../components/LabelAndError';
import { USER_EMAIL_STATUS_RES } from '../../../constants';
import {
  validateEmail,
  validateMinStringLength,
} from '../../../utils/validation';
import { checkUserStatus } from '../account-creation/utils';
import Spinner, { SPINNER_COLORS } from './Spinner';
import { StatesOptionsMapper } from './StatesOptionsMapper';
import { resendConfirmation } from '../../../utils/AWSCognitoHelper';
import { useI18n } from '../../../i18n/utils';
import { I18N_STR } from '../../../i18n/strings';

export function AccessCodeInputTextContainer({
  type,
  value,
  updateHandler,
  onKeyPress,
  placeHolder,
  name = 'accessCode',
  _ref,
}) {
  const [val, setValue] = useState(value ? value : '');
  const [isValid, setIsValid] = useState(null);
  const { str } = useI18n();

  useEffect(() => {
    setValue(value);
  }, [value]);

  const update = e => {
    setValue(e.target.value);
    updateHandler(e.target.value);
  };

  const checkValidation = () => {
    const _isValid = validateMinStringLength(val, 3);
    setIsValid(_isValid);
  };

  const keyPressHandler = e => {
    onKeyPress(e.key);
  };

  return (
    <label
      className={classnames('form-input', {
        'is-valid': isValid === true,
        'has-error': isValid === false,
      })}
      htmlFor={name}
    >
      <LabelAndError
        required={true}
        isValid={isValid}
        placeHolder={placeHolder ?? str(I18N_STR.accessCode)}
        errorString={str(I18N_STR.ENTER_VALID_ACCESS_CODE)}
      />
      <input
        ref={_ref}
        type={type || 'text'}
        id={name}
        name={name}
        value={val}
        onChange={update}
        onKeyPress={keyPressHandler}
        onBlur={checkValidation}
        aria-invalid={isValid || isValid === null ? false : true}
        required
      />
    </label>
  );
}

export function InputText({
  type,
  name,
  value,
  updateHandler,
  validator,
  action,
  placeholder,
  autoComplete,
  errorString,
  required = true,
  inputMode = 'text',
  blurHandler = null,
}) {
  const dispatch = useDispatch();
  const [val, setValue] = useState(value || '');
  const [isValid, setIsValid] = useState(null);
  const [isPristine, setIsPristine] = useState(true);

  useEffect(() => {
    const _isValid = Boolean(validator) ? validator(val) : true;

    if ((!isPristine || val.length > 0) && _isValid) {
      setIsValid(_isValid);
    }

    if (action && name) {
      dispatch({ type: action, payload: { name, value: val } });
    }

    if (updateHandler) {
      updateHandler(val);
    }

    if (isPristine && val !== '') {
      setIsPristine(false);
    }

    if (!isPristine && val === '') {
      setIsPristine(true);
    }
  }, [val]);

  const onBlurHandler = e => {
    checkValidation();
    if (blurHandler) {
      blurHandler(e);
    }
  };

  const checkValidation = () => {
    const _isValid = Boolean(validator) ? validator(val) : true;

    if (!isPristine || val.length > 0) {
      setIsValid(_isValid);
    }
  };

  const update = event => {
    const eventValue = event.currentTarget.value;
    setValue(eventValue);
  };

  return (
    <label
      className={classnames('form-input', {
        'is-valid': isValid === true,
        'has-error': isValid === false,
        [isPristine === true ? 'is-pristine' : 'is-touched']: true,
      })}
      htmlFor={name}
    >
      <LabelAndError
        required={required}
        isValid={isValid}
        placeHolder={placeholder}
        errorString={errorString}
      />
      <input
        type={type || 'text'}
        inputMode={inputMode}
        id={name}
        name={name}
        value={val}
        autoComplete={autoComplete}
        onChange={update}
        onBlur={onBlurHandler}
        aria-invalid={isValid || isValid === null ? false : true}
        required={required}
      />
    </label>
  );
}

export function Checkbox({ name, value, updateHandler, action, placeholder }) {
  const dispatch = useCallback(useDispatch(), []);

  const update = e => {
    const newValue = !value;
    if (action) {
      dispatch({ type: action, payload: { name, value: newValue } });
    }
    if (updateHandler) {
      updateHandler(newValue);
    }
  };

  return (
    <div className="form-input has-checkbox">
      <div className="input-container checkbox-container">
        <input
          type="checkbox"
          name={name}
          id={name}
          checked={value}
          onChange={update}
        />
        <label htmlFor={name}>{placeholder}</label>
      </div>
    </div>
  );
}

export function StatesDropdown({
  name,
  value = null,
  action,
  errorString,
  excludedStates = [],
  placeholder,
}) {
  const dispatch = useCallback(useDispatch(), []);
  const [val, setValue] = useState(value ? value : '');
  const [isValid, setIsValid] = useState(null);
  const [isPristine, setIsPristine] = useState(true);

  const onChangeHandler = e => {
    const _value = e.currentTarget.value;
    setValue(_value);
  };

  useEffect(() => {
    setIsValid(val !== 'false');
    if (isPristine && val !== '') {
      setIsPristine(false);
    }
    dispatch({ type: action, payload: { name, value: val } });
  }, [val, dispatch]);

  return (
    <label
      className={classnames('form-input', {
        'is-valid': isValid === true,
        'has-error': isValid === false,
        [isPristine === true ? 'is-pristine' : 'is-touched']: true,
        'is-default-value': val === 'false',
      })}
      htmlFor={name}
    >
      <LabelAndError
        required={true}
        isValid={isValid}
        placeHolder={placeholder}
        errorString={errorString}
      />
      <select
        name={name}
        id={name}
        onChange={onChangeHandler}
        defaultValue={value}
        required
      >
        <StatesOptionsMapper excludedStates={excludedStates} />
      </select>
    </label>
  );
}

export function PersonalisationDropdown({
  name,
  value,
  action,
  placeholder,
  question,
  errorString,
  repeater,
  updateHandler = null,
}) {
  const dispatch = useCallback(useDispatch(), []);
  const [val, setValue] = useState(value ? value : '');
  const [isValid, setIsValid] = useState(null);
  const [isPristine, setIsPristine] = useState(true);

  const onChangeHandler = e => {
    const _value = e.currentTarget.value;
    setValue(_value);
    setIsValid(_value !== 'false');
  };

  useEffect(() => {
    if (isPristine && val !== '') {
      setIsPristine(false);
    }
    if (updateHandler) {
      updateHandler(name, val);
    }
    dispatch({ type: action, payload: { name, value: val } });
  }, [val, dispatch]);

  return (
    <div className="form-input side-by-side-select">
      <label id={name} className="question">
        {question}
      </label>
      <div
        className={
          'input-container' +
          (isValid ? ' is-valid' : ' has-error') +
          (isPristine === true ? ' is-pristine' : ' is-touched')
        }
      >
        <select
          name={name}
          onChange={onChangeHandler}
          required
          aria-labelledby={name}
        >
          <option value="false">{placeholder}</option>
          {repeater.map(item => (
            <option value={item.key} key={item.key}>
              {item.label}
            </option>
          ))}
        </select>
        {isValid === false && (
          <span className="input-error-message">
            {errorString || `ERROR ${name}`}
          </span>
        )}
      </div>
    </div>
  );
}

export function GenderDropdown({
  name,
  value = null,
  action,
  placeholder,
  errorString,
  repeater,
  updateHandler = null,
  validator,
  required = true,
}) {
  const defaultValue = '';
  const dispatch = useDispatch();
  const [val, setValue] = useState(value ? value : defaultValue);
  const [isValid, setIsValid] = useState(null);
  const [isPristine, setIsPristine] = useState(true);

  const onChangeHandler = e => {
    const _value = e.currentTarget.value;
    setValue(_value);
  };

  useEffect(() => {
    const _isValid = Boolean(validator) ? validator(val) : val !== defaultValue;

    if (!isPristine) {
      setIsValid(_isValid);
    }

    if (action && name) {
      dispatch({ type: action, payload: { name, value: val } });
    }

    if (updateHandler) {
      updateHandler(name, val);
    }

    if (isPristine && val !== defaultValue) {
      setIsPristine(false);
    }
  }, [val, dispatch]);

  return (
    <label
      className={classnames('form-input', {
        'is-valid': isValid === true,
        'has-error': isValid === false,
        [isPristine === true ? 'is-pristine' : 'is-touched']: true,
        'is-default-value': val === '',
      })}
      htmlFor={name}
    >
      <LabelAndError
        required={required}
        isValid={isValid}
        placeHolder={placeholder}
        errorString={errorString}
      />
      <select
        name={name}
        id={name}
        onChange={onChangeHandler}
        defaultValue={value}
        required
      >
        <option value={defaultValue} />
        {repeater.map(item => (
          <option value={item.key} key={item.key}>
            {item.label}
          </option>
        ))}
      </select>
    </label>
  );
}

export function DisabledField({ name, value, placeholder }) {
  return (
    <div className="form-input input-container is-valid">
      <div className="label-and-error">
        <label form={name}>{placeholder}</label>
      </div>
      <input type="text" name={name} value={value} disabled />
    </div>
  );
}

export function InputEmail({
  name,
  action,
  placeholder,
  value,
  disabled = false,
  updateHandler = null,
  blurHandler = null,
}) {
  const dispatch = useCallback(useDispatch(), []);
  const [isAjaxing, setIsAjaxing] = useState(false);
  const [isValid, setIsValid] = useState(null);
  const [message, setMessage] = useState(null);
  const [isPristine, setIsPristine] = useState(true);
  const [emailSuggestion, setEmailSuggestion] = useState();
  const { str } = useI18n();

  useEffect(() => {
    if (isPristine && value !== '') {
      setIsPristine(false);
    }
    if (!isPristine && value === '') {
      setIsPristine(true);
    }
    validateEmailFields();
  }, [value]);

  const validateEmailFields = () => {
    if (value.length === 0) {
      setMessage('');
      setIsValid(null);
      return;
    }

    if (validateEmail(value) === false) {
      setMessage(str(I18N_STR.EMAIL_NOT_VALID));
      setIsValid(false);
      return;
    }

    setIsAjaxing(true);

    checkUserStatus(value).then(res => {
      setIsAjaxing(false);
      if (res === USER_EMAIL_STATUS_RES.NOT_FOUND) {
        setMessage('');
        setIsValid(true);
      } else if (res === USER_EMAIL_STATUS_RES.EXISTS) {
        setMessage(str(I18N_STR.EMAIL_EXISTS_IN_SYSTEM));
        setIsValid(false);
      } else if (res === USER_EMAIL_STATUS_RES.NOT_VERIFIED) {
        resendConfirmation(value.toLowerCase());
        setMessage(str(I18N_STR.USER_NOT_CONFIRMED));
        setIsValid(false);
      } else if (res === USER_EMAIL_STATUS_RES.NOT_INITIALIZED) {
        setMessage(str(I18N_STR.USER_NOT_INITIALIZED));
        setIsValid(false);
      }
    });
  };

  const onUpdateHandler = e => {
    const value = e.currentTarget.value.trim();
    setValue(value);
  };

  const onBlurHandler = e => {
    checkForTypos();
    if (blurHandler) {
      blurHandler(e);
    }
  };

  const checkForTypos = () => {
    mailcheck.run({
      email: value,
      suggested: suggestion => {
        setEmailSuggestion(suggestion);
      },
      empty: () => {
        setEmailSuggestion(null);
      },
    });
  };

  const replaceEmailWithSuggestion = () => {
    if (emailSuggestion.full) {
      setValue(emailSuggestion.full);
      setEmailSuggestion(null);
    }
  };

  const setValue = email => {
    dispatch({
      type: action,
      payload: { name, value: email },
    });
    if (updateHandler) {
      updateHandler(name, email);
    }
  };

  return (
    <>
      <label
        className={classnames('form-input', 'email-input', {
          'is-valid': isValid === true,
          'has-error': isValid === false,
          [isPristine === true ? 'is-pristine' : 'is-touched']: true,
        })}
        htmlFor={name}
      >
        <LabelAndError
          required={true}
          isValid={isValid}
          placeHolder={placeholder}
          errorString={message}
        />
        <input
          id={name}
          type="email"
          inputMode="email"
          name={name}
          value={value}
          onChange={onUpdateHandler}
          onBlur={onBlurHandler}
          autoComplete="email"
          disabled={disabled}
          required
        />
        <Spinner show={isAjaxing} />
      </label>
      <div className="email-typo-suggestion">
        {emailSuggestion && (
          <>
            {str(I18N_STR.emailTypoSuggestion)}{' '}
            <button onClick={replaceEmailWithSuggestion}>
              {emailSuggestion.full}
            </button>
            ?
          </>
        )}
      </div>
    </>
  );
}

export function EmailComparison({
  name,
  action,
  placeholder,
  value,
  disabled = false,
}) {
  const dispatch = useCallback(useDispatch(), []);
  const [isAjaxing, setIsAjaxing] = useState(false);
  const [isValid, setIsValid] = useState(null);
  const [message, setMessage] = useState(null);
  const [email, setValueForEmail] = useState(value ? value : '');
  const [retypedEmail, setValueForRetypedEmail] = useState('');
  const [isPristine, setIsPristine] = useState(true);
  const selfRef = useRef(false);
  const [emailSuggestion, setEmailSuggestion] = useState();
  const { str } = useI18n();

  const FIELD_NAMES = {
    EMAIL: 'email',
    RETYPED: 'retypedEmail',
  };

  useEffect(() => {
    if (isMounted()) {
      if (isPristine && (email !== '' || retypedEmail !== '')) {
        setIsPristine(false);
      }
      validateEmailFields();
    } else {
      setMounted();
    }
  }, [email, retypedEmail]);

  const isMounted = () => {
    return selfRef.current;
  };

  const setMounted = () => {
    selfRef.current = true;
  };

  const validateEmailFields = () => {
    if (email.length === 0 || retypedEmail.length === 0) {
      setMessage('');
      setIsValid(null);
      return;
    }
    if (email !== retypedEmail) {
      setMessage(str(I18N_STR.EMAILS_SHOULD_MATCH));
      setIsValid(false);
      return;
    } else {
      setMessage('');
      setIsValid(null);
    }
    if (validateEmail(email) === false) {
      setMessage(str(I18N_STR.EMAIL_NOT_VALID));
      setIsValid(false);
      return;
    }
    setIsAjaxing(true);
    checkUserStatus(email).then(res => {
      setIsAjaxing(false);
      if (res !== USER_EMAIL_STATUS_RES.EXISTS) {
        setMessage('');
        setIsValid(true);
        dispatch({ type: action, payload: { name, value: email } });
      } else {
        setMessage(str(I18N_STR.EMAIL_EXISTS_IN_SYSTEM));
        setIsValid(false);
      }
    });
  };

  const update = e => {
    switch (e.currentTarget.name) {
      case FIELD_NAMES.EMAIL:
        setValueForEmail(e.currentTarget.value.trim());
        break;
      case FIELD_NAMES.RETYPED:
        setValueForRetypedEmail(e.currentTarget.value.trim());
        break;
      default:
        break;
    }
  };

  const checkForTypos = () => {
    mailcheck.run({
      email,
      suggested: suggestion => {
        setEmailSuggestion(suggestion);
      },
      empty: () => {
        setEmailSuggestion(null);
      },
    });
  };

  const replaceEmailWithSuggestion = () => {
    if (emailSuggestion.full) {
      setValueForEmail(emailSuggestion.full);
      // enable if we would like the suggestion button to populate both fields
      // setValueForRetypedEmail(emailSuggestion.full);
      setEmailSuggestion(null);
    }
  };

  return (
    <>
      <div className="side-by-side-inputs">
        <label
          className={classnames('form-input', 'input-container', {
            'is-valid': isValid === true,
            'has-error': isValid === false,
            [isPristine === true ? 'is-pristine' : 'is-touched']: true,
          })}
        >
          <LabelAndError
            errorString={message}
            isValid={isValid}
            placeHolder={placeholder[0]}
            required={true}
          />
          <input
            type="email"
            name={FIELD_NAMES.EMAIL}
            value={email}
            onChange={update}
            onBlur={checkForTypos}
            autoComplete="email"
            disabled={disabled}
            required
          />
          <Spinner show={isAjaxing} />
        </label>
        <label
          className={classnames('form-input', 'input-container', {
            'is-valid': isValid === true,
            'has-error': isValid === false,
            [isPristine === true ? 'is-pristine' : 'is-touched']: true,
          })}
        >
          <LabelAndError
            errorString={message}
            isValid={isValid}
            placeHolder={placeholder[1]}
            required={true}
          />
          <input
            type="email"
            name={FIELD_NAMES.RETYPED}
            value={retypedEmail}
            onChange={update}
            disabled={disabled}
            required
          />
        </label>
      </div>
      {emailSuggestion && (
        <div className="email-typo-suggestion">
          Did you mean{' '}
          <button onClick={replaceEmailWithSuggestion}>
            {emailSuggestion.full}
          </button>
          ?
        </div>
      )}
    </>
  );
}

export function AjaxifiedButton({
  label,
  labelWhenSending,
  onClickHandler,
  isSendingData,
  className,
  disabled = false,
  ariaLabel,
}) {
  const [isClicked, setIsClicked] = useState(false);
  const fetchState = useSelector(state => state.fetchState);
  const isSending =
    isClicked &&
    (fetchState === ACTION_TYPES.FETCHING || isSendingData === true);
  const dispatch = useDispatch();
  const { str } = useI18n();

  useEffect(() => {
    if (isClicked && fetchState !== ACTION_TYPES.FETCHING) {
      setIsClicked(false);
    }
  }, [isClicked, fetchState, isSendingData]);

  const onClick = () => {
    setIsClicked(true);
    dispatch({ type: ACTION_TYPES.FETCHING });
    if (!isSending) {
      onClickHandler();
    }
  };

  return (
    <button
      onClick={onClick}
      className={classnames(
        'next-frame-button',
        { 'is-sending': isSending },
        { 'is-disabled': disabled },
        className
      )}
      disabled={disabled || isSending}
      aria-label={
        (isSending && str(I18N_STR.disabledDuringSubmission)) ||
        ariaLabel ||
        label
      }
    >
      <Spinner show={isSending} color={SPINNER_COLORS.LIGHT} />
      {isSending && labelWhenSending ? labelWhenSending : label}
    </button>
  );
}
