import {
  MAX_ALLOWED_ADULT_AGE,
  MAX_ALLOWED_MINOR_AGE,
  MIN_ALLOWED_ADULT_AGE,
  MIN_ALLOWED_MINOR_AGE,
} from '../constants';
import { EmailSupportLink } from '../containers/register/common-components/EmailSupportLink';
import { ChatSupportLink } from '../containers/register/common-components/ChatSupportLink';
const LANGUAGES = { EN: 'en', ES: 'es' };
export const I18N_STR = {
  close: { [LANGUAGES.EN]: <>Close</>, [LANGUAGES.ES]: <>Cerrar</> },
  next: { [LANGUAGES.EN]: <>Next</>, [LANGUAGES.ES]: <>Siguiente</> },
  continue: { [LANGUAGES.EN]: <>Continue</>, [LANGUAGES.ES]: <>Continuar</> },
  update: { [LANGUAGES.EN]: <>Update</>, [LANGUAGES.ES]: <>Actualizar</> },
  cancel: { [LANGUAGES.EN]: <>Cancel</>, [LANGUAGES.ES]: <>Actualizar</> },
  submit: { [LANGUAGES.EN]: <>Submit</>, [LANGUAGES.ES]: <>Entregar</> },
  submitDisabled: {
    [LANGUAGES.EN]: <>Submit button disabled</>,
    [LANGUAGES.ES]: <>Botón Enviar deshabilitado</>,
  },
  openPivot: {
    [LANGUAGES.EN]: <>Open Pivot</>,
    [LANGUAGES.ES]: <>Abrir Pivot</>,
  },
  getStarted: {
    [LANGUAGES.EN]: <>Get Started</>,
    [LANGUAGES.ES]: <>Empezar</>,
  },
  goTo: { [LANGUAGES.EN]: <>Go to</>, [LANGUAGES.ES]: <>Ir a</> },
  pleaseContactWithQuestions: {
    [LANGUAGES.EN]: (
      <>
        Please contact us at <EmailSupportLink /> if you have any questions.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Si tienes preguntas, escríbenos a <EmailSupportLink />.
      </>
    ),
  },
  send: { [LANGUAGES.EN]: <>Send</>, [LANGUAGES.ES]: <>Enviar</> },
  logoAlt: {
    [LANGUAGES.EN]: <>Pivot home</>,
    [LANGUAGES.ES]: <>Inicio de Pivot</>,
  },
  getPivot: {
    [LANGUAGES.EN]: <>Get Pivot</>,
    [LANGUAGES.ES]: <>Obtener Pivot</>,
  },
  openMenu: { [LANGUAGES.EN]: <>Open Menu</>, [LANGUAGES.ES]: <>Abrir menú</> },
  renewSubscription: {
    [LANGUAGES.EN]: <>Renew subscription</>,
    [LANGUAGES.ES]: <>Renovar la suscripción</>,
  },
  login: { [LANGUAGES.EN]: <>Log in</>, [LANGUAGES.ES]: <>Iniciar sesión</> },
  externalLink: {
    [LANGUAGES.EN]: <>External link opens in a new tab</>,
    [LANGUAGES.ES]: <>El enlace externo se abre en otra pestaña</>,
  },
  copyright: {
    [LANGUAGES.EN]: <>Copyright</>,
    [LANGUAGES.ES]: <>Derechos de autor</>,
  },
  companyName: {
    [LANGUAGES.EN]: <>Pivot Health Technologies Inc.</>,
    [LANGUAGES.ES]: <>Pivot Health Technologies Inc.</>,
  },
  terms: {
    [LANGUAGES.EN]: <>Terms of Service</>,
    [LANGUAGES.ES]: <>Condiciones del Servicio</>,
  },
  privacy: {
    [LANGUAGES.EN]: <>Privacy Policy</>,
    [LANGUAGES.ES]: <>Política de privacidad</>,
  },
  contact: {
    [LANGUAGES.EN]: <>Contact Us</>,
    [LANGUAGES.ES]: <>Contáctenos</>,
  },
  contactSupport: {
    [LANGUAGES.EN]: <>Contact Support</>,
    [LANGUAGES.ES]: <>Contactar a Soporte</>,
  },
  disabledDuringSubmission: {
    [LANGUAGES.EN]: <>Button disabled during data submission</>,
    [LANGUAGES.ES]: <>Botón deshabilitado durante el envío de datos</>,
  },
  profile: { [LANGUAGES.EN]: <>Profile</>, [LANGUAGES.ES]: <>Perfil</> },
  medication: {
    [LANGUAGES.EN]: <>Medication</>,
    [LANGUAGES.ES]: <>Medicamento</>,
  },
  shortlistTitle: {
    [LANGUAGES.EN]: <>We’re full. But don’t go away!</>,
    [LANGUAGES.ES]: <>No tenemos disponibilidad. ¡Pero no te vayas!</>,
  },
  shortlistP1: {
    [LANGUAGES.EN]: (
      <>
        Thank you so much for your interest in Pivot. We’re honored that you
        would place your trust in us.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Muchas gracias por tu interés en Pivot. Nos sentimos honrados por la
        confianza que has depositado en nosotros.
      </>
    ),
  },
  shortlistP2: {
    [LANGUAGES.EN]: (
      <>
        Unfortunately, we only have a limited number of spots available and have
        reached our maximum for the current period. But more space may open up
        soon.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Lamentablemente, solo tenemos un número limitado de plazas disponibles y
        hemos alcanzado nuestro número máximo para el período actual. Pero es
        posible que pronto se abran más plazas.
      </>
    ),
  },
  shortlistP3: {
    [LANGUAGES.EN]: (
      <>
        We’ll be sure to reach out to you the moment that happens. In the
        meantime, feel free to reach out to <EmailSupportLink /> if you have any
        questions about Pivot or about when additional spots may open up.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Nos aseguraremos de comunicarnos contigo en el momento en que eso
        suceda. Mientras tanto, no dudes en comunicarte con{' '}
        <a href="mailto:<EmailSupportLink />" className="green">
          soporte@pivot.co
        </a>{' '}
        si tienes alguna pregunta sobre Pivot o sobre cuándo se pueden abrir
        plazas adicionales.
      </>
    ),
  },
  shortlistP4: {
    [LANGUAGES.EN]: <>We look forward to working with you soon.</>,
    [LANGUAGES.ES]: <>Esperamos poder trabajar pronto contigo.</>,
  },
  shortlistP5: {
    [LANGUAGES.EN]: (
      <>
        Sincerely,
        <br />
        The Pivot Team
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Atentamente,
        <br />
        El equipo de Pivot
      </>
    ),
  },
  resetPassTitle: {
    [LANGUAGES.EN]: <>Reset your password</>,
    [LANGUAGES.ES]: <>Restablece tu contraseña</>,
  },
  resetPassInstructions: {
    [LANGUAGES.EN]: (
      <>Write your email below so that we can send you a verification code.</>
    ),
    [LANGUAGES.ES]: (
      <>
        Escribe tu correo electrónico a continuación y te enviaremos un código
        de verificación.
      </>
    ),
  },
  forgotPassword: {
    [LANGUAGES.EN]: <>Forgot Password?</>,
    [LANGUAGES.ES]: <>¿Has olvidado tu contraseña?</>,
  },
  passwordLength: {
    [LANGUAGES.EN]: (
      <>
        Passwords must be at least 9 characters and contain a number, upper case
        letter and lower case letter.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Las contraseñas deben tener al menos 9 caracteres y contener un número,
        una mayúscula y una minúscula.
      </>
    ),
  },
  sentEmailTo: {
    [LANGUAGES.EN]: <>We’ve sent an email to?</>,
    [LANGUAGES.ES]: <>¿Ya te enviamos un código de verificación?</>,
  },
  enterVerificationCode: {
    [LANGUAGES.EN]: (
      <>
        Please enter the verification code you received and create a new
        password.?
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Ingresa el código de verificación que recibiste y crea una nueva
        contraseña.
      </>
    ),
  },
  loginTitle: {
    [LANGUAGES.EN]: <>Login</>,
    [LANGUAGES.ES]: <>Iniciar sesión</>,
  },
  loginInstructions: {
    [LANGUAGES.EN]: (
      <>Please sign in with your Pivot credentials to continue.</>
    ),
    [LANGUAGES.ES]: (
      <>Para continuar, inicia sesión con tus credenciales de Pivot.</>
    ),
  },
  signIn: { [LANGUAGES.EN]: <>Sign in</>, [LANGUAGES.ES]: <>Iniciar sesión</> },
  didYouMean: {
    [LANGUAGES.EN]: <>Did you mean</>,
    [LANGUAGES.ES]: <>Quisiste decir</>,
  },
  billingTitle: {
    [LANGUAGES.EN]: <>Manage My Payment Methods</>,
    [LANGUAGES.ES]: <>Administrar mis métodos de pago</>,
  },
  billingDescription: {
    [LANGUAGES.EN]: (
      <>Review and make sure your Credit Card information is up to date.</>
    ),
    [LANGUAGES.ES]: (
      <>
        Revisa y asegúrate de que la información de tu tarjeta de crédito esté
        actualizada.
      </>
    ),
  },
  changeAddressTitle: {
    [LANGUAGES.EN]: <>Where should we ship your sensor?</>,
    [LANGUAGES.ES]: <>¿Dónde debemos enviar tu sensor?</>,
  },
  changeAddressInstructions: {
    [LANGUAGES.EN]: (
      <>If you need to change your address please contact us at</>
    ),
    [LANGUAGES.ES]: <>Si necesitas cambiar su dirección, contáctanos en</>,
  },
  shippingDetails: {
    [LANGUAGES.EN]: <>Shipping details</>,
    [LANGUAGES.ES]: <>Información de envío</>,
  },
  profileTitle: {
    [LANGUAGES.EN]: <>My Profile</>,
    [LANGUAGES.ES]: <>Mi perfil</>,
  },
  profileInstructions: {
    [LANGUAGES.EN]: <>View and update your information here.</>,
    [LANGUAGES.ES]: <>Revisa y actualiza tu información aquí.</>,
  },
  profileTitle2: {
    [LANGUAGES.EN]: <>View My Profile</>,
    [LANGUAGES.ES]: <>Ver mi perfil</>,
  },
  profileSignout: {
    [LANGUAGES.EN]: <>or Sign Out</>,
    [LANGUAGES.ES]: <>o cerrar sesión</>,
  },
  profileInstructions2: {
    [LANGUAGES.EN]: (
      <>Review and make sure all your information is up to date.</>
    ),
    [LANGUAGES.ES]: (
      <>Revisa y asegúrate de que toda tu información esté actualizada.</>
    ),
  },
  changePasswordTitle: {
    [LANGUAGES.EN]: <>Change My Password</>,
    [LANGUAGES.ES]: <>Cambiar mi contraseña</>,
  },
  changePasswordInstructions: {
    [LANGUAGES.EN]: <>Reset your Pivot account password here.</>,
    [LANGUAGES.ES]: <>Restablece la contraseña de tu cuenta Pivot aquí.</>,
  },
  changePhoneTitle: {
    [LANGUAGES.EN]: <>Change My Telephone</>,
    [LANGUAGES.ES]: <>Cambiar mi teléfono</>,
  },
  changePhoneInstructions: {
    [LANGUAGES.EN]: <>Update your phone number here.</>,
    [LANGUAGES.ES]: <>Actualiza tu número de teléfono aquí.</>,
  },
  reorderSensorTitle: {
    [LANGUAGES.EN]: <>Your sensor is about to expire!</>,
    [LANGUAGES.ES]: <>¡Tu sensor está a punto de caducar!</>,
  },
  reorderSensorInstructions: {
    [LANGUAGES.EN]: (
      <>
        Looks like your sensor is about to expire soon, so we’ll ship you a new
        one.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Parece que tu sensor caducará pronto, por lo que te enviaremos uno
        nuevo.
      </>
    ),
  },
  reorderSensorInstructions2: {
    [LANGUAGES.EN]: <>Please confirm your shipping address:</>,
    [LANGUAGES.ES]: <>Confirma tu dirección de envío:</>,
  },
  changeAddress: {
    [LANGUAGES.EN]: <>Change Address</>,
    [LANGUAGES.ES]: <>Cambiar dirección</>,
  },
  mySubscriptionTitle: {
    [LANGUAGES.EN]: <>My Subscription</>,
    [LANGUAGES.ES]: <>Mi suscripción</>,
  },
  mySubscriptionInstruction1: {
    [LANGUAGES.EN]: <>Your renewed subscription will include</>,
    [LANGUAGES.ES]: <>Tu suscripción renovada incluirá</>,
  },
  nicotineReplacementTherapy: {
    [LANGUAGES.EN]: <>Nicotine Replacement Therapy</>,
    [LANGUAGES.ES]: <>Terapia de Remplazo de Nicotina</>,
  },
  personalCoaching: {
    [LANGUAGES.EN]: <>Personal Coaching</>,
    [LANGUAGES.ES]: <>Entrenamiento personal</>,
  },
  behavioralSupport: {
    [LANGUAGES.EN]: <>Behavioral Support</>,
    [LANGUAGES.ES]: <>Apoyo conductual</>,
  },
  welcomeBack: {
    [LANGUAGES.EN]: <>Welcome back</>,
    [LANGUAGES.ES]: <>Bienvenido de nuevo</>,
  },
  noLongerEligible: {
    [LANGUAGES.EN]: <>Looks like you’re no longer eligible for Pivot.</>,
    [LANGUAGES.ES]: <>Parece que ya no eres elegible para Pivot.</>,
  },
  subscriptionRenewed: {
    [LANGUAGES.EN]: <>Your subscription has been renewed.</>,
    [LANGUAGES.ES]: <>Tu suscripción ha sido renovada.</>,
  },
  subscriptionAndSensorRenewed: {
    [LANGUAGES.EN]: (
      <>Your subscription has been renewed, and your new sensor is on its way</>
    ),
    [LANGUAGES.ES]: (
      <>Tu suscripción ha sido renovada y tu nuevo sensor está en camino</>
    ),
  },
  subscriptionRenewedInstructions1: {
    [LANGUAGES.EN]: (
      <>
        Unfortunately, your coverage may have ended with your employer or health
        plan. Thank you for being a part of the Pivot family.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Lamentablemente, es posible que tu empleador o tu plan de salud ya no te
        ofrezcan cobertura. ¡Gracias por ser parte de la familia Pivot!
      </>
    ),
  },
  subscriptionRenewedInstructions2: {
    [LANGUAGES.EN]: (
      <>
        If you think this is an error or have any questions, please email us at
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Si crees que se trata de error o tienes alguna pregunta, envíanos un
        correo electrónico a
      </>
    ),
  },
  week: { [LANGUAGES.EN]: <>Week</>, [LANGUAGES.ES]: <>Semana</> },
  weeks: { [LANGUAGES.EN]: <>Weeks</>, [LANGUAGES.ES]: <>Semanas</> },
  reEnrollSuccessTitle: {
    [LANGUAGES.EN]: <>Your Pivot account has been extended!</>,
    [LANGUAGES.ES]: <>¡Tu cuenta Pivot se ha extendido!</>,
  },
  reEnrollSuccessDescription: {
    [LANGUAGES.EN]: <>Open the Pivot app to continue your Pivot program.</>,
    [LANGUAGES.ES]: (
      <>Abre la aplicación Pivot para continuar con tu programa Pivot.</>
    ),
  },
  reEnrollNotAvailTitle: {
    [LANGUAGES.EN]: <>Sorry, this offer was sent to you by mistake.</>,
    [LANGUAGES.ES]: <>Lo sentimos, recibiste esta oferta por error.</>,
  },
  reEnrollNotAvailDescription1: {
    [LANGUAGES.EN]: (
      <>
        You still have access to the Pivot program, with no interruptions. We
        will contact you when you are closer to the end of your program to
        re-enroll.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Aún tienes acceso al programa Pivot, sin interrupciones. Nos
        comunicaremos contigo cuando estés más cerca del final de tu programa
        para volver a matricularte.
      </>
    ),
  },
  reEnrollNotAvailDescription2: {
    [LANGUAGES.EN]: (
      <>
        Please contact us at <EmailSupportLink /> if you have any questions.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Si tienes preguntas, escríbenos a <EmailSupportLink />.
      </>
    ),
  },
  reEnrollFailedTitle: {
    [LANGUAGES.EN]: (
      <>Sorry, it appears you are no longer eligible for Pivot.</>
    ),
    [LANGUAGES.ES]: (
      <>Lo sentimos, parece que ya no eres elegible para Pivot.</>
    ),
  },
  reEnrollFailedDescription: {
    [LANGUAGES.EN]: (
      <>
        Please contact us at <EmailSupportLink /> to resolve this problem.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Comunícate con nosotros escribiendo a <EmailSupportLink /> para resolver
        este problema.
      </>
    ),
  },
  reconfirmEmailTitle: {
    [LANGUAGES.EN]: <>Confirm your Email</>,
    [LANGUAGES.ES]: <>Confirma tu correo electrónico</>,
  },
  reconfirmEmailInstructions: {
    [LANGUAGES.EN]: <>Enter your email below</>,
    [LANGUAGES.ES]: <>Ingresa tu correo electrónico a continuación</>,
  },
  sendConfirmation: {
    [LANGUAGES.EN]: <>Send Confirmation</>,
    [LANGUAGES.ES]: <>Enviar confirmación</>,
  },
  numCigsDailyQuestion: {
    [LANGUAGES.EN]: <>How many cigarettes do you smoke in a typical day?</>,
    [LANGUAGES.ES]: <>¿Cuántos cigarrillos fumas en un día normal?</>,
  },
  numCigsDailyPlaceholder: {
    [LANGUAGES.EN]: <>Cigarettes Per Day</>,
    [LANGUAGES.ES]: <>Cigarrillos por día</>,
  },
  daysPerWeekQuestion: {
    [LANGUAGES.EN]: <>How many days do you smoke in a typical week?</>,
    [LANGUAGES.ES]: <>¿Cuántos días fumas en una semana normal?</>,
  },
  daysPerWeekPlaceholder: {
    [LANGUAGES.EN]: <>Days Per Week</>,
    [LANGUAGES.ES]: <>Días por semana</>,
  },
  avgPackCostQuestion: {
    [LANGUAGES.EN]: <>What do you typically pay for a pack of cigarettes?</>,
    [LANGUAGES.ES]: (
      <>¿Cuánto pagas normalmente por una cajetilla de cigarrillos?</>
    ),
  },
  avgPackCostPlaceholder: {
    [LANGUAGES.EN]: <>Cost Per Pack</>,
    [LANGUAGES.ES]: <>Costo por cajetilla</>,
  },
  ageStartedSmokingQuestion: {
    [LANGUAGES.EN]: <>At what age did you start smoking?</>,
    [LANGUAGES.ES]: <>¿A qué edad empezaste a fumar?</>,
  },
  ageStartedSmokingPlaceholder: {
    [LANGUAGES.EN]: <>Started At</>,
    [LANGUAGES.ES]: <>Empecé a los</>,
  },
  accessCodeSupportMessage: {
    [LANGUAGES.EN]: (
      <>
        If you don’t know this, <ChatSupportLink /> and provide the name of your
        employer or health plan. You may be eligible for free access to Pivot.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Si no lo sabes,
        <ChatSupportLink />e indica el nombre de tu empleador o plan de salud.
        Puedes ser elegible para acceder gratuitamente a Pivot.
      </>
    ),
  },
  downloadTheApp: {
    [LANGUAGES.EN]: <>Download the App</>,
    [LANGUAGES.ES]: <>Descargar la aplicación</>,
  },
  downloadApp: {
    [LANGUAGES.EN]: <>Download App</>,
    [LANGUAGES.ES]: <>Descargar App</>,
  },
  downloadOnTheAppStore: {
    [LANGUAGES.EN]: <>Download on the App Store</>,
    [LANGUAGES.ES]: <>Descargar en la App Store</>,
  },
  getItOnGooglePlay: {
    [LANGUAGES.EN]: <>Get it on Google Play</>,
    [LANGUAGES.ES]: <>Consíguela en Google Play</>,
  },
  availableOnDate: {
    [LANGUAGES.EN]: <>Available on</>,
    [LANGUAGES.ES]: <>Disponible en</>,
  },
  messageSupport: {
    [LANGUAGES.EN]: <>Message support</>,
    [LANGUAGES.ES]: <>Enviar un mensaje a soporte</>,
  },
  amIEligible: {
    [LANGUAGES.EN]: <>Am I eligible?</>,
    [LANGUAGES.ES]: <>¿Soy elegible?</>,
  },
  emailPrivacyNoticeDescription1: {
    [LANGUAGES.EN]: (
      <>
        Please use the email address that you check most often. You’ll need it
        to log into your account and receive essential emails.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Utiliza la dirección de correo electrónico que revisas con más
        frecuencia. La necesitarás para iniciar sesión en tu cuenta y recibir
        correos electrónicos importantes.
      </>
    ),
  },
  emailPrivacyNoticeDescription2: {
    [LANGUAGES.EN]: <>I understand that Pivot will be sending some</>,
    [LANGUAGES.ES]: <>Entiendo que Pivot enviará algunos</>,
  },
  emailPrivacyNoticeDescription3: {
    [LANGUAGES.EN]: <>related to my Pivot Program to this email.</>,
    [LANGUAGES.ES]: (
      <>relacionados con mi Programa Pivot al correo electrónico anterior.</>
    ),
  },
  emailPrivacyNoticeDescription4: {
    [LANGUAGES.EN]: <>I have read and agree to</>,
    [LANGUAGES.ES]: <>He leído y accedo a</>,
  },
  pii: {
    [LANGUAGES.EN]: <>Personally Identifiable Information (PII)</>,
    [LANGUAGES.ES]: <>Información de identificación personal</>,
  },
  phi: {
    [LANGUAGES.EN]: <>Personal Health Information (PHI)</>,
    [LANGUAGES.ES]: <>Información de salud personal</>,
  },
  progressBarCounter: {
    [LANGUAGES.EN]: <>Step 1 of 3 steps</>,
    [LANGUAGES.ES]: <>Paso 1 de 3 pasos</>,
  },
  progressStepElig: {
    [LANGUAGES.EN]: <>ELIGIBILITY</>,
    [LANGUAGES.ES]: <>ELEGIBILIDAD</>,
  },
  progressStepBenefits: {
    [LANGUAGES.EN]: <>YOUR BENEFITS</>,
    [LANGUAGES.ES]: <>TUS BENEFICIOS</>,
  },
  progressStepWelcome: {
    [LANGUAGES.EN]: <>WELCOME</>,
    [LANGUAGES.ES]: <>BIENVENIDO</>,
  },
  tosMeasures: {
    [LANGUAGES.EN]: (
      <>Pivot has taken measures to protect your privacy, as described in the</>
    ),
    [LANGUAGES.ES]: (
      <>
        Pivot ha adoptado medidas para proteger tu privacidad, como se describe
        en la
      </>
    ),
  },
  tosAgree: {
    [LANGUAGES.EN]: (
      <>
        By clicking Continue, you are indicating that you have read and agree to
        these terms.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Al hacer clic en Continuar, indicas que has leído y aceptas estos
        términos.
      </>
    ),
  },
  fullName: {
    [LANGUAGES.EN]: <>Full name</>,
    [LANGUAGES.ES]: <>Nombre completo</>,
  },
  email: {
    [LANGUAGES.EN]: <>Email</>,
    [LANGUAGES.ES]: <>Correo electrónico</>,
  },
  gender: { [LANGUAGES.EN]: <>Gender</>, [LANGUAGES.ES]: <>Género</> },
  male: { [LANGUAGES.EN]: <>Male</>, [LANGUAGES.ES]: <>Masculino</> },
  female: { [LANGUAGES.EN]: <>Female</>, [LANGUAGES.ES]: <>Femenino</> },
  other: { [LANGUAGES.EN]: <>Other</>, [LANGUAGES.ES]: <>Otro</> },
  birthdate: {
    [LANGUAGES.EN]: <>Birthdate</>,
    [LANGUAGES.ES]: <>Fecha de nacimiento</>,
  },
  address: { [LANGUAGES.EN]: <>Address</>, [LANGUAGES.ES]: <>Dirección</> },
  password: { [LANGUAGES.EN]: <>Password</>, [LANGUAGES.ES]: <>Contraseña</> },
  currentPassword: {
    [LANGUAGES.EN]: <>Current Password</>,
    [LANGUAGES.ES]: <>Contraseña actual</>,
  },
  newPassword: {
    [LANGUAGES.EN]: <>New Password</>,
    [LANGUAGES.ES]: <>Contraseña nueva</>,
  },
  mobileNumber: {
    [LANGUAGES.EN]: <>Mobile Number</>,
    [LANGUAGES.ES]: <>Número de celular</>,
  },
  notProvided: {
    [LANGUAGES.EN]: <>Not provided</>,
    [LANGUAGES.ES]: <>No indicado</>,
  },
  firstName: { [LANGUAGES.EN]: <>First name</>, [LANGUAGES.ES]: <>Nombre</> },
  lastName: { [LANGUAGES.EN]: <>Last name</>, [LANGUAGES.ES]: <>Apellido</> },
  addressLine1: {
    [LANGUAGES.EN]: <>Street and Number, P.O. box, c/o.</>,
    [LANGUAGES.ES]: <>Calle y número, apartado postal, a la atención de.</>,
  },
  addressLine2: {
    [LANGUAGES.EN]: <>Apartment, suite, unit, building, floor, etc.</>,
    [LANGUAGES.ES]: <>Departamento, suite, unidad, edificio, piso, etc.</>,
  },
  city: { [LANGUAGES.EN]: <>City</>, [LANGUAGES.ES]: <>Ciudad</> },
  state: { [LANGUAGES.EN]: <>State</>, [LANGUAGES.ES]: <>Estado</> },
  zipCode: {
    [LANGUAGES.EN]: <>ZIP Code</>,
    [LANGUAGES.ES]: <>Código postal</>,
  },
  accessCode: {
    [LANGUAGES.EN]: <>Access Code</>,
    [LANGUAGES.ES]: <>Código de acceso</>,
  },
  okReceivingSms: {
    [LANGUAGES.EN]: (
      <>
        I'm ok receiving text messages related to Pivot registration and
        customer support.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Acepto recibir mensajes de texto relacionados con registro de Pivot y
        soporte al cliente.
      </>
    ),
  },
  okReceivingSmsAccount: {
    [LANGUAGES.EN]: <>My Pivot account, customer service, or support</>,
    [LANGUAGES.ES]: <>Mi cuenta Pivot, servicio al cliente o soporte</>,
  },
  okReceivingSmsMarketing: {
    [LANGUAGES.EN]: (
      <>Getting the most out of Pivot (new features, special promotions)</>
    ),
    [LANGUAGES.ES]: (
      <>
        Cómo aprovechar Pivot al máximo (nuevas funciones, promociones
        especiales)
      </>
    ),
  },
  smsPrivacyNotice: {
    [LANGUAGES.EN]: (
      <>
        Texting is not considered secure. You may change your texting
        preferences at any time. Messaging & data rates may apply.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        El envío de mensajes de texto no se considera seguro. Puedes cambiar sus
        preferencias de mensajes de texto en cualquier momento. Es posible que
        se apliquen tarifas de mensajería y datos.
      </>
    ),
  },
  emailTypoSuggestion: {
    [LANGUAGES.EN]: <>Did you mean</>,
    [LANGUAGES.ES]: <>Quisiste decir</>,
  },
  pwdCriteriaNumChars: {
    [LANGUAGES.EN]: <>9 characters</>,
    [LANGUAGES.ES]: <>9 caracteres</>,
  },
  pwdCriteriaUpperCase: {
    [LANGUAGES.EN]: <>1 uppercase character</>,
    [LANGUAGES.ES]: <>1 carácter en mayúscula</>,
  },
  pwdCriteriaLowerCase: {
    [LANGUAGES.EN]: <>1 lowercase character</>,
    [LANGUAGES.ES]: <>1 carácter en minúscula</>,
  },
  pwdCriteriaNumber: {
    [LANGUAGES.EN]: <>1 number</>,
    [LANGUAGES.ES]: <>1 número</>,
  },
  pwdCriteriaValid: {
    [LANGUAGES.EN]: <>Password is now valid</>,
    [LANGUAGES.ES]: <>La contraseña ahora es válida</>,
  },
  pwdCriteriaNeeds: {
    [LANGUAGES.EN]: <>Password needs at least</>,
    [LANGUAGES.ES]: <>La contraseña necesita al menos</>,
  },
  pwdCriteriaMissing: {
    [LANGUAGES.EN]: <>Password is missing at least</>,
    [LANGUAGES.ES]: <>A la contraseña le falta al menos</>,
  },
  pwdCriteriaShow: {
    [LANGUAGES.EN]: <>Show password</>,
    [LANGUAGES.ES]: <>Mostrar contraseña</>,
  },
  pwdCriteriaHide: {
    [LANGUAGES.EN]: <>Hide password</>,
    [LANGUAGES.ES]: <>Ocultar contraseña</>,
  },
  datePickerDay: { [LANGUAGES.EN]: <>Day</>, [LANGUAGES.ES]: <>Día</> },
  datePickerMonth: { [LANGUAGES.EN]: <>Month</>, [LANGUAGES.ES]: <>Mes</> },
  datePickerYear: {
    [LANGUAGES.EN]: <>Year (YYYY)</>,
    [LANGUAGES.ES]: <>Año (AAAA)</>,
  },
  monthJan: { [LANGUAGES.EN]: <>January</>, [LANGUAGES.ES]: <>Enero</> },
  monthFeb: { [LANGUAGES.EN]: <>February</>, [LANGUAGES.ES]: <>Febrero</> },
  monthMar: { [LANGUAGES.EN]: <>March</>, [LANGUAGES.ES]: <>Marzo</> },
  monthApr: { [LANGUAGES.EN]: <>April</>, [LANGUAGES.ES]: <>Abril</> },
  monthMay: { [LANGUAGES.EN]: <>May</>, [LANGUAGES.ES]: <>Mayo</> },
  monthJun: { [LANGUAGES.EN]: <>June</>, [LANGUAGES.ES]: <>Junio</> },
  monthJul: { [LANGUAGES.EN]: <>July</>, [LANGUAGES.ES]: <>Julio</> },
  monthAug: { [LANGUAGES.EN]: <>August</>, [LANGUAGES.ES]: <>Agosto</> },
  monthSep: { [LANGUAGES.EN]: <>September</>, [LANGUAGES.ES]: <>Septiembre</> },
  monthOct: { [LANGUAGES.EN]: <>October</>, [LANGUAGES.ES]: <>Octubre</> },
  monthNov: { [LANGUAGES.EN]: <>November</>, [LANGUAGES.ES]: <>Noviembre</> },
  monthDec: { [LANGUAGES.EN]: <>December</>, [LANGUAGES.ES]: <>Diciembre</> },
  contactUsAt: {
    [LANGUAGES.EN]: <>Contact us at</>,
    [LANGUAGES.ES]: <>Contáctanos en</>,
  },
  ifCodeUnknown: {
    [LANGUAGES.EN]: <>if you don’t know this code.</>,
    [LANGUAGES.ES]: <>si no conoces este código.</>,
  },
  enterLegalFirstName: {
    [LANGUAGES.EN]: <>Please enter your legal first name</>,
    [LANGUAGES.ES]: <>Ingresa tu nombre legal</>,
  },
  enterLegalLastName: {
    [LANGUAGES.EN]: <>Please enter your legal last name</>,
    [LANGUAGES.ES]: <>Ingresa tu apellido legal</>,
  },
  enterDob: {
    [LANGUAGES.EN]: <>Please enter your date of birth</>,
    [LANGUAGES.ES]: <>Ingresa tu fecha de nacimiento</>,
  },
  enterZipcode: {
    [LANGUAGES.EN]: <>Please enter your five digit home address zip code</>,
    [LANGUAGES.ES]: (
      <>Ingresa el código postal de cinco dígitos de tu domicilio</>
    ),
  },
  enterMemberId: {
    [LANGUAGES.EN]: <>Please enter your Member ID</>,
    [LANGUAGES.ES]: <>Ingresa tu identificación de miembro</>,
  },
  enterGroupNumber: {
    [LANGUAGES.EN]: <>Please enter your group number</>,
    [LANGUAGES.ES]: <>Ingresa tu número de grupo</>,
  },
  accessCodeValidationError: {
    [LANGUAGES.EN]: <>Error with access code validation</>,
    [LANGUAGES.ES]: <>Error al validar el código de acceso</>,
  },
  pivotForSmoking: {
    [LANGUAGES.EN]: <>Pivot for smoking</>,
    [LANGUAGES.ES]: <>Pivot para fumadores</>,
  },
  pivotForVaping: {
    [LANGUAGES.EN]: <>Pivot for vape</>,
    [LANGUAGES.ES]: <>Pivot para vapeadores</>,
  },
  pivotForOther: {
    [LANGUAGES.EN]: <>Pivot for other tobacco</>,
    [LANGUAGES.ES]: <>Pivot para otros productos de tabaco</>,
  },
  pivotForOtherLinkLabel: {
    [LANGUAGES.EN]: <>Pivot for other</>,
    [LANGUAGES.ES]: <>Pivot para otros</>,
  },
  whyPivotWorks: {
    [LANGUAGES.EN]: <>Why Pivot works</>,
    [LANGUAGES.ES]: <>Por qué funciona Pivot</>,
  },
  iCtaTitle: {
    [LANGUAGES.EN]: <>What best describes you?</>,
    [LANGUAGES.ES]: <>¿Qué te describe mejor?</>,
  },
  iCtaQuestion1: {
    [LANGUAGES.EN]: <>I’m not sure about quitting</>,
    [LANGUAGES.ES]: <>No estoy seguro de dejar el hábito</>,
  },
  iCtaTitle1: {
    [LANGUAGES.EN]: <>Pivot welcomes all people who smoke.</>,
    [LANGUAGES.ES]: <>Pivot da la bienvenida a todas las personas que fuman.</>,
  },
  iCtaAnswer1: {
    [LANGUAGES.EN]: (
      <>
        Many people join Pivot with no intention of quitting and still make
        progress! We invite you to give us a try and see what you may learn.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        ¡Muchas personas se unen a Pivot sin la intención de dejar el hábito y
        aun así progresan! Te invitamos a darnos una oportunidad y ver qué
        puedes aprender.
      </>
    ),
  },
  iCtaButton1: {
    [LANGUAGES.EN]: <>Get Started</>,
    [LANGUAGES.ES]: <>Empezar</>,
  },
  iCtaQuestion2: {
    [LANGUAGES.EN]: <>I want to quit but can’t find the motivation</>,
    [LANGUAGES.ES]: (
      <>Quiero dejar de fumar pero no encuentro la motivación necesaria</>
    ),
  },
  iCtaTitle2: {
    [LANGUAGES.EN]: <>We hear you, and we can help with that.</>,
    [LANGUAGES.ES]: <>Te escuchamos y podemos ayudarte con eso.</>,
  },
  iCtaAnswer2: {
    [LANGUAGES.EN]: (
      <>
        Our patented Breath Sensor measures the levels of carbon monoxide (a key
        cigarette toxin) in your breath. When you smoke fewer cigarettes, you’ll
        be able to see your carbon monoxide levels go down in real time. That’s
        motivation!
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Nuestro Sensor de Respiración patentado mide los niveles de monóxido de
        carbono (una toxina clave del cigarrillo) en el aliento. Cuando fumes
        menos cigarrillos, podrás ver en tiempo real cómo tus niveles de
        monóxido de carbono disminuyen. ¡Eso te motivará!
      </>
    ),
  },
  iCtaButton2: {
    [LANGUAGES.EN]: <>Get Started</>,
    [LANGUAGES.ES]: <>Empezar</>,
  },
  iCtaQuestion3: {
    [LANGUAGES.EN]: <>I’d like to try reducing my tobacco use</>,
    [LANGUAGES.ES]: <>Me gustaría intentar reducir mi consumo de tabaco</>,
  },
  iCtaTitle3: {
    [LANGUAGES.EN]: <>Great, any reduction is a win!</>,
    [LANGUAGES.ES]: <>¡Excelente, cualquier reducción es una ganancia!</>,
  },
  iCtaAnswer3: {
    [LANGUAGES.EN]: (
      <>
        Pivot helps you reduce a little bit each week, with challenges and
        activities to crush cravings.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Pivot te ayuda a reducir un poco cada semana, con desafíos y actividades
        para frenar los deseos intensos.
      </>
    ),
  },
  iCtaButton3: {
    [LANGUAGES.EN]: <>Get Started</>,
    [LANGUAGES.ES]: <>Empezar</>,
  },
  iCtaQuestion4: {
    [LANGUAGES.EN]: <>I’m ready to get started on a quit plan</>,
    [LANGUAGES.ES]: (
      <>Estoy listo para comenzar con un plan para dejar de fumar</>
    ),
  },
  iCtaTitle4: {
    [LANGUAGES.EN]: <>Great, Pivot can help set you up for success!</>,
    [LANGUAGES.ES]: (
      <>¡Excelente, Pivot puede ayudarte a prepararte para el éxito!</>
    ),
  },
  iCtaAnswer4: {
    [LANGUAGES.EN]: (
      <>
        We provide all the tools you need to successfully reduce and quit
        tobacco — with the support level and pace that you choose.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Te proporcionamos todas las herramientas que necesitas para reducir tu
        consumo y dejar de fumar con éxito, con el nivel de apoyo y el ritmo que
        elijas.
      </>
    ),
  },
  iCtaButton4: {
    [LANGUAGES.EN]: <>Get Started</>,
    [LANGUAGES.ES]: <>Empezar</>,
  },
  iCtaQuestion5: {
    [LANGUAGES.EN]: <>I quit. I’m afraid it won’t last.</>,
    [LANGUAGES.ES]: <>Lo dejo. Me temo que no durará.</>,
  },
  iCtaTitle5: {
    [LANGUAGES.EN]: <>We got you!</>,
    [LANGUAGES.ES]: <>¡Lo entendemos!</>,
  },
  iCtaAnswer5: {
    [LANGUAGES.EN]: (
      <>
        Give yourself a break. It’s hard to quit for good. Most of us need
        support to stay the course. Pivot can help you manage cravings and
        slips, to keep living a tobacco-free life.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Tómate un respiro. Es difícil dejarlo para siempre. La mayoría de
        nosotros necesitamos apoyo para mantener el rumbo. Pivot puede ayudarte
        a controlar los deseos intensos y las recaídas para que puedas seguir
        viviendo una vida libre de tabaco.
      </>
    ),
  },
  iCtaButton5: {
    [LANGUAGES.EN]: <>Get Started</>,
    [LANGUAGES.ES]: <>Empezar</>,
  },
  bandPrivacyPolicy: {
    [LANGUAGES.EN]: (
      <>
        Pivot is compliant with the Health Insurance Portability and
        Accountability Act of 1996 (HIPAA) and FDA regulations. We at Pivot take
        your privacy and security very seriously and we will never sell your
        data.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Pivot cumple con la Ley de Transferencia y Responsabilidad del Seguro
        Médico (Health Insurance Portability and Accountability Act, HIPAA por
        sus siglas en inglés) de 1996 y las regulaciones de la FDA. En Pivot nos
        tomamos muy en serio tu privacidad y seguridad y nunca venderemos tus
        datos.
      </>
    ),
  },
  sbsAppMenu: {
    [LANGUAGES.EN]: <>Pivot App</>,
    [LANGUAGES.ES]: <>Aplicación Pivot</>,
  },
  sbsAppTopTitle: {
    [LANGUAGES.EN]: <>Pivot App</>,
    [LANGUAGES.ES]: <>Aplicación Pivot</>,
  },
  sbsAppTitle: {
    [LANGUAGES.EN]: <>Learn and Practice</>,
    [LANGUAGES.ES]: <>Aprende y practica</>,
  },
  sbsAppCopy: {
    [LANGUAGES.EN]: (
      <>
        Whether it’s learning, reducing or preparing to quit ー set your own
        goals and track your progress. Build a personalized quit plan with daily
        activities, challenges and simple next steps to meet your goals.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Ya sea que se trate de aprender, reducir o prepararse para dejar el
        hábito, establece tus propios objetivos y da seguimiento a tu progreso.
        Crea un plan personalizado para dejar el hábito con actividades diarias,
        desafíos y próximos pasos sencillos para alcanzar tus objetivos.
      </>
    ),
  },
  sbsSensorMenu: {
    [LANGUAGES.EN]: <>Pivot Breath Sensor</>,
    [LANGUAGES.ES]: <>Sensor de Respiración Pivot</>,
  },
  sbsSensorTopTitle: {
    [LANGUAGES.EN]: <>Pivot Breath Sensor</>,
    [LANGUAGES.ES]: <>Sensor de Respiración Pivot</>,
  },
  sbsSensorTitle: {
    [LANGUAGES.EN]: <>Build Motivation</>,
    [LANGUAGES.ES]: <>Conseguir motivación</>,
  },
  sbsSensorCopy: {
    [LANGUAGES.EN]: (
      <>
        Revolutionary device instantly measures the amount of carbon monoxide (a
        key cigarette toxin) in your breath. Visualize progress and see how even
        small changes can have major impacts. Available for those who smoke
        cigarettes.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Un dispositivo revolucionario mide instantáneamente la cantidad de
        monóxido de carbono (una toxina clave del cigarrillo) en el aliento.
        Visualiza el progreso y descubre cómo incluso los pequeños cambios
        pueden tener impactos importantes. Disponible para quienes fuman
        cigarrillos.
      </>
    ),
  },
  sbsNrtMenu: {
    [LANGUAGES.EN]: <>Free Nicotine Replacement</>,
    [LANGUAGES.ES]: <>Reemplazo gratuito de nicotina</>,
  },
  sbsNrtTopTitle: {
    [LANGUAGES.EN]: <>Free Nicotine Replacement</>,
    [LANGUAGES.ES]: <>Reemplazo gratuito de nicotina</>,
  },
  sbsNrtTitle: {
    [LANGUAGES.EN]: <>Reduce Cravings</>,
    [LANGUAGES.ES]: <>Reducir los deseos intensos</>,
  },
  sbsNrtCopy: {
    [LANGUAGES.EN]: (
      <>
        Free nicotine replacement products provide relief from cravings, proven
        to increase the chances of successfully quitting. Available to order in
        the app for those who smoke cigarettes.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Los productos gratuitos de reemplazo de nicotina frenan los deseos
        intensos y se ha demostrado que aumentan las posibilidades de dejar el
        hábito con éxito. Disponible para ordenar en la aplicación para quienes
        fuman cigarrillos.
      </>
    ),
  },
  sbsCoachingMenu: {
    [LANGUAGES.EN]: <>Personalized Guidance</>,
    [LANGUAGES.ES]: <>Orientación personalizada</>,
  },
  sbsCoachingTopTitle: {
    [LANGUAGES.EN]: <>Personalized Guidance</>,
    [LANGUAGES.ES]: <>Orientación personalizada</>,
  },
  sbsCoachingTitle: {
    [LANGUAGES.EN]: <>Support When Needed</>,
    [LANGUAGES.ES]: <>Soporte cuando se necesita</>,
  },
  sbsCoachingCopy: {
    [LANGUAGES.EN]: (
      <>
        You can tap into Pivot’s active user community for collective wisdom, or
        chat with an expert for personalized support, help dealing with triggers
        and proven strategies for success.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Puedes acceder a la comunidad de usuarios activos de Pivot para obtener
        una visión colectiva o conversar con un experto para obtener apoyo
        personalizado, ayuda para lidiar con desencadenantes y estrategias
        comprobadas para lograr el éxito.
      </>
    ),
  },
  cdphpSbsNrtMenu: {
    [LANGUAGES.EN]: <>No-Cost Nicotine Replacement</>,
    [LANGUAGES.ES]: <>Reemplazo de nicotina sin costo</>,
  },
  cdphpSbsNrtTopTitle: {
    [LANGUAGES.EN]: <>No-Cost Nicotine Replacement</>,
    [LANGUAGES.ES]: <>Reemplazo de nicotina sin costo</>,
  },
  cdphpSbsNrtTitle: {
    [LANGUAGES.EN]: <>Reduce Cravings</>,
    [LANGUAGES.ES]: <>Reducir los deseos intensos</>,
  },
  cdphpSbsNrtCopy: {
    [LANGUAGES.EN]: (
      <>
        A 4-week supply of nicotine replacement products, provided at no cost,
        helps relieve cravings and is proven to increase the chances of
        successfully quitting. Available to order in the app for those who smoke
        cigarettes.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Un suministro de 4 semanas de productos de reemplazo de nicotina,
        proporcionado sin costo, ayuda a frenar los deseos intensos y está
        demostrado que aumenta las posibilidades de dejar el hábito con éxito.
        Disponible para ordenar en la aplicación para quienes fuman cigarrillos.
      </>
    ),
  },
  bcncSbsSensorMenu: {
    [LANGUAGES.EN]: <>Pivot Breath Sensor</>,
    [LANGUAGES.ES]: <>Sensor de Respiración Pivot</>,
  },
  bcncSbsSensorTopTitle: {
    [LANGUAGES.EN]: <>Pivot Breath Sensor</>,
    [LANGUAGES.ES]: <>Sensor de Respiración Pivot</>,
  },
  bcncSbsSensorTitle: {
    [LANGUAGES.EN]: <>Track Progress</>,
    [LANGUAGES.ES]: <>Monitorear el progreso</>,
  },
  bcncSbsSensorCopy: {
    [LANGUAGES.EN]: (
      <>
        Track changes in your breath day-to-day. Visualize progress and see how
        even small changes can have major impacts on levels of carbon monoxide
        (a key cigarette toxin) in your breath.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Monitorea los cambios en tu respiración día a día. Visualiza el progreso
        y descubre cómo incluso los cambios más pequeños pueden tener impactos
        importantes en los niveles de monóxido de carbono (una toxina clave del
        cigarrillo) en tu aliento.
      </>
    ),
  },
  bcncSbsNrtMenu: {
    [LANGUAGES.EN]: <>Free Nicotine Replacement Therapy</>,
    [LANGUAGES.ES]: <>Terapia de Remplazo de Nicotina gratuita</>,
  },
  bcncSbsNrtTopTitle: {
    [LANGUAGES.EN]: <>Free Nicotine Replacement Therapy (NRT)</>,
    [LANGUAGES.ES]: <>Terapia de Remplazo de Nicotina (NRT) gratuita</>,
  },
  bcncSbsNrtTitle: {
    [LANGUAGES.EN]: <>Reduce Cravings</>,
    [LANGUAGES.ES]: <>Reducir los deseos intensos</>,
  },
  bcncSbsNrtCopy: {
    [LANGUAGES.EN]: (
      <>
        Free NRT products provide relief from cravings, proven to increase the
        chances of successfully quitting. Order in the app and have them
        delivered directly to your door.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Los productos gratuitos de la NRT frenan los deseos intensos y se ha
        demostrado que aumentan las posibilidades de dejar el hábito con éxito.
        Ordena en la aplicación y recíbelos directamente en tu puerta.
      </>
    ),
  },
  socialProofTitle1: {
    [LANGUAGES.EN]: <>Increased confidence</>,
    [LANGUAGES.ES]: <>Mayor confianza</>,
  },
  socialProofQuote1: {
    [LANGUAGES.EN]: (
      <>
        I’ve tried everything — nothing ever works. For the first time ever, I
        can see something working, and it’s changing my mindset, it’s happening
        for me.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        He intentado todo, pero nada funciona para mí. Por primera vez, puedo
        ver que algo funciona y está cambiando mi forma de pensar, me está
        pasando.
      </>
    ),
  },
  socialProofTitle2: {
    [LANGUAGES.EN]: <>Increased motivation</>,
    [LANGUAGES.ES]: <>Mayor motivación</>,
  },
  socialProofQuote2: {
    [LANGUAGES.EN]: (
      <>
        There was no judgment and that ended up me quitting smoking because of
        the motivation and moral support I got.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Nadie me juzgó y eso hizo que dejara el hábito de fumar por la
        motivación y el apoyo moral que recibí.
      </>
    ),
  },
  socialProofTitle3: {
    [LANGUAGES.EN]: <>Best Quit Smoking Tool</>,
    [LANGUAGES.ES]: <>La mejor herramienta para dejar de fumar</>,
  },
  socialProofQuote3: {
    [LANGUAGES.EN]: (
      <>
        I really believe in this program. It has done wonders for me. Every time
        I have a stressful day, I go back to what I learned from Pivot.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Realmente creo en este programa. Ha hecho maravillas por mí. Cada vez
        que tengo un día estresante, vuelvo a lo que aprendí de Pivot.
      </>
    ),
  },
  socialProofDisclaimer: {
    [LANGUAGES.EN]: (
      <>
        Pivot is a flexible program designed to address all forms of tobacco
        use, including cigarettes, smokeless tobacco, e-cigarettes and other
        vaping devices, with different feature sets available as provided by
        your employer or health plan.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Pivot es un programa flexible diseñado para abordar todas las formas de
        consumo de tabaco, incluidos cigarrillos, tabaco sin humo, cigarrillos
        electrónicos y otros dispositivos de vapeo, con diferentes
        características a tu disposición a través de tu empleador o plan de
        salud.
      </>
    ),
  },
  bcncLegalFooterDescription: {
    [LANGUAGES.EN]: (
      <>
        Blue Cross and Blue Shield of North Carolina (Blue Cross NC) does not
        discriminate on the basis of race, color, national origin, sex, age or
        disability in its health programs and activities.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Blue Cross and Blue Shield of North Carolina (Blue Cross NC) no
        discriminan por motivos de raza, color, nacionalidad, sexo, edad o
        discapacidad en sus programas y actividades de salud.
      </>
    ),
  },
  bcncLegalFooterLearnMore: {
    [LANGUAGES.EN]: (
      <>
        Learn more about our non-discrimination policy and no-cost services
        available to you.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Obtén más información sobre nuestra política de no discriminación y los
        servicios gratuitos que están a tu disposición.
      </>
    ),
  },
  bcncLegalFooterInfoInOtherLangs: {
    [LANGUAGES.EN]: <>Information in Other Languages</>,
    [LANGUAGES.ES]: <>Información en otros idiomas</>,
  },
  bcncLegalFooterNonDiscriminationPolicy: {
    [LANGUAGES.EN]: <>Non-Discrimination Policy and Accessibility Services</>,
    [LANGUAGES.ES]: (
      <>Política de no discriminación y servicios de accesibilidad</>
    ),
  },
  bcncLegalFooterFootnote1: {
    [LANGUAGES.EN]: (
      <>
        Blue Cross NC provides Pivot for your convenience and is not liable in
        any way for services received. Decisions regarding care should be made
        with the advice of a doctor. Blue Cross NC reserves the right to
        discontinue or change the program at any time.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Blue Cross NC proporciona Pivot para su comodidad y no es responsable de
        ninguna manera por los servicios recibidos. Las decisiones relativas a
        la atención deben tomarse previa consulta con un médico. Blue Cross NC
        se reserva el derecho de suspender o modificar el programa en cualquier
        momento.
      </>
    ),
  },
  bcncLegalFooterFootnote2: {
    [LANGUAGES.EN]: (
      <>
        BLUE CROSS&reg;, BLUE SHIELD&reg;, and the Cross and Shield symbols are
        registered marks of the Blue Cross and Blue Shield Association, an
        association of independent Blue Cross and Blue Shield plans. All other
        marks and trade names are the property of their respective owners. Pivot
        is a separate company that is solely responsible for the services it is
        providing. Pivot does not offer Blue Cross or Blue Shield products or
        services. Blue Cross and Blue Shield of North Carolina is an independent
        licensee of the Blue Cross and Blue Shield Association. U36831, 9/20.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        BLUE CROSS&reg;, BLUE SHIELD&reg;, y los símbolos Cross and Shield son
        marcas registradas de Blue Cross and Blue Shield Association, una
        asociación de planes independientes de Blue Cross and Blue Shield. Todas
        las demás marcas y nombres comerciales son propiedad de sus respectivos
        dueños. Pivot es una empresa independiente que es la única responsable
        de los servicios que proporciona. Pivot no ofrece productos o servicios
        de Blue Cross o Blue Shield. Blue Cross and Blue Shield of North
        Carolina es un licenciatario independiente de Blue Cross and Blue Shield
        Association. U36831, 20/9.
      </>
    ),
  },
  heroTitle: {
    [LANGUAGES.EN]: <>With Pivot, quitting has never been easier</>,
    [LANGUAGES.ES]: <>Con Pivot, dejar el hábito jamás había sido tan fácil</>,
  },
  heroCopy: {
    [LANGUAGES.EN]: (
      <>
        The Pivot SmartSensor is here
        <br />
        Free if you’re eligible
      </>
    ),
    [LANGUAGES.ES]: <>El Pivot SmartSensor está aquí Gratis si eres elegible</>,
  },
  cdphpHeroCopy: {
    [LANGUAGES.EN]: (
      <>
        The Pivot SmartSensor is here. All at no cost to you if you’re eligible
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        El Pivot SmartSensor está aquí Totalmente sin costo para ti si eres
        elegible
      </>
    ),
  },
  heroVapeTitle: {
    [LANGUAGES.EN]: <>When I quit vaping, I’ll</>,
    [LANGUAGES.ES]: <>Cuando dejes de vapear,</>,
  },
  heroVapeCopy: {
    [LANGUAGES.EN]: (
      <>
        Chart your path toward a healthier & wealthier future, free of vaping.
        It starts with Pivot, at no cost to you if you’re eligible.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Traza tu camino hacia un futuro más saludable y con más dinero, libre de
        vapeo. Comienza con Pivot, sin costo para ti si eres elegible.
      </>
    ),
  },
  heroTypeWriterWord1: {
    [LANGUAGES.EN]: <>breathe better</>,
    [LANGUAGES.ES]: <>respira mejor</>,
  },
  heroTypeWriterWord2: {
    [LANGUAGES.EN]: <>save money</>,
    [LANGUAGES.ES]: <>ahorra dinero</>,
  },
  heroTypeWriterWord3: {
    [LANGUAGES.EN]: <>kick the nagging nicotine</>,
    [LANGUAGES.ES]: <>deja atrás la molesta nicotina</>,
  },
  heroTypeWriterWord4: {
    [LANGUAGES.EN]: <>find my focus</>,
    [LANGUAGES.ES]: <>encontrar mi enfoque</>,
  },
  heroTypeWriterWord5: {
    [LANGUAGES.EN]: <>stress less</>,
    [LANGUAGES.ES]: <>menos estrés</>,
  },
  heroOtherTitle: {
    [LANGUAGES.EN]: <>Meet Pivot - the quit program proven to work</>,
    [LANGUAGES.ES]: (
      <>
        Conoce Pivot: el programa para dejar de fumar que ha demostrado que
        funciona
      </>
    ),
  },
  heroOtherCopy: {
    [LANGUAGES.EN]: (
      <>
        Whether you chew, smoke cigars, hookah, or more, you can take back
        control from tobacco. Start today, at no cost to you if you’re eligible.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Ya sea que masques tabaco, fumes puros, pipas de agua u otros, puedes
        recuperar el control sobre el tabaco. Comienza hoy, sin costo para ti si
        eres elegible.
      </>
    ),
  },
  bcncEligNote: {
    [LANGUAGES.EN]: (
      <>
        NOTE: This site is owned and operated by Pivot. Pivot is responsible for
        this site, including the privacy policies that govern the site.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        NOTA: Este sitio es propiedad de Pivot y es operado por esta. Pivot es
        responsable de este sitio, incluidas las políticas de privacidad que lo
        rigen.
      </>
    ),
  },
  cdphpEligNote: {
    [LANGUAGES.EN]: (
      <>
        Pivot is a flexible program designed to address all forms of tobacco
        use, including cigarettes, smokeless tobacco, e-cigarettes and other
        vaping devices, with different feature sets available as provided by
        your employer or health plan.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Pivot es un programa flexible diseñado para abordar todas las formas de
        consumo de tabaco, incluidos cigarrillos, tabaco sin humo, cigarrillos
        electrónicos y otros dispositivos de vapeo, con diferentes
        características a tu disposición a través de tu empleador o plan de
        salud.
      </>
    ),
  },
  privacyPolicyCopy1: {
    [LANGUAGES.EN]: (
      <>
        Pivot is compliant with the Health Insurance Portability and
        accountability Act of 1996 (HIPAA) and FDA regulations.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Pivot cumple con la Ley de Transferencia y Responsabilidad del Seguro
        Médico (Health Insurance Portability and Accountability Act, HIPAA por
        sus siglas en inglés) de 1996 y las regulaciones de la FDA.
      </>
    ),
  },
  privacyPolicyCopy2: {
    [LANGUAGES.EN]: (
      <>
        We at Pivot take your privacy and security very seriously and we will
        never sell your data.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        En Pivot nos tomamos muy en serio tu privacidad y seguridad y nunca
        venderemos tus datos.
      </>
    ),
  },
  sectionAppSensorTitle: {
    [LANGUAGES.EN]: <>Skip a cigarette, see your body’s response</>,
    [LANGUAGES.ES]: (
      <>Deja de fumar un cigarrillo, observs la respuesta de tu cuerpo</>
    ),
  },
  sectionAppSensorCopy: {
    [LANGUAGES.EN]: (
      <>
        Pivot’s SmartSensor measures carbon monoxide (CO) – a toxin in cigarette
        smoke. Skip a cigarette, and your numbers will decrease. See progress
        one breath at a time.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        El SmartSensor de Pivot mide el monóxido de carbono (CO), una toxina
        presente en el humo del cigarrillo. Deja de fumar un cigarrillo y tus
        cifras disminuirán. Observa cómo progresas una respiración a la vez.
      </>
    ),
  },
  sectionAppSensorButton: {
    [LANGUAGES.EN]: <>Claim my sensor</>,
    [LANGUAGES.ES]: <>Solicitar mi sensor</>,
  },
  sectionCoachingTitle: {
    [LANGUAGES.EN]: <>Help when you want it</>,
    [LANGUAGES.ES]: <>Ayuda cuando la necesites</>,
  },
  sectionCoachingCopy: {
    [LANGUAGES.EN]: (
      <>
        You can Pivot on your own, but sometimes we all need a helping hand.
        Your Pivot coach is available through private, in-app messaging.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Puedes usar tu Pivot por tu cuenta, pero todos necesitamos ayuda a
        veces. Tu entrenador Pivot está disponible a través de mensajes privados
        dentro de la aplicación.
      </>
    ),
  },
  sectionCoachingButton: {
    [LANGUAGES.EN]: <>Build my support team</>,
    [LANGUAGES.ES]: <>Formar mi equipo de soporte</>,
  },
  sectionCoreLoopTitle: {
    [LANGUAGES.EN]: <>Engaging, enlightening, and even a little fun</>,
    [LANGUAGES.ES]: <>Atractivo, revelador e incluso algo divertido</>,
  },
  sectionCoreLoopCopy: {
    [LANGUAGES.EN]: (
      <>
        You know how quit programs usually feel like homework? Not Pivot. Swap a
        smoke break for a 5 minute game.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        ¿Sabes que los programas para dejar de fumar se sienten como una
        obligación? Eso no pasa con Pivot. Cambia una pausa para fumar por un
        juego de 5 minutos.
      </>
    ),
  },
  sectionCoreLoopButton: {
    [LANGUAGES.EN]: <>Try something new</>,
    [LANGUAGES.ES]: <>Prueba algo nuevo</>,
  },
  sectionNrtTitle: {
    [LANGUAGES.EN]: <>Free tools to combat cravings</>,
    [LANGUAGES.ES]: (
      <>Herramientas gratuitas para combatir los deseos intensos</>
    ),
  },
  sectionNrtCopy: {
    [LANGUAGES.EN]: <>From nicotine replacements to habit-busting mind-hacks</>,
    [LANGUAGES.ES]: (
      <>
        Desde sustitutos de la nicotina hasta trucos mentales para acabar con
        los hábitos
      </>
    ),
  },
  sectionNrtButton: {
    [LANGUAGES.EN]: <>Claim my benefit</>,
    [LANGUAGES.ES]: <>Solicitar mi beneficio</>,
  },
  sectionOtherCoachingTitle: {
    [LANGUAGES.EN]: <>Receive expert support from a certified coach</>,
    [LANGUAGES.ES]: (
      <>Recibe apoyo especializado de un entrenador certificado</>
    ),
  },
  sectionOtherCoachingCopy: {
    [LANGUAGES.EN]: (
      <>
        Quitting is much easier when it’s not done on your own. Leverage your
        assigned Pivot coach (a real person!) to gain confidence and kick your
        habit once and for all.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Dejar el hábito es mucho más fácil si no tienes que hacerlo solo.
        Aprovecha a tu entrenador Pivot asignado (¡una persona real!) para
        adquirir confianza y dejar este hábito de una vez por todas.
      </>
    ),
  },
  sectionOtherCoachingButton: {
    [LANGUAGES.EN]: <>Try Something New</>,
    [LANGUAGES.ES]: <>Pruebe algo nuevo</>,
  },
  sectionOtherCommunityTitle: {
    [LANGUAGES.EN]: <>Talk to a community of your peers</>,
    [LANGUAGES.ES]: <>Conversa con una comunidad de personas como tú</>,
  },
  sectionOtherCommunityCopy: {
    [LANGUAGES.EN]: (
      <>
        Found via the app, Pivot’s peer community may be just the thing you need
        to quit. Celebrate milestones and discuss your little (and big)
        victories over tobacco.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        La comunidad de personas como tú de Pivot, que se encuentra a través de
        la aplicación, puede ser justo lo que necesitas para dejar de fumar.
        Celebra los logros y analiza tus pequeñas (y grandes) victorias sobre el
        tabaco.
      </>
    ),
  },
  sectionOtherCommunityButton: {
    [LANGUAGES.EN]: <>I’m Ready to Enroll</>,
    [LANGUAGES.ES]: <>Estoy listo para matricularme</>,
  },
  sectionOtherDiscoverTitle: {
    [LANGUAGES.EN]: <>Discover personalized, engaging content</>,
    [LANGUAGES.ES]: <>Descubre contenido personalizado y atractivo</>,
  },
  sectionOtherDiscoverCopy: {
    [LANGUAGES.EN]: (
      <>
        The Pivot mobile app is available when you need it. Gain a quit kit in
        your pocket and create healthier habits through personalized content
        unique to your quit journey.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        La aplicación móvil Pivot está disponible cuando la necesitas. Obtén un
        kit de bolsillo para dejar el hábito y crea hábitos más saludables a
        través de contenido personalizado exclusivo que te guiará durante tu
        proceso para dejar de fumar.
      </>
    ),
  },
  sectionOtherDiscoverButton: {
    [LANGUAGES.EN]: <>Claim my spot</>,
    [LANGUAGES.ES]: <>Solicitar mi plaza</>,
  },
  sectionQuitKitTitle: {
    [LANGUAGES.EN]: <>Quit kit in your pocket</>,
    [LANGUAGES.ES]: <>Kit de bolsillo para dejar el hábito</>,
  },
  sectionQuitKitCopy: {
    [LANGUAGES.EN]: (
      <>
        Access Pivot from your phone anywhere, anytime. Activities are built to
        take 5 minutes or less.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Accede a Pivot desde tu teléfono en cualquier lugar y momento. Las
        actividades se diseñaron para durar 5 minutos o menos.
      </>
    ),
  },
  sectionQuitKitButton: {
    [LANGUAGES.EN]: <>Join now</>,
    [LANGUAGES.ES]: <>Únete ahora</>,
  },
  sectionVapeCoachingTitle: {
    [LANGUAGES.EN]: <>Personalized support when you need it</>,
    [LANGUAGES.ES]: <>Soporte personalizado cuando lo necesites</>,
  },
  sectionVapeCoachingCopy: {
    [LANGUAGES.EN]: (
      <>
        Your Pivot coach is available through private, in-app messaging. Pivot’s
        coaches are real people, specially trained in vaping cessation.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Tu entrenador Pivot está disponible a través de mensajes privados dentro
        de la aplicación. Los entrenadores Pivot son personas reales,
        especialmente formadas para ayudarte a dejar de vapear.
      </>
    ),
  },
  sectionVapeCoachingButton: {
    [LANGUAGES.EN]: <>Get Support</>,
    [LANGUAGES.ES]: <>Obtener apoyo</>,
  },
  sectionCravingsTitle: {
    [LANGUAGES.EN]: <>Crush your cravings</>,
    [LANGUAGES.ES]: <>Frena tus deseos intensos</>,
  },
  sectionCravingsCopy: {
    [LANGUAGES.EN]: (
      <>
        Do you vape to replace smoking, reduce stress, connect with friends?
        We’ve got tips for that.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        ¿Vapeas para reemplazar el fumar, reducir el estrés, conectarte con
        amigos? Tenemos consejos para eso.
      </>
    ),
  },
  sectionCravingsButton: {
    [LANGUAGES.EN]: <>Crush a Craving</>,
    [LANGUAGES.ES]: <>Frena tus deseos intensos</>,
  },
  sectionVapeFunTitle: {
    [LANGUAGES.EN]: <>Engaging, enlightening, and even a little fun</>,
    [LANGUAGES.ES]: <>Atractivo, revelador e incluso algo divertido</>,
  },
  sectionVapeFunCopy: {
    [LANGUAGES.EN]: (
      <>Relax, Pivot doesn’t feel like homework. How about a 5 minute game?</>
    ),
    [LANGUAGES.ES]: (
      <>
        Relájate, Pivot no se siente como una obligación. ¿Qué tal un juego de 5
        minutos?
      </>
    ),
  },
  sectionVapeFunButton: {
    [LANGUAGES.EN]: <>Join today</>,
    [LANGUAGES.ES]: <>Únete hoy</>,
  },
  sectionVapePaceTitle: {
    [LANGUAGES.EN]: <>Set the pace</>,
    [LANGUAGES.ES]: <>Establece tu ritmo</>,
  },
  sectionVapePaceCopy: {
    [LANGUAGES.EN]: (
      <>
        Find your right next step, know what to expect, and learn how to succeed
        with your goal – learning, cutting back, or quitting.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Encuentra el siguiente paso correcto, descubre qué esperar y cómo lograr
        tu objetivo: aprender, reducir o dejar el hábito.
      </>
    ),
  },
  sectionVapePaceButton: {
    [LANGUAGES.EN]: <>Claim My Plan</>,
    [LANGUAGES.ES]: <>Solicitar mi plan</>,
  },
  sectionVapeCQuitKitTitle: {
    [LANGUAGES.EN]: <>Quit kit in your pocket</>,
    [LANGUAGES.ES]: <>Kit de bolsillo para dejar el hábito</>,
  },
  sectionVapeCQuitKitCopy: {
    [LANGUAGES.EN]: (
      <>App-based & convenient, Pivot’s there when you need it.</>
    ),
    [LANGUAGES.ES]: (
      <>
        Conveniente y basado en aplicaciones, Pivot está ahí cuando lo
        necesitas.
      </>
    ),
  },
  sectionVapeCQuitKitButton: {
    [LANGUAGES.EN]: <>Get Pivot</>,
    [LANGUAGES.ES]: <>Obtener Pivot</>,
  },
  targetRasModalTitle: {
    [LANGUAGES.EN]: <>Healthier and wealthier.</>,
    [LANGUAGES.ES]: <>Más saludable y con más dinero.</>,
  },
  targetRasModalCopy: {
    [LANGUAGES.EN]: (
      <>
        Available at no charge to all Target team members and their medically
        enrolled dependents ages 18+. Participating in Pivot enables you to
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Disponible sin costo para todos los miembros del equipo de Target y sus
        dependientes mayores de 18 años inscritos en planes de salud. Participar
        en Pivot te permite
      </>
    ),
  },
  targetRasModalButton: {
    [LANGUAGES.EN]: (
      <>update your tobacco status and remove the tobacco surcharge</>
    ),
    [LANGUAGES.ES]: (
      <>
        actualizar tu condición de consumidor de tabaco y eliminar el recargo
        por tabaco
      </>
    ),
  },
  targetRasModalButtonAlt: {
    [LANGUAGES.EN]: <>Click to read more about the terms</>,
    [LANGUAGES.ES]: <>Haz clic para leer más sobre los términos</>,
  },
  raModalTitle: {
    [LANGUAGES.EN]: (
      <>
        In order to update your tobacco status and remove the tobacco surcharge,
        you must complete a minimum of 6 Pivot activities per week, for a total
        of 6 weeks (and they don't have to be in consecutive weeks).
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Para actualizar tu condición de consumidor de tabaco y eliminar el
        recargo por tabaco, debes completar un mínimo de 6 actividades Pivot por
        semana, para un total de 6 semanas (y no tienes que realizarlas en
        semanas consecutivas).
      </>
    ),
  },
  raModalDescription: {
    [LANGUAGES.EN]: (
      <>Activities can include any combination of the following:</>
    ),
    [LANGUAGES.ES]: (
      <>Las actividades pueden incluir cualquier combinación de lo siguiente:</>
    ),
  },
  raModalItem1: {
    [LANGUAGES.EN]: (
      <>
        Learn new skills with <strong>in-app Lessons</strong>
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Aprender nuevas habilidades con
        <strong> Lecciones dentro de la aplicación</strong>
      </>
    ),
  },
  raModalItem2: {
    [LANGUAGES.EN]: (
      <>
        Track your progress by <strong>taking a breath sample</strong> with the
        Pivot breath sensor
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Monitorear tu progreso mediante
        <strong>tomando una muestra de aliento</strong> con el sensor de
        respiración Pivot
      </>
    ),
  },
  raModalItem3: {
    [LANGUAGES.EN]: (
      <>
        Build confidence by doing a timed <strong>practice quit</strong>
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Generar confianza completando un
        <strong> ensayo para dejar de fumar</strong> cronometrado
      </>
    ),
  },
  raModalItem4: {
    [LANGUAGES.EN]: (
      <>
        Participate in an <strong>in-app chat</strong> with your Pivot coach or
        in community
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Participar en un <strong>chat dentro de la aplicación</strong> con tu
        entrenador Pivot o en la comunidad
      </>
    ),
  },
  raModalItem5: {
    [LANGUAGES.EN]: (
      <>
        Update your status with a <strong>Pivot check-in</strong>
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Actualizar tu condición con un<strong> registro en Pivot</strong>
      </>
    ),
  },
  raModalDisclaimer: {
    [LANGUAGES.EN]: (
      <>
        Pivot is compliant with the Health Insurance Portability and
        Accountability Act of 1996 (HIPAA) and FDA regulations. We at Pivot take
        your privacy and security very seriously and will never sell your data.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Pivot cumple con la Ley de Transferencia y Responsabilidad del Seguro
        Médico (Health Insurance Portability and Accountability Act, HIPAA por
        sus siglas en inglés) de 1996 y las regulaciones de la FDA. En Pivot nos
        tomamos muy en serio tu privacidad y seguridad y nunca venderemos tus
        datos.
      </>
    ),
  },
  accessCodeNotOPenModalMessage: {
    [LANGUAGES.EN]: <>Registration will be open starting on</>,
    [LANGUAGES.ES]: <>La inscripción estará abierta a partir del</>,
  },
  exitIntentModalTitle: {
    [LANGUAGES.EN]: (
      <>Don’t quit yet! Pivot works even if you don’t want to quit.</>
    ),
    [LANGUAGES.ES]: (
      <>
        ¡No dejes el hábito todavía! Pivot funciona incluso si no quieres dejar
        el hábito.
      </>
    ),
  },
  exitIntentModalCopy: {
    [LANGUAGES.EN]: (
      <>
        If you’re still not ready to try it but would like to learn more, enter
        your email below.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Si aún no estás listo para probarlo pero te gustaría obtener más
        información, ingresa tu correo electrónico a continuación.
      </>
    ),
  },
  msgModalSnooze1: {
    [LANGUAGES.EN]: (
      <>Thanks for snoozing! We’ll reach back out about Pivot later.</>
    ),
    [LANGUAGES.ES]: (
      <>
        ¡Gracias por compartir! Más adelante te brindaremos más información
        sobre Pivot.
      </>
    ),
  },
  msgModalSnooze2: {
    [LANGUAGES.EN]: (
      <>
        Feeling ready after all? Click through to register today. You can take
        things at your own pace, there’s never any pressure to quit with Pivot.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Finalmente, ¿ya te sientes listo? Haz clic para registrarte hoy. Con
        Pivot, puedes ir a tu propio ritmo, nunca sentirás la presión de dejar
        el hábito.
      </>
    ),
  },
  msgModalSnoozeOnboard1: {
    [LANGUAGES.EN]: (
      <>Thanks for snoozing! We’ll reach back out about Pivot later.</>
    ),
    [LANGUAGES.ES]: (
      <>
        ¡Gracias por compartir! Más adelante te brindaremos más información
        sobre Pivot.
      </>
    ),
  },
  msgModalSnoozeOnboard2: {
    [LANGUAGES.EN]: (
      <>
        Feeling ready after all? Download the Pivot App today. You can take
        things at your own pace, there’s never any pressure to quit with Pivot.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Finalmente, ¿ya te sientes listo? Descarga la aplicación Pivot hoy
        mismo. Con Pivot, puedes ir a tu propio ritmo, nunca sentirás la presión
        de dejar el hábito.
      </>
    ),
  },
  unSupportedModalQuitTitle: {
    [LANGUAGES.EN]: <>Have you recently quit?</>,
    [LANGUAGES.ES]: <>¿Has dejado de fumar recientemente?</>,
  },
  unSupportedModalQuitCopy: {
    [LANGUAGES.EN]: (
      <>
        Congratulations! Pivot can help you stay quit with a community and
        support. Please indicate which tobacco products you have used in the
        past so that we can personalize your experience.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        ¡Felicidades! Pivot puede ayudarte a seguir sin fumar con una comunidad
        y apoyo. Indica qué productos de tabaco ha consumido en el pasado para
        que podamos personalizar tu experiencia.
      </>
    ),
  },
  unSupportedModalNotUserTitle: {
    [LANGUAGES.EN]: <>Support for People Who Don’t Smoke</>,
    [LANGUAGES.ES]: <>Apoyo para las personas que no fuman</>,
  },
  unSupportedModalNotUserCopy: {
    [LANGUAGES.EN]: (
      <>
        Pivot is a program for all tobacco users, but this version of Pivot is
        only for people who smoke. If you only use smokeless forms of tobacco,
        please contact your employer or health plan about expanded options.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Pivot es un programa para todos los consumidores de tabaco, pero esta
        versión de Pivot es solo para personas que fuman. Si solo usas productos
        de tabaco sin humo, comunícate con tu empleador o plan de salud para
        conocer las opciones ampliadas.
      </>
    ),
  },
  unSupportedModalButton: {
    [LANGUAGES.EN]: <>Change my answer</>,
    [LANGUAGES.ES]: <>Cambiar mi respuesta</>,
  },
  vpAuthModalTitle: {
    [LANGUAGES.EN]: <>Log into Virgin Pulse</>,
    [LANGUAGES.ES]: <>Iniciar sesión en Virgin Pulse</>,
  },
  vpAuthModalCopy: {
    [LANGUAGES.EN]: (
      <>
        To gain free access to Pivot, please log in with your Virgin Pulse
        account.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Para obtener acceso gratuito a Pivot, inicia sesión con tu cuenta de
        Virgin Pulse.
      </>
    ),
  },
  vpAuthModalButton: {
    [LANGUAGES.EN]: <>Log into Virgin Pulse</>,
    [LANGUAGES.ES]: <>Iniciar sesión en Virgin Pulse</>,
  },
  vpExistsModalTitle: {
    [LANGUAGES.EN]: <>You already have an account</>,
    [LANGUAGES.ES]: <>Ya tienes una cuenta</>,
  },
  vpExistsModalCopy: {
    [LANGUAGES.EN]: (
      <>
        It appears you have already created a Pivot account. Please contact{' '}
        <EmailSupportLink /> for help retrieving your account or other
        questions.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Parece que ya has creado una cuenta Pivot. Comunícate con{' '}
        <EmailSupportLink /> para obtener ayuda para recuperar tu cuenta o si
        tienes otras preguntas.
      </>
    ),
  },
  vpTimeoutModalTitle: {
    [LANGUAGES.EN]: <>Session Expired</>,
    [LANGUAGES.ES]: <>Sesión expirada</>,
  },
  vpTimeoutModalCopy: {
    [LANGUAGES.EN]: (
      <>
        Sorry your session has expired. Please log back into your Virgin Pulse
        account to proceed.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Lo siento, tu sesión ha expirado. Vuelve a iniciar sesión en tu cuenta
        de Virgin Pulse para continuar.
      </>
    ),
  },
  vpTimeoutModalButton: {
    [LANGUAGES.EN]: <>Log into Virgin Pulse</>,
    [LANGUAGES.ES]: <>Iniciar sesión en Virgin Pulse</>,
  },
  vpCookiesModalTitle: {
    [LANGUAGES.EN]: <>Third Party Cookies Are Blocked</>,
    [LANGUAGES.ES]: <>Las cookies de terceros están bloqueadas</>,
  },
  vpCookiesModalCopy: {
    [LANGUAGES.EN]: (
      <>
        Please enable third party cookies to proceed. Go to your browser
        preferences and under Privacy and Security you can adjust your settings.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Habilita las cookies de terceros para continuar. Ve a las preferencias
        de tu navegador y en Privacidad y Seguridad podrás ajustar tu
        configuración.
      </>
    ),
  },
  vpCookiesModalButton: {
    [LANGUAGES.EN]: <>Cookies Enabled</>,
    [LANGUAGES.ES]: <>Cookies habilitadas</>,
  },
  lpHeader: {
    [LANGUAGES.EN]: <>With Pivot, quitting has never been easier</>,
    [LANGUAGES.ES]: <>Con Pivot, dejar el hábito jamás había sido tan fácil</>,
  },
  lpCopy1: {
    [LANGUAGES.EN]: (
      <>
        It’s like a Quit Kit in your pocket, and 100% free if you’re eligible.
        Pivot is built for smoking, vaping, and all other types of tobacco.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Es como un Kit para Dejarlo en tu bolsillo, y 100% gratuito si eres
        elegible. Pivot está creado para fumar, vapeo y todos los otros tipos de
        tabaco.
      </>
    ),
  },
  lpDisclaimer: {
    [LANGUAGES.EN]: (
      <>Pivot is built for smoking, vaping and all other tobacco types.</>
    ),
    [LANGUAGES.ES]: (
      <>
        Pivot está diseñado para fumadores, vapeadores y consumidores de todos
        los demás tipos de tabaco.
      </>
    ),
  },
  lpSimpleSignUpTitle: {
    [LANGUAGES.EN]: (
      <>
        Time for a fresh start.
        <br />
        Pivot first. Quit when you’re ready.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Es hora de comenzar de nuevo.
        <br />
        Primero, Pivot. Deja el hábito cuando estés listo.
      </>
    ),
  },
  lpSimpleSignUpCopy: {
    [LANGUAGES.EN]: (
      <>
        Pivot is a personalized program to help you quit smoking, on your own
        schedule and at your own pace.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Pivot es un programa personalizado para ayudarte a dejar de fumar, según
        tus horarios y a tu ritmo.
      </>
    ),
  },
  approvedTitle: {
    [LANGUAGES.EN]: <>Congrats, you’re in!</>,
    [LANGUAGES.ES]: <>¡Felicidades, ya estás participando!</>,
  },
  approvedCopy: {
    [LANGUAGES.EN]: (
      <>
        You can now upgrade your Pivot account. Complete the upgrade process in
        the Pivot app.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Ahora puedes actualizar tu cuenta Pivot. Completa el proceso de
        actualización en la aplicación Pivot.
      </>
    ),
  },
  coverageTitle: {
    [LANGUAGES.EN]: <>Pivot may be available as a free benefit</>,
    [LANGUAGES.ES]: (
      <>Pivot puede estar disponible como un beneficio gratuito</>
    ),
  },
  coverageDescription: {
    [LANGUAGES.EN]: (
      <>
        Please enter your <strong>access code</strong> or search for your{' '}
        <strong>employer</strong> or <strong>health plan</strong> to find out.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Introduce tu <strong>código de acceso</strong> o busca por{' '}
        <strong>empleador</strong> o <strong>plan de salud</strong> para
        descubrirlo.
      </>
    ),
  },
  accessCodeSearch: {
    [LANGUAGES.EN]: <>Access Code or Search for Employer or Health Plan</>,
    [LANGUAGES.ES]: (
      <>Código de acceso o búsqueda por empleador o plan de salud</>
    ),
  },
  coverageDisclaimer: {
    [LANGUAGES.EN]: (
      <>
        If you don’t see your employer or health plan, and think you should be
        covered, please contact <EmailSupportLink />
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Si no ves a tu empleador o plan de salud y crees que deberías tener
        cobertura, comunícate con <EmailSupportLink />
      </>
    ),
  },
  createAccountTitle1: {
    [LANGUAGES.EN]: <>Welcome to Pivot!</>,
    [LANGUAGES.ES]: <>¡Bienvenido a Pivot!</>,
  },
  createAccountTitle2: {
    [LANGUAGES.EN]: <>Step 2 of 3: Create Account</>,
    [LANGUAGES.ES]: <>Paso 2 de 3: Crear una cuenta</>,
  },
  createAccountTitle3: {
    [LANGUAGES.EN]: <>Step 1 of 3: Create Account</>,
    [LANGUAGES.ES]: <>Paso 1 de 3: Crear una cuenta</>,
  },
  createAccountDescriptionDTc: {
    [LANGUAGES.EN]: (
      <>Let’s get started! Create an account to register with Pivot.</>
    ),
    [LANGUAGES.ES]: <>¡Empecemos! Crea una cuenta para registrarte en Pivot.</>,
  },
  createAccountDescriptionCig: {
    [LANGUAGES.EN]: (
      <>
        Create your account, including shipping information, so we know where to
        ship your sensor. Your account isn’t complete until you confirm your
        registration.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Crea tu cuenta, e incluye la información de envío, para que sepamos
        dónde enviar tu sensor. Tu cuenta no estará completa hasta que confirmes
        tu registro.
      </>
    ),
  },
  createAccountDescriptionNonCig: {
    [LANGUAGES.EN]: <>To get started, just fill out the form below.</>,
    [LANGUAGES.ES]: (
      <>Para comenzar, simplemente completa el formulario a continuación.</>
    ),
  },
  createAccountPrivacy: {
    [LANGUAGES.EN]: (
      <>
        We take your privacy very seriously. Any information we collect is
        solely for providing you with the best experience possible.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Nos tomamos muy en serio tu privacidad. Cualquier información que
        recopilemos es únicamente para brindarte la mejor experiencia posible.
      </>
    ),
  },
  activationCode: {
    [LANGUAGES.EN]: <>Activation Code</>,
    [LANGUAGES.ES]: <>Código de activación</>,
  },
  accountDetails: {
    [LANGUAGES.EN]: <>Account Details</>,
    [LANGUAGES.ES]: <>Detalles de la cuenta</>,
  },
  personalizeTitle: {
    [LANGUAGES.EN]: <>Personalize Your Experience</>,
    [LANGUAGES.ES]: <>Personaliza tu experiencia</>,
  },
  personalizeCopy: {
    [LANGUAGES.EN]: (
      <>
        Tell us about yourself - we’ll use this information to personalize your
        experience in the Pivot app.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Cuéntanos sobre ti: utilizaremos esta información para personalizar tu
        experiencia en la aplicación Pivot.
      </>
    ),
  },
  yourEmail: {
    [LANGUAGES.EN]: <>Your email</>,
    [LANGUAGES.ES]: <>Tu correo electrónico</>,
  },
  retypeEmail: {
    [LANGUAGES.EN]: <>Re-type email</>,
    [LANGUAGES.ES]: <>Escribe nuevamente tu correo electrónico</>,
  },
  credsTitle: {
    [LANGUAGES.EN]: <>Let’s create your secure account.</>,
    [LANGUAGES.ES]: <>Creemos tu cuenta segura.</>,
  },
  creds: {
    [LANGUAGES.EN]: <>Credentials</>,
    [LANGUAGES.ES]: <>Credenciales</>,
  },
  emailAddress: {
    [LANGUAGES.EN]: <>Email address</>,
    [LANGUAGES.ES]: <>Dirección de correo electrónico</>,
  },
  createAccount: {
    [LANGUAGES.EN]: <>Create Account</>,
    [LANGUAGES.ES]: <>Crear una cuenta</>,
  },
  createAccountPersonalDetailsTitle1: {
    [LANGUAGES.EN]: <>Great, let’s create your account.</>,
    [LANGUAGES.ES]: <>Excelente, creemos tu cuenta.</>,
  },
  createAccountPersonalDetailsTitle2: {
    [LANGUAGES.EN]: <>Let’s create your account.</>,
    [LANGUAGES.ES]: <>Creemos tu cuenta.</>,
  },
  createAccountPersonalDetails: {
    [LANGUAGES.EN]: <>Personal details</>,
    [LANGUAGES.ES]: <>Datos personales</>,
  },
  shippingDetailsTitle1: {
    [LANGUAGES.EN]: <>You’re in! Where should we ship your sensor?</>,
    [LANGUAGES.ES]: <>¡Listo! ¿Dónde debemos enviar tu sensor?</>,
  },
  shippingDetailsTitle2: {
    [LANGUAGES.EN]: <>Where should we ship your sensor?</>,
    [LANGUAGES.ES]: <>¿Dónde debemos enviar tu sensor?</>,
  },
  diffShippingAddress: {
    [LANGUAGES.EN]: <>My shipping address is different</>,
    [LANGUAGES.ES]: <>Mi dirección de envío es diferente</>,
  },
  shippingDetailsTestimonial1: {
    [LANGUAGES.EN]: (
      <>
        The sensor changed my thinking about everything. Who doesn’t want to
        monitor a measure of their health almost instantly
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        El sensor cambió mi forma de pensar sobre todo. ¿Quién no quiere
        controlar su salud casi al instante?
      </>
    ),
  },
  shippingDetailsTestimonial2: {
    [LANGUAGES.EN]: (
      <>
        The sensor changed my thinking about everything. Who doesn’t want to
        monitor a measure of their health almost instantly?
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        El sensor cambió mi forma de pensar sobre todo. ¿Quién no quiere
        controlar su salud casi al instante?
      </>
    ),
  },
  pivotMember: {
    [LANGUAGES.EN]: <>Pivot member</>,
    [LANGUAGES.ES]: <>Miembro de Pivot</>,
  },
  downloadTitle: {
    [LANGUAGES.EN]: <>Almost done!</>,
    [LANGUAGES.ES]: <>¡Ya casi terminamos!</>,
  },
  downloadInstruction1: {
    [LANGUAGES.EN]: <>Download the Pivot app:</>,
    [LANGUAGES.ES]: <>Descarga la aplicación Pivot:</>,
  },
  downloadInstruction1Mobile: {
    [LANGUAGES.EN]: (
      <>
        Download the “Pivot Journey” app to start conquering your cravings with
        games and diving into lessons and community
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Descarga la app “Pivot Journey” para comenzar a dominar tus antojos con
        juegos, y explorar las lecciones y la comunidad
      </>
    ),
  },
  downloadInstruction2: {
    [LANGUAGES.EN]: (
      <>
        Search “<b>Pivot Journey</b>”
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Buscar <b>Recorrido con Pivot</b>
      </>
    ),
  },
  downloadInstruction3: {
    [LANGUAGES.EN]: <>or scan QR code</>,
    [LANGUAGES.ES]: <>o escanea código QR</>,
  },
  downloadInstruction4: {
    [LANGUAGES.EN]: <>Use your email:</>,
    [LANGUAGES.ES]: <>Utiliza tu correo electrónico:</>,
  },
  downloadInstruction5: {
    [LANGUAGES.EN]: <>to log into the Pivot app.</>,
    [LANGUAGES.ES]: <>para iniciar sesión en la aplicación Pivot.</>,
  },
  downloadQuestions: {
    [LANGUAGES.EN]: (
      <>
        Have questions? Contact Pivot support at:
        <br />
        <EmailSupportLink />
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        ¿Tienes preguntas? Ponte en contacto con el soporte de Pivot en:
        <br />
        <EmailSupportLink />
      </>
    ),
  },
  eligApprovedTitle1: {
    [LANGUAGES.EN]: <>Great news</>,
    [LANGUAGES.ES]: <>Muy buenas noticias</>,
  },
  eligApprovedTitle2: {
    [LANGUAGES.EN]: <>Pivot is free for you.</>,
    [LANGUAGES.ES]: <>Pivot es gratis para ti.</>,
  },
  vpEligApprovedTitle: {
    [LANGUAGES.EN]: <>Pivot is free for you.</>,
    [LANGUAGES.ES]: <>Pivot es gratis para ti.</>,
  },
  cdphpBcncEligApprovedTitle: {
    [LANGUAGES.EN]: (
      <>
        Great news!
        <br />
        Pivot is available at no charge as part of your plan.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        ¡Muy buenas noticias!
        <br />
        Pivot está disponible sin cargo como parte de tu plan.
      </>
    ),
  },
  eligApprovedCopyCig: {
    [LANGUAGES.EN]: <>What’s next?</>,
    [LANGUAGES.ES]: <>¿Qué sigue?</>,
  },
  eligApprovedCopyNonCig: {
    [LANGUAGES.EN]: <>With Pivot, you’ll:</>,
    [LANGUAGES.ES]: <>Con Pivot, tu:</>,
  },
  eligApprovedCigTitle1: {
    [LANGUAGES.EN]: <>Claim your free sensor</>,
    [LANGUAGES.ES]: <>Reclamas tu sensor gratis</>,
  },
  eligApprovedCigCopy1: {
    [LANGUAGES.EN]: (
      <>
        Our FDA-approved sensor enables you to watch your carbon monoxide (CO)
        levels drop when you skip or delay a cigarette.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Nuestro sensor aprobado por FDA te permite ver como descienden tus
        niveles de monóxido de carbono (CO) cuando saltas o retrasas un
        cigarrillo.
      </>
    ),
  },
  eligApprovedCigTitle2: {
    [LANGUAGES.EN]: <>Start crushing cravings</>,
    [LANGUAGES.ES]: <>Comienzas a dominar los antojos</>,
  },
  eligApprovedCigCopy2: {
    [LANGUAGES.EN]: (
      <>
        While you wait for you sensor, play games to crush cravings, meet with
        your tobacco-trained coach, and order free NRT supplies to your door.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Mientras esperas que llegue tu sensor, juega juegos para dominar
        antojos, reúnete con tu entrenador capacitado para tabaco y pide
        suministros NRT gratis a tu puerta.
      </>
    ),
  },
  eligApprovedNonCigTitle1: {
    [LANGUAGES.EN]: <>Identify patterns</>,
    [LANGUAGES.ES]: <>Identificas patrones</>,
  },
  eligApprovedNonCigCopy1: {
    [LANGUAGES.EN]: (
      <>
        Our personalized lessons help you identify and break smoking patterns
        and triggers.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Nuestras lecciones personalizadas te ayudan a identificar y romper los
        patrones y desencadentantes de fumar.
      </>
    ),
  },
  eligApprovedNonCigTitle2: {
    [LANGUAGES.EN]: <>Get expert coaching</>,
    [LANGUAGES.ES]: <>Obtienes entrenamiento experto</>,
  },
  eligApprovedNonCigCopy2: {
    [LANGUAGES.EN]: (
      <>
        Receive guidance from tobacco cessation experts every step of the way.
      </>
    ),
    [LANGUAGES.ES]: (
      <>Tienes la guía de expertos en dejar de fumar en cada paso del camino.</>
    ),
  },
  eligApprovedNonCigTitle3: {
    [LANGUAGES.EN]: <>Conquer cravings</>,
    [LANGUAGES.ES]: <>Conquistas los antojos</>,
  },
  eligApprovedNonCigCopy3: {
    [LANGUAGES.EN]: (
      <>
        Conquer cravings with tools and resources designed to help you stay
        motivated throughout your journey.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Conquistas los antojos con herramientas y recursos diseñados para
        ayudarte a seguir motivado/a en todo el recorrido.
      </>
    ),
  },
  eligIntroTitle: {
    [LANGUAGES.EN]: <>Pivot may be available to you as a FREE benefit.</>,
    [LANGUAGES.ES]: (
      <>Pivot puede estar disponible para ti como un beneficio GRATUITO.</>
    ),
  },
  cdphpBcncEligIntroTitle: {
    [LANGUAGES.EN]: (
      <>Pivot may be available to you at NO CHARGE as part of your plan.</>
    ),
    [LANGUAGES.ES]: (
      <>Pivot puede estar disponible para ti SIN CARGO como parte de tu plan.</>
    ),
  },
  eligIntroCopy: {
    [LANGUAGES.EN]: (
      <>Answering a few questions will help us check your eligibility.</>
    ),
    [LANGUAGES.ES]: (
      <>Responder algunas preguntas nos ayudará a verificar tu elegibilidad.</>
    ),
  },
  bcncEligIntroDisclaimer: {
    [LANGUAGES.EN]: (
      <>Blue Cross and Blue Shield North Carolina covers Pivot for smoking.</>
    ),
    [LANGUAGES.ES]: (
      <>
        Blue Cross and Blue Shield North Carolina cubre Pivot for smoking (Pivot
        para fumadores).
      </>
    ),
  },
  eligIntroDisclaimer: {
    [LANGUAGES.EN]: (
      <>Pivot is built for smoking, vaping, and all other types of tobacco.</>
    ),
    [LANGUAGES.ES]: (
      <>
        Pivot se diseñó para fumadores, vapeadores y consumidores de todos los
        demás tipos de tabaco.
      </>
    ),
  },
  primary: { [LANGUAGES.EN]: <>Primary</>, [LANGUAGES.ES]: <>Titular</> },
  dependent: {
    [LANGUAGES.EN]: <>Dependent</>,
    [LANGUAGES.ES]: <>Dependiente</>,
  },
  cdphpEligMsg1: {
    [LANGUAGES.EN]: (
      <>
        We’re sorry, it looks like your benefits do not include the Pivot
        program, or you may have entered your information incorrectly. Please
        try resubmitting.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Lo sentimos, parece que tus beneficios no incluyen el programa Pivot o
        es posible que hayas ingresado tu información incorrectamente. Intenta
        volver a enviarla.
      </>
    ),
  },
  cdphpEligMsg2: {
    [LANGUAGES.EN]: <>If you are not eligible for Pivot, there are</>,
    [LANGUAGES.ES]: <>Si no eres elegible para Pivot, hay</>,
  },
  cdphpEligMsg3: {
    [LANGUAGES.EN]: <>other no-cost programs to help you quit</>,
    [LANGUAGES.ES]: (
      <>otros programas gratuitos para ayudarte a dejar de fumar</>
    ),
  },
  cdphpEligMsg4: {
    [LANGUAGES.EN]: <>offered through CDPHP.</>,
    [LANGUAGES.ES]: <>ofrecidos a través de CDPHP.</>,
  },
  iAm: { [LANGUAGES.EN]: <>I am</>, [LANGUAGES.ES]: <>Soy</> },
  select: { [LANGUAGES.EN]: <>Select</>, [LANGUAGES.ES]: <>Seleccionar</> },
  cdphpTooltip: {
    [LANGUAGES.EN]: (
      <>
        You must enter an 11-character member ID, which includes your 9-digit
        ID# as well as the 2 digits preceding your name on your CDPHP ID card
        (no spaces, no hyphens).
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Debes ingresar una identificación de miembro de 11 caracteres, que
        incluya tu número de identificación de 9 dígitos, así como los 2 dígitos
        que preceden a tu nombre en tu tarjeta de identificación de CDPHP (sin
        espacios ni guiones).
      </>
    ),
  },
  hpTooltip: {
    [LANGUAGES.EN]: <>Enter your 8-digit member ID.</>,
    [LANGUAGES.ES]: <>Ingresa tu identificación de miembro de 8 dígitos.</>,
  },
  primaryHolderInfo: {
    [LANGUAGES.EN]: <>Primary policy holder info</>,
    [LANGUAGES.ES]: <>Información del titular de la póliza principal</>,
  },
  primaryEligTitle: {
    [LANGUAGES.EN]: <>Let’s check your coverage.</>,
    [LANGUAGES.ES]: <>Chequemos tu cobertura.</>,
  },
  primaryEligCopy: {
    [LANGUAGES.EN]: (
      <>
        We take privacy very seriously – your information is secure and will not
        be shared.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Nos tomamos la privacidad muy en serio – tu información está segura, y
        no se compartirá.
      </>
    ),
  },
  vpPrimaryEligTitle: {
    [LANGUAGES.EN]: <>Let’s get your account set up.</>,
    [LANGUAGES.ES]: <>Configuremos tu cuenta.</>,
  },
  eligReviewTitle: {
    [LANGUAGES.EN]: <>Coverage Check</>,
    [LANGUAGES.ES]: <>Verificación de cobertura</>,
  },
  eligReviewCopy: {
    [LANGUAGES.EN]: (
      <>
        Thanks for your interest in Pivot!
        <br />
        <br />
        We’re reviewing the information you gave us to verify your eligibility.
        <br />
        <br />
        We’ll be in touch within two business days at your email
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        ¡Gracias por tu interés en Pivot!
        <br />
        <br />
        Estamos revisando la información que nos proporcionaste para verificar
        tu elegibilidad.
        <br />
        <br />
        Nos comunicaremos contigo dentro de dos días hábiles a tu correo
        electrónico
      </>
    ),
  },
  eligReviewTitle2: {
    [LANGUAGES.EN]: <>Learn more about Pivot</>,
    [LANGUAGES.ES]: <>Más información sobre Pivot</>,
  },
  eligReviewCopy2: {
    [LANGUAGES.EN]: <>In the meantime, learn more about Pivot at</>,
    [LANGUAGES.ES]: <>Mientras tanto, obtén más información sobre Pivot en</>,
  },
  secondaryEligTitle: {
    [LANGUAGES.EN]: <>Coverage Check</>,
    [LANGUAGES.ES]: <>Verificación de cobertura</>,
  },
  secondaryEligCopy: {
    [LANGUAGES.EN]: (
      <>Looks like we might need more information to confirm if you qualify</>
    ),
    [LANGUAGES.ES]: (
      <>
        Parece que podríamos necesitar más información para confirmar si
        calificas
      </>
    ),
  },
  whereDoIFindThis: {
    [LANGUAGES.EN]: <>Where do I find this?</>,
    [LANGUAGES.ES]: <>¿Dónde la encuentro?</>,
  },
  needHelp: {
    [LANGUAGES.EN]: <>Need help?</>,
    [LANGUAGES.ES]: <>¿Necesitas ayuda?</>,
  },
  emailOrCallUS: {
    [LANGUAGES.EN]: (
      <>
        Please email <EmailSupportLink /> or call us at
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Envía un correo electrónico a <EmailSupportLink /> o llámanos al
      </>
    ),
  },
  workingHours: {
    [LANGUAGES.EN]: <>between 9 AM and 5 PM PST.</>,
    [LANGUAGES.ES]: <>entre las 9 a. m. y las 5 p. m., hora del Pacífico.</>,
  },
  chatNow: {
    [LANGUAGES.EN]: <>Chat Now</>,
    [LANGUAGES.ES]: <>Chatear ahora</>,
  },
  eligTakenTitle: {
    [LANGUAGES.EN]: <>Looks like something went wrong!</>,
    [LANGUAGES.ES]: <>¡Parece que algo salió mal!</>,
  },
  eligTakenCopy1: {
    [LANGUAGES.EN]: (
      <>
        It appears your slot was already taken - either by someone else or a
        duplicate account.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Parece que tu plaza ya fue ocupada, ya sea por otra persona o por una
        cuenta duplicada.
      </>
    ),
  },
  eligTakenCopy2: {
    [LANGUAGES.EN]: (
      <>
        If you accidentally created a second account, please use the first
        account. If not, please contact <EmailSupportLink /> and they will help
        you resolve the problem.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Si accidentalmente creaste una segunda cuenta, utiliza la primera. Si
        no, por favor contacta a<EmailSupportLink />y ellos te ayudarán a
        resolver el problema.
      </>
    ),
  },
  noCoverageTitle: {
    [LANGUAGES.EN]: (
      <>
        Thanks for your interest! Pivot is not a covered benefit for you, yet.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        ¡Gracias por tu interés! Pivot aún no es un beneficio incluido en tu
        cobertura.
      </>
    ),
  },
  noCoverageCopy: {
    [LANGUAGES.EN]: (
      <>
        We believe that you shouldn’t have to pay for programs like Pivot, and
        we’d love to let you know if your coverage changes.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Creemos que no deberías tener que pagar por programas como Pivot y nos
        encantaría informarte si tu cobertura cambia.
      </>
    ),
  },
  regCompleteTitle: {
    [LANGUAGES.EN]: <>Congratulations, your account is verified!</>,
    [LANGUAGES.ES]: <>Felicidades, ¡tu cuenta ha sido verificada!</>,
  },
  regCompleteCopyOpenTheApp: {
    [LANGUAGES.EN]: <>Open the Pivot app and complete your account setup.</>,
    [LANGUAGES.ES]: (
      <>Abre la aplicación Pivot y completa la configuración de tu cuenta.</>
    ),
  },
  regCompleteCopyLastStep: {
    [LANGUAGES.EN]: <>Last step: Download your Pivot Journey app below</>,
    [LANGUAGES.ES]: (
      <>Último paso: Descarga tu aplicación Pivot Journey a continuación</>
    ),
  },
  regCompleteCopySearch: {
    [LANGUAGES.EN]: (
      <>or search for “Pivot Journey” in the App store on your smartphone.</>
    ),
    [LANGUAGES.ES]: (
      <>Buscar 'Recorrido con Pivot' en la App Store de tu teléfono</>
    ),
  },
  sendLinkViaTxt: {
    [LANGUAGES.EN]: <>Send a link to the app via text</>,
    [LANGUAGES.ES]: <>Enviar un enlace a la aplicación por mensaje de texto</>,
  },
  gotoPivotApp: {
    [LANGUAGES.EN]: <>Go to the Pivot app</>,
    [LANGUAGES.ES]: <>Ir a la aplicación Pivot</>,
  },
  regCompleteQuestion: {
    [LANGUAGES.EN]: <>Questions? Contact support at</>,
    [LANGUAGES.ES]: <>¿Preguntas? Ponerse en contacto con Soporte</>,
  },
  regErrorTitle: {
    [LANGUAGES.EN]: <>Looks like something went wrong!</>,
    [LANGUAGES.ES]: <>¡Parece que algo salió mal!</>,
  },
  regErrorCopy1: {
    [LANGUAGES.EN]: <>An unexpected error has occurred.</>,
    [LANGUAGES.ES]: <>Ha ocurrido un error inesperado.</>,
  },
  regErrorCopy2: {
    [LANGUAGES.EN]: (
      <>
        Please contact suppoer@pivot.co for assistance with your registration.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Comunícate con <EmailSupportLink /> para obtener ayuda con tu registro.
      </>
    ),
  },
  thankYouTitle: {
    [LANGUAGES.EN]: <>Thank you!</>,
    [LANGUAGES.ES]: <>¡Gracias!</>,
  },
  thankYouCopy1: {
    [LANGUAGES.EN]: (
      <>
        Looks like Pivot is not covered by your employer or health plan at this
        time.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Parece que, en este momento, Pivot no es un beneficio incluido en la
        cobertura de tu empleador ni en tu plan de salud.
      </>
    ),
  },
  thankYouCopy2: {
    [LANGUAGES.EN]: <>You can learn more about Pivot and buy it here</>,
    [LANGUAGES.ES]: <>Puedes conocer más sobre Pivot y comprarlo aquí</>,
  },
  cig: { [LANGUAGES.EN]: <>Cigarettes</>, [LANGUAGES.ES]: <>Cigarrillos</> },
  vape: {
    [LANGUAGES.EN]: <>Vape / E-cigs</>,
    [LANGUAGES.ES]: <>Vaporizador / cigarrillos electrónicos</>,
  },
  chew: {
    [LANGUAGES.EN]: <>Chewing tobacco / snuff</>,
    [LANGUAGES.ES]: <>Tabaco masticable / rapé</>,
  },
  otherNicTypes: {
    [LANGUAGES.EN]: <>Other (pipe / hookah / cigars / cigarillos)</>,
    [LANGUAGES.ES]: <>Otros (pipa/pipa de agua/puros/cigarritos)</>,
  },
  noUse: {
    [LANGUAGES.EN]: <>I don’t use any tobacco products</>,
    [LANGUAGES.ES]: <>No uso ningún producto de tabaco</>,
  },
  usageTitle: {
    [LANGUAGES.EN]: <>Which type(s) of tobacco would you like help with?</>,
    [LANGUAGES.ES]: <>¿Para qué tipo(s) de tabaco te gustaría recibir ayuda?</>,
  },
  usageCopy: {
    [LANGUAGES.EN]: (
      <>
        You can choose more than one product. If you recently quit, select which
        product you most recently used.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Puedes escoger más de un producto. Si lo dejaste recientemente,
        selecciona qué producto usaste más recientemente.
      </>
    ),
  },
  sensorNote: {
    [LANGUAGES.EN]: (
      <>
        Note: The tobacco type you selected is not eligible for the SmartSensor
        - the device only provides useful data for people who smoke cigarettes.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Nota: El tipo de tabaco que seleccionaste no es elegible para el
        SmartSensor: el dispositivo solamente proporciona datos útiles para
        quien fuma cigarros.
      </>
    ),
  },
  sensorNoteShort: {
    [LANGUAGES.EN]: <>This type is not eligible for the SmartSensor</>,
    [LANGUAGES.ES]: <>Este tipo no es elegible para el SmartSensor</>,
  },
  INCORRECT_USER_OR_PASS: {
    [LANGUAGES.EN]: <>Incorrect username or password.</>,
    [LANGUAGES.ES]: <>Nombre de usuario o contraseña incorrecta.</>,
  },
  ATTEMPT_LIMIT_EXCEEDED: {
    [LANGUAGES.EN]: <>Attempt limit exceeded, please try after some time.</>,
    [LANGUAGES.ES]: (
      <>Se superó el límite de intentos. Inténtalo dentro de unos minutos.</>
    ),
  },
  USER_DOESNT_EXIST: {
    [LANGUAGES.EN]: <>User does not exist.</>,
    [LANGUAGES.ES]: <>El usuario no existe.</>,
  },
  CANNOT_RESET_PASSWORD: {
    [LANGUAGES.EN]: (
      <>
        Cannot reset password for the user as there is no registered/verified
        email or phone_number
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        No se puede restablecer la contraseña del usuario porque no hay un
        correo electrónico o número de teléfono registrado/verificado
      </>
    ),
  },
  EMAIL_SENT: {
    [LANGUAGES.EN]: <>Email Sent</>,
    [LANGUAGES.ES]: <>Correo electrónico enviado</>,
  },
  LOADING: { [LANGUAGES.EN]: <>Loading</>, [LANGUAGES.ES]: <>Cargando</> },
  CONTINUE: { [LANGUAGES.EN]: <>Continue</>, [LANGUAGES.ES]: <>Continuar</> },
  PASSWORD: { [LANGUAGES.EN]: <>Password</>, [LANGUAGES.ES]: <>Contraseña</> },
  RETYPE_PASSWORD: {
    [LANGUAGES.EN]: <>Re-type password</>,
    [LANGUAGES.ES]: <>Vuelve a escribir la contraseña</>,
  },
  AGE_RANGE_PROBLEM_MINOR: {
    [LANGUAGES.EN]: (
      <>
        Sorry, this registration of Pivot only allows people from ages{' '}
        {MIN_ALLOWED_MINOR_AGE} through {MAX_ALLOWED_MINOR_AGE} to register.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Lo sentimos, este registro de Pivot solo permite que se registren
        personas con edades comprendidas entre MIN_ALLOWED_MINOR_AGE y
        MAX_ALLOWED_MINOR_AGE.
      </>
    ),
  },
  AGE_RANGE_PROBLEM_ADULT: {
    [LANGUAGES.EN]: (
      <>
        Sorry, this registration of Pivot only allows people from ages{' '}
        {MIN_ALLOWED_ADULT_AGE} through {MAX_ALLOWED_ADULT_AGE} to register.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Lo sentimos, este registro de Pivot solo permite que se registren
        personas de edades comprendidas entre MIN_ALLOWED_ADULT_AGE y
        MAX_ALLOWED_ADULT_AGE.
      </>
    ),
  },
  EMAIL_EXISTS_IN_SYSTEM: {
    [LANGUAGES.EN]: (
      <>
        Email is already in system.{' '}
        <a href="./login" className="green">
          Log in
        </a>
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        El correo electrónico ya está en el sistema.{' '}
        <a href="PAGES.LOGIN.url" className="green">
          Iniciar sesión
        </a>
      </>
    ),
  },
  EMAIL_NOT_VALID: {
    [LANGUAGES.EN]: <>Email is not a valid address</>,
    [LANGUAGES.ES]: <>El correo electrónico no es una dirección válida</>,
  },
  EMAILS_SHOULD_MATCH: {
    [LANGUAGES.EN]: <>Emails must match</>,
    [LANGUAGES.ES]: <>Los correos electrónicos deben coincidir</>,
  },
  ACCESS_CODE_NOT_RECOGNIZED: {
    [LANGUAGES.EN]: (
      <>
        Oops! We don’t recognize that one. Please contact us at{' '}
        <EmailSupportLink /> to make sure you have the correct code.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        ¡Ups! No lo reconocemos. Escribe a{' '}
        <a href="mailto:<EmailSupportLink />">support@pivot.co</a> para
        asegurarte de tener el código correcto.
      </>
    ),
  },
  ACCESS_CODE_FULL: {
    [LANGUAGES.EN]: (
      <>
        This access code is no longer active. Please contact us at{' '}
        <EmailSupportLink /> to make sure you have the correct code.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Este código de acceso ya no está activo. Escribe a{' '}
        <a href="mailto:<EmailSupportLink />">support@pivot.co</a> para
        asegurarte de tener el código correcto.
      </>
    ),
  },
  ACCESS_CODE_EXPIRED: {
    [LANGUAGES.EN]: (
      <>
        This access code is no longer active. Please contact us at{' '}
        <EmailSupportLink /> to make sure you have the correct code.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Este código de acceso ya no está activo. Escribe a{' '}
        <a href="mailto:<EmailSupportLink />">support@pivot.co</a> para
        asegurarte de tener el código correcto.
      </>
    ),
  },
  ENTER_VALID_ACCESS_CODE: {
    [LANGUAGES.EN]: <>Invalid Access Code</>,
    [LANGUAGES.ES]: <>Código de acceso no válido</>,
  },
  ENTER_VALID_ADDRESS: {
    [LANGUAGES.EN]: <>Invalid address</>,
    [LANGUAGES.ES]: <>Dirección no válida</>,
  },
  ENTER_VALID_CITY: {
    [LANGUAGES.EN]: <>Invalid city</>,
    [LANGUAGES.ES]: <>Ciudad no válida</>,
  },
  ENTER_VALID_CODE: {
    [LANGUAGES.EN]: <>Invalid code</>,
    [LANGUAGES.ES]: <>Código no válido</>,
  },
  ENTER_VALID_DATE: {
    [LANGUAGES.EN]: <>Invalid date</>,
    [LANGUAGES.ES]: <>Fecha no válida</>,
  },
  ENTER_VALID_EMAIL: {
    [LANGUAGES.EN]: <>Invalid email</>,
    [LANGUAGES.ES]: <>Correo electrónico no válido</>,
  },
  ENTER_VALID_PASSWORD: {
    [LANGUAGES.EN]: <>Invalid password</>,
    [LANGUAGES.ES]: <>Contraseña no válida</>,
  },
  ENTER_VALID_PHONE: {
    [LANGUAGES.EN]: <>Invalid phone number</>,
    [LANGUAGES.ES]: <>Número de teléfono no válido</>,
  },
  ENTER_VALID_ZIP: {
    [LANGUAGES.EN]: <>Invalid zip code</>,
    [LANGUAGES.ES]: <>Código postal no válido</>,
  },
  FIRST_NAME_EMPTY: {
    [LANGUAGES.EN]: <>First Name cannot be empty</>,
    [LANGUAGES.ES]: <>El nombre no puede estar vacío</>,
  },
  FIRST_NAME_TOO_LONG: {
    [LANGUAGES.EN]: <>19 character limit</>,
    [LANGUAGES.ES]: <>límite de 19 caracteres</>,
  },
  INCORRECT_USER_PASS: {
    [LANGUAGES.EN]: <>Incorrect username or password</>,
    [LANGUAGES.ES]: <>Nombre de usuario o contraseña incorrectos</>,
  },
  INCORRECT_VERIFICATION_CODE: {
    [LANGUAGES.EN]: <>The verification code you entered is incorrect</>,
    [LANGUAGES.ES]: <>El código de verificación que ingresaste es incorrecto</>,
  },
  INVALID_INPUT: {
    [LANGUAGES.EN]: <>Input is not valid</>,
    [LANGUAGES.ES]: <>La entrada no es válida</>,
  },
  INVALID_INPUT_STRING: {
    [LANGUAGES.EN]: <>Sorry, only letters (a-z) and spaces are allowed</>,
    [LANGUAGES.ES]: <>Lo sentimos, solo se permiten letras (a-z) y espacios</>,
  },
  LAST_NAME_EMPTY: {
    [LANGUAGES.EN]: <>Last Name cannot be empty</>,
    [LANGUAGES.ES]: <>El apellido no puede estar vacío</>,
  },
  LAST_NAME_TOO_LONG: {
    [LANGUAGES.EN]: <>20 character limit</>,
    [LANGUAGES.ES]: <>límite de 20 caracteres</>,
  },
  LIMITS_EXCEEDED: {
    [LANGUAGES.EN]: <>Attempt limit exceeded, please try after some time</>,
    [LANGUAGES.ES]: (
      <>Se superó el límite de intentos. Inténtalo dentro de unos minutos</>
    ),
  },
  LAMBDA_ERROR_DURING_SIGNUP: {
    [LANGUAGES.EN]: <>Lambda error during signup</>,
    [LANGUAGES.ES]: <>Error de Lambda durante el registro</>,
  },
  MISSING: {
    [LANGUAGES.EN]: <>Please complete this field</>,
    [LANGUAGES.ES]: <>Complete este campo</>,
  },
  PASS_NOT_VALID: {
    [LANGUAGES.EN]: <>Password is not valid</>,
    [LANGUAGES.ES]: <>La contraseña no es válida</>,
  },
  PASS_1_NOT_VALID: {
    [LANGUAGES.EN]: <>Password 1 is not valid</>,
    [LANGUAGES.ES]: <>La contraseña 1 no es válida</>,
  },
  PASS_2_NOT_VALID: {
    [LANGUAGES.EN]: <>Password 2 is not valid</>,
    [LANGUAGES.ES]: <>La contraseña 2 no es válida</>,
  },
  PASS_CHANGE_LIMITS_EXCEEDED: {
    [LANGUAGES.EN]: (
      <>
        You’ve tried to change your password too many times recently - please
        try again later
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Has intentado cambiar tu contraseña demasiadas veces recientemente.
        Inténtalo de nuevo más tarde
      </>
    ),
  },
  PASSWORDS_SHOULD_MATCH: {
    [LANGUAGES.EN]: <>Passwords must match</>,
    [LANGUAGES.ES]: <>Las contraseñas deben coincidir</>,
  },
  PHONE_NUMBER_EMPTY: {
    [LANGUAGES.EN]: <>Phone number cannot be empty</>,
    [LANGUAGES.ES]: <>El número de teléfono no puede estar vacío</>,
  },
  PHONE_NUMBER_LENGTH: {
    [LANGUAGES.EN]: <>Phone number must be at least 10 characters</>,
    [LANGUAGES.ES]: (
      <>El número de teléfono debe tener al menos 10 caracteres</>
    ),
  },
  SELECT: {
    [LANGUAGES.EN]: <>Please select one</>,
    [LANGUAGES.ES]: <>Por favor, selecciona uno</>,
  },
  SERVER_ERROR: {
    [LANGUAGES.EN]: <>Server Error</>,
    [LANGUAGES.ES]: <>Error del servidor</>,
  },
  SESSION_TIMEOUT: {
    [LANGUAGES.EN]: <>Your session timed out - please log in and try again</>,
    [LANGUAGES.ES]: (
      <>
        Se agotó el tiempo de espera de tu sesión; inicia sesión e inténtalo de
        nuevo
      </>
    ),
  },
  SOMETHING_WRONG: {
    [LANGUAGES.EN]: <>Oops, looks like there was an error</>,
    [LANGUAGES.ES]: <>Vaya, parece que hubo un error</>,
  },
  SOMETHING_WRONG_CONTACT_SUPPORT: {
    [LANGUAGES.EN]: (
      <>
        Oops, looks like there was an error. Please reach out to{' '}
        <a href="mailto:<EmailSupportLink />">support@pivot.co</a> for help
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Vaya, parece que hubo un error. Comunícate con{' '}
        <a href="mailto:<EmailSupportLink />">support@pivot.co</a> para obtener
        ayuda
      </>
    ),
  },
  USER_NAME_EXISTS_GOTO_LOGIN: {
    [LANGUAGES.EN]: (
      <>
        Looks like you already have an account. Please{' '}
        <a href="/login">click here</a> to login or contact us at{' '}
        <a href="mailto:<EmailSupportLink />">support@pivot.co</a>
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Parece que ya tienes una cuenta <a href="/login">Haz clic aquí</a> para
        iniciar sesión o escríbenos a{' '}
        <a href="mailto:<EmailSupportLink />">support@pivot.co</a>
      </>
    ),
  },
  URL_PARSE_FAILED: {
    [LANGUAGES.EN]: <>Parsing return url failed.</>,
    [LANGUAGES.ES]: <>Error al analizar la URL de retorno.</>,
  },
  USER_DISABLED: {
    [LANGUAGES.EN]: (
      <>
        Your account has been disabled. Please contact your access code provider
        for more information.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Tu cuenta ha sido deshabilitada. Comunícate con tu proveedor del código
        de acceso para obtener más información.
      </>
    ),
  },
  USER_NOT_INITIALIZED: {
    [LANGUAGES.EN]: (
      <>
        Looks like you already have an account. Please log in to the mobile app
        to complete registration.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Parece que ya tienes una cuenta Inicia sesión en la aplicación móvil
        para completar el registro.
      </>
    ),
  },
  USER_NOT_CONFIRMED: {
    [LANGUAGES.EN]: (
      <>
        Looks like you’ve already signed up, but you need to verify your email.
        We’ve just emailed you a new link.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Parece que ya te has registrado, pero necesitas verificar tu correo
        electrónico. Acabamos de enviarte un nuevo enlace por correo
        electrónico.
      </>
    ),
  },
  USER_NOT_FOUND: {
    [LANGUAGES.EN]: <>An account with this email address does not exist.</>,
    [LANGUAGES.ES]: (
      <>No existe una cuenta con esta dirección de correo electrónico.</>
    ),
  },
  USER_CONFIRMED: {
    [LANGUAGES.EN]: <>User is already confirmed.</>,
    [LANGUAGES.ES]: <>El usuario ya está confirmado.</>,
  },
  PAYMENT_FAIL: {
    [LANGUAGES.EN]: (
      <>
        Processing payment failed, Please reach out to <EmailSupportLink /> for
        help
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Error al procesar el pago. Comunícate con <EmailSupportLink /> para
        obtener ayuda
      </>
    ),
  },
  EMAIL_SENT_OK: {
    [LANGUAGES.EN]: <>Message successfully sent, check your email!</>,
    [LANGUAGES.ES]: (
      <>Mensaje enviado correctamente. Revisa tu correo electrónico.</>
    ),
  },
  PASS_RESET_EMAIL_SENT: {
    [LANGUAGES.EN]: (
      <>An email was sent - you’ll now be redirected to reset your password</>
    ),
    [LANGUAGES.ES]: (
      <>
        Se envió un correo electrónico; ahora serás redirigido para restablecer
        tu contraseña
      </>
    ),
  },
  PASS_UPDATE_OK: {
    [LANGUAGES.EN]: <>Password was updated successfully</>,
    [LANGUAGES.ES]: <>La contraseña se actualizó correctamente</>,
  },
  SMS_SENT: {
    [LANGUAGES.EN]: <>SMS sent!</>,
    [LANGUAGES.ES]: <>¡SMS enviado!</>,
  },
  TEL_UPDATE_OK: {
    [LANGUAGES.EN]: <>Telephone was updated successfully</>,
    [LANGUAGES.ES]: <>El teléfono se actualizó correctamente</>,
  },
  THANKS: { [LANGUAGES.EN]: <>Thank you!</>, [LANGUAGES.ES]: <>¡Gracias!</> },
  USER_RECONFIRM: {
    [LANGUAGES.EN]: (
      <>
        We’ve just sent you a new link please check your email and verify your
        account
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Acabamos de enviarte un nuevo enlace. Revisa tu correo electrónico y
        verifica tu cuenta
      </>
    ),
  },
  SUBMIT_BUTTON_DISABLED: {
    [LANGUAGES.EN]: (
      <>Submit button has been disabled until all fields are completed</>
    ),
    [LANGUAGES.ES]: (
      <>
        El botón Enviar ha sido deshabilitado hasta que se completen todos los
        campos
      </>
    ),
  },
  PAGE_START_TITLE: {
    [LANGUAGES.EN]: <>Pivot Tobacco Cessation Program</>,
    [LANGUAGES.ES]: <>Programa Pivot de Cesación del Tabaquismo</>,
  },
  PAGE_START_DESCRIPTION: {
    [LANGUAGES.EN]: <>Quit when you are ready. Register today!</>,
    [LANGUAGES.ES]: <>Deja el hábito cuando estés listo. ¡Regístrate hoy!</>,
  },
  PAGE_JOIN_TITLE: {
    [LANGUAGES.EN]: <>Pivot Tobacco Cessation Program</>,
    [LANGUAGES.ES]: <>Programa Pivot de Cesación del Tabaquismo</>,
  },
  PAGE_JOIN_DESCRIPTION: {
    [LANGUAGES.EN]: <>Quit when you are ready. Register today!</>,
    [LANGUAGES.ES]: <>Deja el hábito cuando estés listo. ¡Regístrate hoy!</>,
  },
  PAGE_AMAZON_TITLE: {
    [LANGUAGES.EN]: <>Get Started - Pivot Registration</>,
    [LANGUAGES.ES]: <>Empezar: Regístrate en Pivot</>,
  },
  PAGE_AMAZON_DESCRIPTION: {
    [LANGUAGES.EN]: <>Quit when you are ready. Register today!</>,
    [LANGUAGES.ES]: <>Deja el hábito cuando estés listo. ¡Regístrate hoy!</>,
  },
  PAGE_PARTNER_LANDING_TITLE: {
    [LANGUAGES.EN]: <>Pivot Tobacco Cessation Program - Register today</>,
    [LANGUAGES.ES]: (
      <>Programa Pivot de Cesación del Tabaquismo: regístrate hoy</>
    ),
  },
  PAGE_PARTNER_LANDING_DESCRIPTION: {
    [LANGUAGES.EN]: (
      <>Pivot partnered to provide you with free access to Pivot!</>
    ),
    [LANGUAGES.ES]: (
      <>¡Pivot se ha asociado para brindarte acceso gratuito a Pivot!</>
    ),
  },
  PAGE_PARTNER_LANDING_STREAMLINED_TITLE: {
    [LANGUAGES.EN]: <>Pivot Tobacco Cessation Program - Register today</>,
    [LANGUAGES.ES]: (
      <>Programa Pivot de Cesación del Tabaquismo: regístrate hoy</>
    ),
  },
  PAGE_PARTNER_LANDING_STREAMLINED_DESCRIPTION: {
    [LANGUAGES.EN]: <>Claim your free access to Pivot!</>,
    [LANGUAGES.ES]: <>¡Solicita tu acceso gratuito a Pivot!</>,
  },
  PAGE_VIRGIN_PULSE_LANDING_TITLE: {
    [LANGUAGES.EN]: <>Pivot Tobacco Cessation Program - Register today</>,
    [LANGUAGES.ES]: (
      <>Programa Pivot de Cesación del Tabaquismo: regístrate hoy</>
    ),
  },
  PAGE_VIRGIN_PULSE_LANDING_DESCRIPTION: {
    [LANGUAGES.EN]: (
      <>
        Pivot has partnered with Virgin Pulse to provide you with free access to
        Pivot!
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        ¡Pivot se ha asociado con Virgin Pulse para brindarte acceso gratuito a
        Pivot!
      </>
    ),
  },
  PAGE_COVERAGE_TITLE: {
    [LANGUAGES.EN]: (
      <>Pivot Registration - Find your employer or enter an access code</>
    ),
    [LANGUAGES.ES]: (
      <>Registro en Pivot: Busca a tu empleador o ingresa un código de acceso</>
    ),
  },
  PAGE_COVERAGE_DESCRIPTION: {
    [LANGUAGES.EN]: (
      <>
        You may be eligible for free access to Pivot. Enter your
        employer-provided access code or search for your employer.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Puedes ser elegible para acceder gratuitamente a Pivot. Ingresa el
        código de acceso proporcionado por tu empleador o busca a tu empleador.
      </>
    ),
  },
  PAGE_NO_COVERAGE_TITLE: {
    [LANGUAGES.EN]: (
      <>Pivot Registration - Find your employer or enter an access code</>
    ),
    [LANGUAGES.ES]: (
      <>Registro en Pivot: Busca a tu empleador o ingresa un código de acceso</>
    ),
  },
  PAGE_NO_COVERAGE_DESCRIPTION: {
    [LANGUAGES.EN]: (
      <>
        You may be eligible for free access to Pivot. Enter your
        employer-provided access code or search for your employer.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Puedes ser elegible para acceder gratuitamente a Pivot. Ingresa el
        código de acceso proporcionado por tu empleador o busca a tu empleador.
      </>
    ),
  },
  PAGE_THANK_YOU_TITLE: {
    [LANGUAGES.EN]: <>Pivot Registration - Thank you</>,
    [LANGUAGES.ES]: <>Registro en Pivot: Gracias</>,
  },
  PAGE_THANK_YOU_DESCRIPTION: { [LANGUAGES.EN]: <></>, [LANGUAGES.ES]: <></> },
  PAGE_NICOTINE_USAGE_SELECTION_TITLE: {
    [LANGUAGES.EN]: <>Pivot Registration - Tobacco usage</>,
    [LANGUAGES.ES]: <>Registro en Pivot: Consumo de tabaco</>,
  },
  PAGE_NICOTINE_USAGE_SELECTION_DESCRIPTION: {
    [LANGUAGES.EN]: <></>,
    [LANGUAGES.ES]: <></>,
  },
  PAGE_NICORETTE_NICOTINE_USAGE_SELECTION_TITLE: {
    [LANGUAGES.EN]: <>Pivot Registration - Tobacco usage</>,
    [LANGUAGES.ES]: <>Registro en Pivot: Consumo de tabaco</>,
  },
  PAGE_NICORETTE_NICOTINE_USAGE_SELECTION_DESCRIPTION: {
    [LANGUAGES.EN]: <></>,
    [LANGUAGES.ES]: <></>,
  },
  PAGE_ELIGIBILITY_INTRO_TITLE: {
    [LANGUAGES.EN]: <>Pivot Registration - Eligibility</>,
    [LANGUAGES.ES]: <>Registro en Pivot: Elegibilidad</>,
  },
  PAGE_ELIGIBILITY_INTRO_DESCRIPTION: {
    [LANGUAGES.EN]: <></>,
    [LANGUAGES.ES]: <></>,
  },
  PAGE_ELIGIBILITY_PRIMARY_TITLE: {
    [LANGUAGES.EN]: <>Pivot Registration - Eligibility</>,
    [LANGUAGES.ES]: <>Registro en Pivot: Elegibilidad</>,
  },
  PAGE_ELIGIBILITY_PRIMARY_DESCRIPTION: {
    [LANGUAGES.EN]: <></>,
    [LANGUAGES.ES]: <></>,
  },
  PAGE_ELIGIBILITY_SECONDARY_TITLE: {
    [LANGUAGES.EN]: <>Pivot Registration - Eligibility continued</>,
    [LANGUAGES.ES]: <>Registro en Pivot: Continuidad de la elegibilidad</>,
  },
  PAGE_ELIGIBILITY_SECONDARY_DESCRIPTION: {
    [LANGUAGES.EN]: <></>,
    [LANGUAGES.ES]: <></>,
  },
  PAGE_REVIEW_ELIGIBILITY_TITLE: {
    [LANGUAGES.EN]: <>Pivot Registration - Review</>,
    [LANGUAGES.ES]: <>Registro en Pivot: Revisar</>,
  },
  PAGE_REVIEW_ELIGIBILITY_DESCRIPTION: {
    [LANGUAGES.EN]: <></>,
    [LANGUAGES.ES]: <></>,
  },
  PAGE_ELIGIBILITY_APPROVED_TITLE: {
    [LANGUAGES.EN]: <>Pivot Registration - Review</>,
    [LANGUAGES.ES]: <>Registro en Pivot: Revisar</>,
  },
  PAGE_ELIGIBILITY_APPROVED_DESCRIPTION: {
    [LANGUAGES.EN]: <></>,
    [LANGUAGES.ES]: <></>,
  },
  PAGE_APPROVED_TITLE: {
    [LANGUAGES.EN]: <>Pivot Registration - Eligibility approval</>,
    [LANGUAGES.ES]: <>Registro en Pivot: Aprobación de la elegibilidad</>,
  },
  PAGE_APPROVED_DESCRIPTION: { [LANGUAGES.EN]: <></>, [LANGUAGES.ES]: <></> },
  PAGE_CREATE_ACCOUNT_TITLE: {
    [LANGUAGES.EN]: <>Pivot Registration - Create</>,
    [LANGUAGES.ES]: <>Registro en Pivot: Crear</>,
  },
  PAGE_CREATE_ACCOUNT_DESCRIPTION: {
    [LANGUAGES.EN]: <></>,
    [LANGUAGES.ES]: <></>,
  },
  PAGE_CREATE_ACCOUNT_FOX_TITLE: {
    [LANGUAGES.EN]: <>Pivot Registration - Create</>,
    [LANGUAGES.ES]: <>Registro en Pivot: Crear</>,
  },
  PAGE_CREATE_ACCOUNT_FOX_DESCRIPTION: {
    [LANGUAGES.EN]: <></>,
    [LANGUAGES.ES]: <></>,
  },
  PAGE_DOWNLOAD_APP_TITLE: {
    [LANGUAGES.EN]: <>Pivot Registration - Verification email sent</>,
    [LANGUAGES.ES]: (
      <>Registro en Pivot: Correo electrónico de verificación enviado</>
    ),
  },
  PAGE_DOWNLOAD_APP_DESCRIPTION: {
    [LANGUAGES.EN]: <></>,
    [LANGUAGES.ES]: <></>,
  },
  PAGE_COMPLETE_REGISTRATION_TITLE: {
    [LANGUAGES.EN]: <>Pivot Registration - Complete</>,
    [LANGUAGES.ES]: <>Registro en Pivot: Completo</>,
  },
  PAGE_COMPLETE_REGISTRATION_DESCRIPTION: {
    [LANGUAGES.EN]: <></>,
    [LANGUAGES.ES]: <></>,
  },
  PAGE_REGISTRATION_ERROR_TITLE: {
    [LANGUAGES.EN]: <>Pivot Registration - Error</>,
    [LANGUAGES.ES]: <>Registro en Pivot: Error</>,
  },
  PAGE_REGISTRATION_ERROR_DESCRIPTION: {
    [LANGUAGES.EN]: <></>,
    [LANGUAGES.ES]: <></>,
  },
  PAGE_SIGNUP_ONLY_TITLE: {
    [LANGUAGES.EN]: <>Pivot Registration - Signup</>,
    [LANGUAGES.ES]: <>Registro en Pivot: Registro</>,
  },
  PAGE_SIGNUP_ONLY_DESCRIPTION: {
    [LANGUAGES.EN]: <></>,
    [LANGUAGES.ES]: <></>,
  },
  PAGE_CONFIRMED_TITLE: {
    [LANGUAGES.EN]: <>Pivot Registration - Confirmed</>,
    [LANGUAGES.ES]: <>Registro en Pivot: Confirmado</>,
  },
  PAGE_CONFIRMED_DESCRIPTION: { [LANGUAGES.EN]: <></>, [LANGUAGES.ES]: <></> },
  PAGE_ELIGIBILITY_TAKEN_TITLE: {
    [LANGUAGES.EN]: <>Pivot Registration - Eligibility taken</>,
    [LANGUAGES.ES]: <>Registro en Pivot: Elegibilidad aceptada</>,
  },
  PAGE_ELIGIBILITY_TAKEN_DESCRIPTION: {
    [LANGUAGES.EN]: <></>,
    [LANGUAGES.ES]: <></>,
  },
  PAGE_LOGIN_TITLE: {
    [LANGUAGES.EN]: <>Login - Pivot</>,
    [LANGUAGES.ES]: <>Iniciar sesión: Pivot</>,
  },
  PAGE_LOGIN_DESCRIPTION: {
    [LANGUAGES.EN]: <>Log into your Pivot account</>,
    [LANGUAGES.ES]: <>Inicia sesión en tu cuenta Pivot</>,
  },
  PAGE_FORGOT_PASSWORD_TITLE: {
    [LANGUAGES.EN]: <>Forgot Password - Pivot</>,
    [LANGUAGES.ES]: <>Olvidé mi contraseña: Pivot</>,
  },
  PAGE_FORGOT_PASSWORD_DESCRIPTION: {
    [LANGUAGES.EN]: (
      <>Forgot your password? Recover access to your Pivot account.</>
    ),
    [LANGUAGES.ES]: (
      <>¿Olvidó su contraseña? Recupera el acceso a tu cuenta Pivot.</>
    ),
  },
  PAGE_RESET_PASSWORD_TITLE: {
    [LANGUAGES.EN]: <>Reset Password - Pivot</>,
    [LANGUAGES.ES]: <>Restablecer contraseña: Pivot</>,
  },
  PAGE_RESET_PASSWORD_DESCRIPTION: {
    [LANGUAGES.EN]: <>Reset your Pivot password.</>,
    [LANGUAGES.ES]: <>Restablezca su contraseña de Pivot.</>,
  },
  PAGE_PROFILE_TITLE: {
    [LANGUAGES.EN]: <>My Profile - Pivot</>,
    [LANGUAGES.ES]: <>Mi perfil: Pivot</>,
  },
  PAGE_PROFILE_DESCRIPTION: {
    [LANGUAGES.EN]: <>Access your Pivot profile.</>,
    [LANGUAGES.ES]: <>Acceda a su perfil de Pivot.</>,
  },
  PAGE_SUBSCRIPTION_RENEWAL_TITLE: {
    [LANGUAGES.EN]: <>Subscription Renewal - Pivot</>,
    [LANGUAGES.ES]: <>Renovación de suscripción: Pivot</>,
  },
  PAGE_SUBSCRIPTION_RENEWAL_DESCRIPTION: {
    [LANGUAGES.EN]: <></>,
    [LANGUAGES.ES]: <></>,
  },
  PAGE_REORDER_SENSOR_TITLE: {
    [LANGUAGES.EN]: <>Reorder Sensor - Pivot</>,
    [LANGUAGES.ES]: <>Volver a ordenar el sensor: Pivot</>,
  },
  PAGE_REORDER_SENSOR_DESCRIPTION: {
    [LANGUAGES.EN]: <></>,
    [LANGUAGES.ES]: <></>,
  },
  PAGE_CHANGE_ADDRESS_TITLE: {
    [LANGUAGES.EN]: <>Update your shipping address - Pivot</>,
    [LANGUAGES.ES]: <>Actualiza tu dirección de envío: Pivot</>,
  },
  PAGE_CHANGE_ADDRESS_DESCRIPTION: {
    [LANGUAGES.EN]: <></>,
    [LANGUAGES.ES]: <></>,
  },
  PAGE_RECONFIRM_TITLE: {
    [LANGUAGES.EN]: <>Pivot Registration - Reconfirm</>,
    [LANGUAGES.ES]: <>Registro en Pivot: Reconfirmar</>,
  },
  PAGE_RECONFIRM_DESCRIPTION: { [LANGUAGES.EN]: <></>, [LANGUAGES.ES]: <></> },
  PAGE_SHORTLIST_TITLE: {
    [LANGUAGES.EN]: <>Pivot Registration - Shortlist</>,
    [LANGUAGES.ES]: <>Registro en Pivot: Preselección</>,
  },
  PAGE_SHORTLIST_DESCRIPTION: { [LANGUAGES.EN]: <></>, [LANGUAGES.ES]: <></> },
  PAGE_NRT_TITLE: {
    [LANGUAGES.EN]: <>NRT Order Flow - Pivot</>,
    [LANGUAGES.ES]: <>Flujo de órdenes de NRT: Pivot</>,
  },
  PAGE_NRT_DESCRIPTION: { [LANGUAGES.EN]: <></>, [LANGUAGES.ES]: <></> },
  PAGE_NRT_FAQ_TITLE: {
    [LANGUAGES.EN]: <>NRT Order Flow - FAQs - Pivot</>,
    [LANGUAGES.ES]: <>Flujo de órdenes de NRT: Preguntas frecuentes: Pivot</>,
  },
  PAGE_NRT_FAQ_DESCRIPTION: {
    [LANGUAGES.EN]: <>Nicotine Replacement Therapy - FAQs - Pivot</>,
    [LANGUAGES.ES]: (
      <>Terapia de Remplazo de Nicotina: Preguntas frecuentes: Pivot</>
    ),
  },
  PAGE_SHIPPING_TITLE: {
    [LANGUAGES.EN]: <>Pivot Registration - Shipping information </>,
    [LANGUAGES.ES]: <>Registro en Pivot: Información de envío</>,
  },
  PAGE_SHIPPING_DESCRIPTION: {
    [LANGUAGES.EN]: <>Enter your shipping information</>,
    [LANGUAGES.ES]: <>Ingresa tu información de envío</>,
  },
  PAGE_PERSONAL_TITLE: {
    [LANGUAGES.EN]: <>Pivot Registration - Create an account</>,
    [LANGUAGES.ES]: <>Registro en Pivot: Crear una cuenta</>,
  },
  PAGE_PERSONAL_DESCRIPTION: {
    [LANGUAGES.EN]: <>Create your Pivot account today!</>,
    [LANGUAGES.ES]: <>¡Crea tu cuenta Pivot hoy!</>,
  },
  PAGE_CREDENTIALS_TITLE: {
    [LANGUAGES.EN]: <>Pivot Registration - Complete account creation</>,
    [LANGUAGES.ES]: <>Registro en Pivot: Completar creación de cuenta</>,
  },
  PAGE_CREDENTIALS_DESCRIPTION: {
    [LANGUAGES.EN]: <>Enter your credentials</>,
    [LANGUAGES.ES]: <>Introduce tus credenciales</>,
  },
  PAGE_RE_ENROLLMENT_TITLE: {
    [LANGUAGES.EN]: <>Pivot Tobacco Cessation Program - Account extended</>,
    [LANGUAGES.ES]: (
      <>Programa Pivot de Cesación del Tabaquismo: Cuenta extendida</>
    ),
  },
  PAGE_RE_ENROLLMENT_DESCRIPTION: {
    [LANGUAGES.EN]: <></>,
    [LANGUAGES.ES]: <></>,
  },
  PAGE_RE_ENROLLMENT_FAILED_TITLE: {
    [LANGUAGES.EN]: <>Pivot Tobacco Cessation Program - Eligibility expired</>,
    [LANGUAGES.ES]: (
      <>Programa Pivot de Cesación del Tabaquismo: Elegibilidad expirada</>
    ),
  },
  PAGE_RE_ENROLLMENT_FAILED_DESCRIPTION: {
    [LANGUAGES.EN]: <></>,
    [LANGUAGES.ES]: <></>,
  },
  PAGE_RE_ENROLLMENT_NOT_AVAILABLE_TITLE: {
    [LANGUAGES.EN]: (
      <>Pivot Tobacco Cessation Program - Re-enrollment not available</>
    ),
    [LANGUAGES.ES]: (
      <>
        Programa Pivot de Cesación del Tabaquismo: No es posible volver a
        matricularse
      </>
    ),
  },
  PAGE_RE_ENROLLMENT_NOT_AVAILABLE_DESCRIPTION: {
    [LANGUAGES.EN]: <></>,
    [LANGUAGES.ES]: <></>,
  },
  PAGE_PRIVACY_POLICY_TITLE: {
    [LANGUAGES.EN]: <>Pivot Registration - Privacy Policy</>,
    [LANGUAGES.ES]: <>Registro en Pivot: Política de privacidad</>,
  },
  PAGE_PRIVACY_POLICY_DESCRIPTION: {
    [LANGUAGES.EN]: <></>,
    [LANGUAGES.ES]: <></>,
  },
  PAGE_TERMS_AND_CONDITIONS_TITLE: {
    [LANGUAGES.EN]: <>Pivot Tobacco Cessation Program - Terms and Conditions</>,
    [LANGUAGES.ES]: (
      <>Programa Pivot de Cesación del Tabaquismo: Términos y condiciones</>
    ),
  },
  PAGE_TERMS_AND_CONDITIONS_DESCRIPTION: {
    [LANGUAGES.EN]: <></>,
    [LANGUAGES.ES]: <></>,
  },
  groupNumber: {
    [LANGUAGES.EN]: <>Group Number</>,
    [LANGUAGES.ES]: <>Número de grupo</>,
  },
  enterEmployeeOrMemberId: {
    [LANGUAGES.EN]: <>Please enter your %</>,
    [LANGUAGES.ES]: <>Ingresa tu %</>,
  },
  enterNCharsEmployeeOrMemberId: {
    [LANGUAGES.EN]: <>Please enter your 11-character %</>,
    [LANGUAGES.ES]: <>Ingresa tu % de 11 caracteres</>,
  },
  sbsAppMenuAriaLabel: {
    [LANGUAGES.EN]: <>Read more about the Pivot app</>,
    [LANGUAGES.ES]: <>Lee más sobre la aplicación Pivot</>,
  },
  sbsSensorMenuAriaLabel: {
    [LANGUAGES.EN]: <>Read more about the Pivot Breath Sensor</>,
    [LANGUAGES.ES]: <>Lee más sobre el Sensor de Respiración Pivot</>,
  },
  sbsNrtMenuAriaLabel: {
    [LANGUAGES.EN]: <>Read more about Nicotine replacement therapy</>,
    [LANGUAGES.ES]: <>Lee más sobre la Terapia de Reemplazo de Nicotina</>,
  },
  sbsCoachingMenuAriaLabel: {
    [LANGUAGES.EN]: <>Read more about our personalized guidance</>,
    [LANGUAGES.ES]: <>Lee más sobre nuestro entrenamiento personalizado</>,
  },
  downloadSpanishFlyer: {
    [LANGUAGES.EN]: (
      <>
        Download a Spanish translation brochure of the Pivot smoking cessation
        program in a new tab
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Descarga un folleto de traducción al español del programa Pivot dejar de
        fumar en una nueva pestaña
      </>
    ),
  },
  testimonialTony: {
    [LANGUAGES.EN]: (
      <>
        When I decided to quit, I believe it was because of the breath sensor.
        The sensor kept reminding me, man, look what I’m doing to myself. to my
        lungs.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Cuando decidí dejarlo, creo que fue por el sensor de aliento. El sensor
        seguía recordándome, hombre, mira lo que me estoy haciendo. a mis
        pulmones.
      </>
    ),
  },
  testimonialJason: {
    [LANGUAGES.EN]: (
      <>
        The Pivot sensor made a big difference for me. As I continued to see my
        goals manifest on that gadget it gave me confidence that I was
        progressing.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        El sensor Pivot marcó una gran diferencia para mí. Mientras seguía
        viendo mis objetivos manifestados en ese dispositivo, me dio confianza
        de que estaba progresando.
      </>
    ),
  },
  testimonialJames: {
    [LANGUAGES.EN]: (
      <>
        How has my life changed? I can taste food. I sleep better. I breathe
        easier. I have more money, which is awesome.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        ¿Cómo ha cambiado mi vida? Puedo saborear la comida. Duermo mejor.
        Respiro con más facilidad. Tengo más dinero, lo cual es fantástico.
      </>
    ),
  },
  testimonialRobin: {
    [LANGUAGES.EN]: (
      <>
        I think the sensor is a game changer. It gives you something tangible.
        It gives you something to look at like tracking your steps.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Creo que el sensor cambia las reglas del juego. Te da algo tangible. Te
        brinda algo que mirar, como seguir tus pasos.
      </>
    ),
  },
  testimonialRita: {
    [LANGUAGES.EN]: (
      <>
        I was surprised that there was no judgement or pressure to quit. Also
        surprising was all of the tools and resources at my disposal.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Me sorprendió que no hubiera ningún juicio ni presión para dejar.
        También me sorprendieron todas las herramientas y recursos a mi
        disposición.
      </>
    ),
  },
  testimonialRey: {
    [LANGUAGES.EN]: (
      <>
        Just give it a try! Seriously, one week. You’ll feel the difference in
        your thinking and the encouragement you get from the Pivot community.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        ¡Pruébalo! En serio, una semana. Sentirás la diferencia en tu forma de
        pensar y el estímulo que recibirás de la comunidad de Pivot.
      </>
    ),
  },
  testimonialJane: {
    [LANGUAGES.EN]: (
      <>
        Pivot is wonderful and did not pressure me to quit. My coach and I
        worked gradually to make a plan right for my needs. The tools were also
        very helpful.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Pivot es maravilloso y no me presionó para dejar. Mi entrenador y yo
        trabajamos gradualmente para elaborar un plan adecuado a mis
        necesidades. Las herramientas también fueron muy útiles.
      </>
    ),
  },
  testimonialDoug: {
    [LANGUAGES.EN]: (
      <>
        It is for everyone. No matter what type of person you are, this app will
        work with you.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Es para todos. No importa qué tipo de persona seas, esta aplicación
        funcionará contigo.
      </>
    ),
  },
  expiresOn: {
    [LANGUAGES.EN]: <>Expires %</>,
    [LANGUAGES.ES]: <>Vence el %</>,
  },
  saveProgressAndExtend: {
    [LANGUAGES.EN]: (
      <>You can save all your progress and extend Pivot for another % %</>
    ),
    [LANGUAGES.ES]: (
      <>Puedes guardar todo tu progreso y extender Pivot por otros % %</>
    ),
  },
  noTimePeriodNrt: { [LANGUAGES.EN]: <>NRT</>, [LANGUAGES.ES]: <>NRT</> },
  timePeriodOfNrt: {
    [LANGUAGES.EN]: <>% % of NRT</>,
    [LANGUAGES.ES]: <>% % de NRT</>,
  },
  noTimePeriodCoaching: {
    [LANGUAGES.EN]: <>Coaching</>,
    [LANGUAGES.ES]: <>Entrenamiento</>,
  },
  timePeriodOfCoaching: {
    [LANGUAGES.EN]: <>% % of coaching</>,
    [LANGUAGES.ES]: <>% % de entrenamiento</>,
  },
  noTimePeriodApp: {
    [LANGUAGES.EN]: <>Pivot App</>,
    [LANGUAGES.ES]: <>Aplicación Pivot</>,
  },
  timePeriodOfApp: {
    [LANGUAGES.EN]: <>% % access to Pivot App</>,
    [LANGUAGES.ES]: <>% % de acceso a la aplicación Pivot</>,
  },
  renewProgram: {
    [LANGUAGES.EN]: <>Renew program</>,
    [LANGUAGES.ES]: <>Renovar programa</>,
  },
  renewProgramOn: {
    [LANGUAGES.EN]: <>Renew program (Available % %)</>,
    [LANGUAGES.ES]: <>Renovar programa (Disponible % %)</>,
  },
  memberId: {
    [LANGUAGES.EN]: <>Member ID</>,
    [LANGUAGES.ES]: <>ID de Miembro</>,
  },
  employeeId: {
    [LANGUAGES.EN]: <>Employee ID</>,
    [LANGUAGES.ES]: <>ID de Empleado</>,
  },
  teamMemberId: {
    [LANGUAGES.EN]: <>Team Member ID</>,
    [LANGUAGES.ES]: <>ID de Miembro de Equipo</>,
  },
  enterMemberOrEmployeeOrTeamMemberId: {
    [LANGUAGES.EN]: <>Please enter your %</>,
    [LANGUAGES.ES]: <>Ingresa tu %</>,
  },
  dontKnowMemberOrEmployeeOrTeamMemberId: {
    [LANGUAGES.EN]: <>I don't know my</>,
    [LANGUAGES.ES]: <>No sé mi</>,
  },
  sendCode: {
    [LANGUAGES.EN]: <>Send Code</>,
    [LANGUAGES.ES]: <>Enviar Código</>,
  },
  changeAddressRequest: {
    [LANGUAGES.EN]: <>Change Address Request</>,
    [LANGUAGES.ES]: <>Cambio de dirección</>,
  },
  checkingEligibility: {
    [LANGUAGES.EN]: <>Checking eligibility...</>,
    [LANGUAGES.ES]: <>Verificando eligibilidad...</>,
  },
  ifEmailIncorrect: {
    [LANGUAGES.EN]: <>If the email above is incorrect, please</>,
    [LANGUAGES.ES]: <>Si el correo de arriba es incorrecto, por favor</>,
  },
  restartHere: {
    [LANGUAGES.EN]: <>restart here</>,
    [LANGUAGES.ES]: <>reinicia aquí</>,
  },
  and: {
    [LANGUAGES.EN]: <>and</>,
    [LANGUAGES.ES]: <>y</>,
  },
  downloadCopy: {
    [LANGUAGES.EN]: (
      <>
        Start conquering your cravings with games, lessons, community and more
        right now in our Pivot app.
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Comienza a dominar tus antojos con juegos, lecciones, comunidad y más
        ahora mismo en nuestra app Pivot.
      </>
    ),
  },
  downloadTitleWebUi: {
    [LANGUAGES.EN]: (
      <>
        Great, you’re all signed up!
        <strong>Now let’s get started with the Pivot program.</strong>
      </>
    ),
    [LANGUAGES.ES]: (
      <>
        Genial, ¡ya te has suscrito!
        <strong>Ahora, comencemos con el programa Pivot.</strong>
      </>
    ),
  },
  downloadCopyWebUi: {
    [LANGUAGES.EN]: <>Or download the Pivot app:</>,
    [LANGUAGES.ES]: <>O descarga la app Pivot:</>,
  },
};
