import { eligibilityRootEndpoint } from '../../../../config';
import { createUserDataFromSelections } from '../account-creation/userDataUtils';
import { getLanguageCode, getSku } from '../account-creation/utils';
import {
  validateName,
  validateZip,
  validateEmail,
  validateMinStringLength,
  isAgeInProgramRange,
  makeDateObjectFromDateString,
  isValidDate,
  validateGender,
  validateStringLength,
} from '../../../utils/validation';
import { store } from '../../../../state/store';
import { fetcher } from '../../../utils/fetcher';
import { AGE_CATEGORY } from '../../../constants';
import { getLocale, str, strReplace } from '../../../i18n/utils';
import { I18N_STR } from '../../../i18n/strings';

export const ORGANIZATION_FIELD_NAME_TO_HIDE = 'organization';

export const ELIG_KEY_NAMES = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  DOB: 'dateOfBirth',
  ZIP: 'zipCode',
  GENDER: 'gender',
  ZIP_AND_GENDER: 'zipAndGender',
  EMAIL: 'email',
  MEMBER_ID: 'memberId',
  GROUP_NUMBER: 'groupNumber',
  PRIMARY_FIRST_NAME: 'primaryFirstName',
  PRIMARY_LAST_NAME: 'primaryLastName',
  PRIMARY_MEMBER_ID: 'primaryMemberId',
  PRIMARY_DOB: 'primaryDateOfBirth',
};

const MEMBER_ID_LENGTH_FOR_CDPHP = 11;
const MEMBER_ID_LENGTH_FOR_HEALTHPARTNERS = 8;

export const FIELDS_ORDER = [
  ELIG_KEY_NAMES.FIRST_NAME,
  ELIG_KEY_NAMES.LAST_NAME,
  ELIG_KEY_NAMES.GENDER,
  ELIG_KEY_NAMES.ZIP,
  ELIG_KEY_NAMES.DOB,
  ELIG_KEY_NAMES.EMAIL,
  ELIG_KEY_NAMES.MEMBER_ID,
  ELIG_KEY_NAMES.GROUP_NUMBER,
];

export const ELIGIBILITY_FIELDS = {
  email: {
    placeholder: key => getPlaceHolder(key, str(I18N_STR.email)),
    instruction: '',
    validator: validateEmail,
    errorString: str(I18N_STR.EMAIL_NOT_VALID),
  },
  gender: {
    placeholder: key => getPlaceHolder(key, str(I18N_STR.gender)),
    instruction: '',
    validator: validateGender,
    errorString: str(I18N_STR.MISSING),
  },
  firstName: {
    placeholder: key => getPlaceHolder(key, str(I18N_STR.firstName)),
    instruction: str(I18N_STR.enterLegalFirstName),
    validator: validateName,
    errorString: str(I18N_STR.INVALID_INPUT),
    autoComplete: 'given-name',
  },
  lastName: {
    placeholder: key => getPlaceHolder(key, str(I18N_STR.lastName)),
    instruction: str(I18N_STR.enterLegalLastName),
    validator: validateName,
    errorString: str(I18N_STR.INVALID_INPUT),
    autoComplete: 'family-name',
  },
  dateOfBirth: {
    placeholder: key => getPlaceHolder(key, str(I18N_STR.birthdate)),
    instruction: str(I18N_STR.enterDob),
    validator: value => {
      const dob = makeDateObjectFromDateString(value);
      const state = store.getState();
      const ageCategory =
        state?.accessCodeConfig?.ageCategory || AGE_CATEGORY.ADULT;
      return isAgeInProgramRange({ ...dob, month: dob.month - 1 }, ageCategory);
    },
  },
  zipCode: {
    placeholder: key => getPlaceHolder(key, str(I18N_STR.zipCode)),
    instruction: str(I18N_STR.enterZipcode),
    validator: validateZip,
    errorString: str(I18N_STR.ENTER_VALID_ZIP),
    autoComplete: 'postal-code',
  },
  memberId: {
    placeholder: key => getPlaceHolder(key, employeeOrMemberId()),
    instruction: strReplace(
      I18N_STR.enterMemberOrEmployeeOrTeamMemberId,
      false,
      [employeeOrMemberId()]
    ),
    validator: validateMinStringLength,
    errorString: str(I18N_STR.MISSING),
  },
  memberIdForCDPHP: {
    placeholder: key => getPlaceHolder(key, employeeOrMemberId()),
    instruction: strReplace(I18N_STR.enterNCharsEmployeeOrMemberId, false, [
      '11',
      employeeOrMemberId(),
    ]),
    validator: value => validateStringLength(value, MEMBER_ID_LENGTH_FOR_CDPHP),
    errorString: str(I18N_STR.INVALID_INPUT),
  },
  memberIdForHealthPartners: {
    placeholder: key => getPlaceHolder(key, employeeOrMemberId()),
    instruction: strReplace(I18N_STR.enterNCharsEmployeeOrMemberId, false, [
      '8',
      employeeOrMemberId(),
    ]),
    validator: value =>
      validateStringLength(value, MEMBER_ID_LENGTH_FOR_HEALTHPARTNERS),
    errorString: str(I18N_STR.INVALID_INPUT),
  },
  employeeId: {
    placeholder: key => getPlaceHolder(key, employeeOrMemberId()),
    instruction: strReplace(
      I18N_STR.enterMemberOrEmployeeOrTeamMemberId,
      false,
      [employeeOrMemberId()]
    ),
    validator: validateMinStringLength,
    errorString: str(I18N_STR.MISSING),
  },
  groupNumber: {
    placeholder: key => getPlaceHolder(key, str(I18N_STR.groupNumber)),
    instruction: str(I18N_STR.enterGroupNumber),
    validator: validateMinStringLength,
    errorString: str(I18N_STR.MISSING),
  },
  primaryFirstName: {
    placeholder: key => getPlaceHolder(key, str(I18N_STR.firstName)),
    instruction: str(I18N_STR.enterLegalFirstName),
    validator: validateName,
    errorString: str(I18N_STR.INVALID_INPUT),
  },
  primaryLastName: {
    placeholder: key => getPlaceHolder(key, str(I18N_STR.lastName)),
    instruction: str(I18N_STR.enterLegalLastName),
    validator: validateName,
    errorString: str(I18N_STR.INVALID_INPUT),
  },
  primaryDateOfBirth: {
    placeholder: key => getPlaceHolder(key, str(I18N_STR.birthdate)),
    instruction: str(I18N_STR.enterDob),
    validator: value => isValidDate(makeDateObjectFromDateString(value)),
    errorString: str(I18N_STR.INVALID_INPUT),
  },
  primaryGender: {
    placeholder: key => getPlaceHolder(key, str(I18N_STR.gender)),
    instruction: '',
    validator: validateGender,
    errorString: str(I18N_STR.MISSING),
  },
  primaryMemberId: {
    placeholder: key => getPlaceHolder(key, employeeOrMemberId()),
    instruction: strReplace(
      I18N_STR.enterMemberOrEmployeeOrTeamMemberId,
      false,
      [employeeOrMemberId()]
    ),
    validator: validateMinStringLength,
    errorString: str(I18N_STR.MISSING),
  },
};

function getPlaceHolder(key, defaultPlaceholder) {
  const state = store.getState();

  return (
    defaultPlaceholder ||
    state?.accessCodeConfig?.eligibility?.displayText?.[key]
  );
}

export function employeeOrMemberId() {
  const state = store.getState();

  const stringFromTemplate =
    state?.accessCodeConfig?.webTemplate?.uniqueIdTextboxPlaceholderCopy?.toLowerCase();

  if (stringFromTemplate === 'employee id') {
    return str(I18N_STR.employeeId);
  }

  if (stringFromTemplate === 'team member id') {
    return str(I18N_STR.teamMemberId);
  }

  // default Member ID
  return str(I18N_STR.memberId);
}

export function isEligible(eligibilityAnswers) {
  const userRegistrationData = createUserDataFromSelections();
  const payload = {
    ...eligibilityAnswers,
    webRegForm: {
      attributes: userRegistrationData.attributes,
      userData: userRegistrationData.userData,
      sku: getSku(),
      locale: getLanguageCode(),
    },
  };

  const endpoint = `${eligibilityRootEndpoint}/v1/eligibility/users/isEligible`;
  return fetcher(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
    .then(res => res.json())
    .catch(err => {
      console.log('err in isEligible', eligibilityAnswers, err);
      return err;
    });
}

export function submitLegalAddressForClaims(email, address) {
  const endpoint = `${eligibilityRootEndpoint}/v1/eligibility/users/${email}/address`;
  return fetcher(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...address,
    }),
  });
}

export function isRealtimeEligibilityCheck(eligibility) {
  return eligibility?.eligibilityServiceProvider === 'changehealthcare';
}

export function isCarrotEligibilityCheck(eligibility) {
  return eligibility?.eligibilityServiceProvider === 'carrot';
}

export function isVirginPulseEligibilityCheck(eligibility) {
  return eligibility?.eligibilityServiceProvider === 'virginpulse';
}
