import { useEffect, useRef } from 'react';
import PortalModal from '../../../components/PortalModal';
import { VP_AUTH_STATES } from '../virgin-pulse/useVirginPulse';
import { EmailSupportLink } from '../common-components/EmailSupportLink';
import { ReactComponent as Image } from '../../../../media/email-exists.svg';
import { useI18n } from '../../../i18n/utils';
import { I18N_STR } from '../../../i18n/strings';

export default function VirginPulseEmailExistsModal({ authState }) {
  const modalRef = useRef();
  const { str } = useI18n();

  useEffect(() => {
    if (
      authState === VP_AUTH_STATES.EMAIL_ALREADY_REGISTERED &&
      modalRef?.current
    ) {
      modalRef.current.focus();
    }
  }, [authState]);

  return authState === VP_AUTH_STATES.EMAIL_ALREADY_REGISTERED ? (
    <PortalModal onTop={true}>
      <div className="obstacle-modal vp-modal email-modal">
        <div className="inner-modal wrapper" ref={modalRef}>
          <Image />
          <h3>{str(I18N_STR.vpExistsModalTitle)}</h3>
          <p>{str(I18N_STR.vpExistsModalCopy)} </p>
          <div className="form-footer">
            <EmailSupportLink className="cs-button" />
          </div>
        </div>
      </div>
    </PortalModal>
  ) : null;
}
