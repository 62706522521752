import React from 'react';

// desktop webp
import ImageX1WebP from '../../../../media/responsive-hero-images/bcnc-hero-desktop@1x.webp';
import ImageX2WebP from '../../../../media/responsive-hero-images/bcnc-hero-dekstop@2x.webp';

//desktop jpg
import ImageX1 from '../../../../media/responsive-hero-images/bcnc-hero-desktop@1x.jpg';
import ImageX2 from '../../../../media/responsive-hero-images/bcnc-hero-dekstop@2x.jpg';

// mobile webp
import ImageMobileXHalfWebP from '../../../../media/responsive-hero-images/bcnc-hero-mobile@0.5x.webp';
import ImageMobileX1WebP from '../../../../media/responsive-hero-images/bcnc-hero-mobile@1x.webp';

// mobile jpg
import ImageMobileXHalf from '../../../../media/responsive-hero-images/bcnc-hero-mobile@0.5x.jpg';
import ImageMobileX1 from '../../../../media/responsive-hero-images/bcnc-hero-mobile@1x.jpg';

export const ResponsiveHeroImage = () => {
  return (
    <picture className="hero-image">
      {/*webp*/}
      <source
        media="(max-width: 640px) and (-webkit-min-device-pixel-ratio: 2)"
        srcSet={ImageMobileX1WebP}
        type="image/webp"
      />
      <source
        media="(max-width: 640px)"
        srcSet={ImageMobileXHalfWebP}
        type="image/webp"
      />
      <source
        media="(max-width: 1280px)"
        srcSet={ImageX1WebP}
        type="image/webp"
      />
      <source
        media="(min-width: 1281px)"
        srcSet={ImageX2WebP}
        type="image/webp"
      />
      {/*jpeg*/}
      <source
        media="(max-width: 640px) and (-webkit-min-device-pixel-ratio: 2)"
        srcSet={ImageMobileXHalf}
        type="image/webp"
      />
      <source
        media="(max-width: 640px)"
        srcSet={ImageMobileX1}
        type="image/jpeg"
      />
      <source media="(max-width: 1280px)" srcSet={ImageX1} type="image/jpeg" />
      <source media="(min-width: 1281px)" srcSet={ImageX2} type="image/jpeg" />
      <img src={ImageX1} alt="" height="680" width="1440" />
    </picture>
  );
};
