import React from 'react';
import { useI18n } from '../../../i18n/utils';
import { I18N_STR } from '../../../i18n/strings';

export const GreenTitle = () => {
  const { str } = useI18n();

  return (
    <div className="green-title-bar">
      <h2>{str(I18N_STR.whyPivotWorks)}</h2>
    </div>
  );
};
