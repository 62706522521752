import React from 'react';
import IconAvailability from '../../../../media/icon-availability.svg';
import { str, reactNodeToString, useI18n } from '../../../i18n/utils';
import { MONTHS } from '../../../strings';
import { I18N_STR } from '../../../i18n/strings';

export function AvailabilityInfo({ availabilityDate }) {
  return (
    <div className="icon-and-text">
      <img src={IconAvailability} alt="" />
      <p>{getAvailabilityDate(availabilityDate)}</p>
    </div>
  );
}

export const getAvailabilityDate = availabilityDate => {
  const date = new Date(availabilityDate);
  return `${reactNodeToString(
    str(I18N_STR.availableOnDate)
  )} ${reactNodeToString(
    MONTHS[date.getUTCMonth()]
  )} ${date.getUTCDate()}, ${date.getUTCFullYear()}`;
};
