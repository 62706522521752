import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { ACTION_TYPES } from '../../../../state/actions';
import { ADDICTION_TYPES } from '../../../constants';
import { logNavigationEvent } from '../../../utils/AnalyticsEventsLogger';
import { getUrlParam } from '../../../utils/queryVarsUtils';
import { getSku } from '../account-creation/utils';
import { PAGES } from '../../../page-urls';
import { getPrincipalAddictionType } from '../registration-utils';
import { MainHeader } from '../../../components/MainHeader';
import { MessageBar } from './MessageBar';
import Footer from '../../../components/Footer';
import { reactNodeToString, useI18n } from '../../../i18n/utils';

export function Container({
  gated,
  component: Component,
  page,
  showHeader = true,
  showFooter = true,
}) {
  const { str } = useI18n();
  const location = useLocation();
  const dispatch = useDispatch();
  const accessCodeConfig = useSelector(state => state.accessCodeConfig);
  const accessCodeFromUrl = getUrlParam('access_code');
  const isSuspectedBot = useSelector(state => state.isSuspectedBot);

  useEffect(() => {
    window.scrollTo(0, 0);
    log();
    setPageTitle();
  }, [location.pathname]);

  const log = () => {
    const sku = getSku();
    const nicotineAddictionType = getAddictionType(sku);
    const payload = {
      accessCode:
        accessCodeConfig?.accessCode || accessCodeFromUrl || undefined,
      sku: sku.name || undefined,
      nicotineAddictionType:
        nicotineAddictionType === ADDICTION_TYPES.NONE
          ? null
          : nicotineAddictionType,
      prefilled: Boolean(accessCodeFromUrl).toString(),
      isSuspectedBot: Boolean(isSuspectedBot).toString(),
    };

    logNavigationEvent(location.pathname, payload);
  };

  const setPageTitle = () => {
    dispatch({
      type: ACTION_TYPES.SET_SCREEN_READER_PAGE_TITLE,
      payload: page?.title || 'Pivot',
    });
  };

  const getAddictionType = sku => {
    const type = getPrincipalAddictionType() || null;

    if (Boolean(sku.name) && (type === ADDICTION_TYPES.NONE || type === null)) {
      return Object.keys(accessCodeConfig.availableNicotineAddictionTypes).find(
        item => {
          return (
            accessCodeConfig.availableNicotineAddictionTypes[item][0] ===
            sku.name
          );
        }
      );
    }

    return type;
  };

  if (gated === true && !accessCodeConfig?.accessCode) {
    return <Navigate to={PAGES.START.url} />;
  }

  return (
    <>
      {showHeader && <MainHeader />}
      <MessageBar />
      {getHeaderTags(page)}
      {<Component location={location} />}
      {showFooter && <Footer />}
    </>
  );
}

function getHeaderTags(page) {
  const pageTitle = page?.title ? reactNodeToString(page.title) : 'Pivot';
  const pageDescription = page?.description;
  return (
    <Helmet>
      <title>{pageTitle}</title>
      {pageDescription && <meta name="description" content={pageDescription} />}
      <meta property="og:url" content={window.location.href} />
      <meta
        property="og:title"
        content={page?.og?.title ? page?.og?.title : pageTitle}
      />
      {page?.og?.description ? (
        <meta property="og:description" content={page.og.description} />
      ) : (
        pageDescription && (
          <meta property="og:description" content={pageDescription} />
        )
      )}
      {page?.og?.image && <meta property="og:image" content={page.og.image} />}
    </Helmet>
  );
}
