import { DEVICE_TYPE } from '../constants';

export function inferDeviceTypeFromUserAgent() {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return DEVICE_TYPE.ANDROID;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return DEVICE_TYPE.IOS;
  }

  return DEVICE_TYPE.DESKTOP;
}
