import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PortalModal from '../../../components/PortalModal';
import { track, WEBREG_PREFIX } from '../../../utils/mixpanelHelper';
import { userManagementRootEndpoint, virginPulse } from '../../../../config';
import { LOCAL_STORAGE_VP_AUTH_TIMESTAMP_KEY } from '../virgin-pulse/useVirginPulse';
import { PAGES } from '../../../page-urls';
import { ReactComponent as Image } from '../../../../media/session-expired.svg';
import { useI18n } from '../../../i18n/utils';
import { I18N_STR } from '../../../i18n/strings';

const FIFTEEN_MINUTES = 900000;
const TEN_SECONDS = 10000;

export default function VirginPulseSessionTimeoutModal() {
  const location = useLocation();
  const isEmailVerificationPage =
    location.pathname === PAGES.COMPLETE_REGISTRATION.url;
  const modalRef = useRef();
  const isVirginPulse = useSelector(state => state.isVirginPulse);
  const isRegistrationComplete = useSelector(
    state => state.registrationComplete
  );

  const [hasSessionTimedOut, setHasSessionTimedOut] = useState();
  const { str } = useI18n();

  const checkIfIsInAuthTimeFrame = () => {
    const authTimestamp =
      localStorage.getItem(LOCAL_STORAGE_VP_AUTH_TIMESTAMP_KEY) || 0;
    const timeSinceAuth = Date.now() - authTimestamp;
    setHasSessionTimedOut(timeSinceAuth >= FIFTEEN_MINUTES);
  };

  useEffect(() => {
    let interval;

    if (isVirginPulse) {
      interval = setInterval(checkIfIsInAuthTimeFrame, TEN_SECONDS);
      checkIfIsInAuthTimeFrame();
    }

    return () => interval && clearInterval(interval);
  }, []);

  useEffect(() => {
    if (hasSessionTimedOut && modalRef.current) {
      modalRef.current.focus();
    }
  }, [hasSessionTimedOut]);

  // skip rendering for anything not VP or not in AuthTimeFrame
  if (
    !isVirginPulse ||
    !hasSessionTimedOut ||
    isRegistrationComplete ||
    isEmailVerificationPage
  ) {
    return null;
  }

  return (
    <PortalModal onTop={true}>
      <div className="obstacle-modal vp-modal session-timeout-modal">
        <div className="inner-modal wrapper" ref={modalRef}>
          <Image />
          <h3>{str(I18N_STR.vpTimeoutModalTitle)}</h3>
          <p>{str(I18N_STR.vpTimeoutModalCopy)}</p>
          <div className="form-footer justify-left">
            <button
              className="cs-button"
              onClick={() => {
                track(`${WEBREG_PREFIX}:vpp-modal-session-timeout`);
                window.location.replace(
                  `${userManagementRootEndpoint}/v1/login/saml?provider=${virginPulse.authProvider}`
                );
              }}
            >
              {str(I18N_STR.vpTimeoutModalButton)}
            </button>
          </div>
        </div>
      </div>
    </PortalModal>
  );
}
