// @flow

import AWS from 'aws-sdk/global';
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';
import { UserNotFoundError, UserAlreadyVerifiedError } from './errors';
import { aws, userManagementRootEndpoint } from '../../config';
import { store } from '../../state/store';
import { ACTION_TYPES } from '../../state/actions';

window.AWS = false;
const { userPoolId, clientId, identityPoolId } = aws;
AWS.config.region = 'us-west-2';

const userPool = new CognitoUserPool({
  UserPoolId: userPoolId,
  ClientId: clientId,
  Paranoia: 7,
});

// Registration
function generateAttribute(name, value): CognitoUserAttribute {
  return new CognitoUserAttribute({
    Name: name,
    Value: value,
  });
}

export function resendConfirmation(userEmail: string): Promise<any> {
  return fetch(
    `${userManagementRootEndpoint}/v3/registration/resend-confirmation-email`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: userEmail,
      }),
    }
  ).then(res => {
    switch (res.status) {
      case 200:
        return;
      case 404:
        throw new UserNotFoundError(userEmail);
      case 409:
        throw new UserAlreadyVerifiedError(userEmail);
      default:
        throw new Error();
    }
  });
}

// Auth
function getUserAttributesFromCognitoObject(object): string {
  if (!object) {
    return;
  }
  return JSON.parse(atob(object.getIdToken().getJwtToken().split('.')[1]));
}

function checkExpiration(): Promise<any> {
  return new Promise((resolve, reject) => {
    if (!AWS.config.credentials.needsRefresh()) {
      resolve();
    }
    userPool.getCurrentUser().getSession((err, session) => {
      if (err || !session || !session.isValid()) {
        reject('Session Timed Out');
      } else {
        resolve();
      }
    });
  });
}

function getCognitoUser(email: string): CognitoUser {
  return new CognitoUser({
    Username: email,
    Pool: userPool,
  });
}

function setCredentials(token: string) {
  let credentials = {
    IdentityPoolId: identityPoolId,
    Logins: {},
  };
  credentials.Logins[`cognito-idp.us-west-2.amazonaws.com/${userPoolId}`] =
    token;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials(credentials);
  AWS.config.credentials.get();
  setCredentialsLocally(token);
}

function setCredentialsLocally(token) {
  let user = null;
  if (token) {
    const base64Url = token.split('.')[1];
    user = JSON.parse(window.atob(base64Url));
  }
  store.dispatch({ type: ACTION_TYPES.SET_LOGGED_IN_USER, payload: user });
}

export function signOut() {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.signOut();
    store.dispatch({ type: ACTION_TYPES.SET_LOGGED_IN_USER, payload: null });
  }
}

export function login(email: string, password: string): Promise<any> {
  return new Promise((resolve, reject) => {
    const cognitoUser = getCognitoUser(email);
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (res, userConfirmationNecessary) => {
        const completeLogin = () => {
          setCredentials(res.getIdToken().getJwtToken());
          resolve({
            cognitoObject: cognitoUser,
            info: getUserAttributesFromCognitoObject(res),
          });
        };

        if (userConfirmationNecessary) {
          cognitoUser.setDeviceStatusRemembered({
            onSuccess: completeLogin,
            onFailure: reject,
          });
        } else {
          completeLogin();
        }
      },
      onFailure: reject,
    });
  });
}

export function checkSessionWithPromise() {
  return Promise((resolve, reject) => {
    let cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      reject();
    }
    return cognitoUser.getSession((err, session) => {
      if (err || !session || !session.isValid()) {
        reject(false);
      }
      setCredentials(session?.getIdToken().getJwtToken());
      resolve({
        cognitoObject: cognitoUser,
        info: getUserAttributesFromCognitoObject(session),
      });
    });
  });
}

export function getUserFromSession(): Promise<any> {
  return new Promise((resolve, reject) => {
    let cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      reject(false);
    }
    return cognitoUser.getSession((err, session) => {
      if (err || !session || !session.isValid()) {
        reject(false);
      }
      // Our session is valid, so we can set our AWS credentials accordingly
      setCredentials(session.getIdToken().getJwtToken());
      resolve({
        cognitoObject: cognitoUser,
        info: getUserAttributesFromCognitoObject(session),
      });
    });
  });
}

export function changePassword(
  user: CognitoUser,
  oldPassword: string,
  newPassword: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    checkExpiration().then(
      () => {
        user.cognitoObject.changePassword(
          oldPassword,
          newPassword,
          (err, res) => {
            if (err) {
              reject(err);
            }
            user.cognitoObject.refreshSession(
              user.cognitoObject.signInUserSession.refreshToken,
              (err, res) => {
                if (err) {
                  reject(err);
                } else {
                  setCredentials(res.getIdToken().getJwtToken());
                  resolve();
                }
              }
            );
          }
        );
      },
      err => {
        reject(err);
      }
    );
  });
}

export function updateProfile(user: CognitoUser, updates: {}): Promise<any> {
  return new Promise((resolve, reject) => {
    checkExpiration().then(
      () => {
        const attributes = [];
        for (let attribute of Object.keys(updates)) {
          attributes.push(generateAttribute(attribute, updates[attribute]));
        }
        user.cognitoObject.updateAttributes(attributes, (err, res) => {
          if (err) {
            reject(err);
          }
          user.cognitoObject.refreshSession(
            user.cognitoObject.signInUserSession.refreshToken,
            (err, res) => {
              if (err) {
                reject(err);
              } else {
                setCredentials(res.getIdToken().getJwtToken());
                resolve();
              }
            }
          );
        });
      },
      err => {
        reject(err);
      }
    );
  });
}

// Param transformation
export function toRegularTelephone(telephone: string): string {
  if (!telephone) {
    return '';
  }
  return `${telephone.slice(2, 5)} ${telephone.slice(5, 8)}-${telephone.slice(
    8
  )}`;
}

export function toCognitoPhone(phone: string): string {
  phone = phone.replace(/\(|\)|-|\s|\+/g, '');
  return `+${phone.length === 11 ? '' : '1'}${phone}`;
}
