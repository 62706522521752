import React from 'react';
import { useI18n } from '../i18n/utils';
import { I18N_STR } from '../i18n/strings';

export default function LabelAndError({
  placeHolder,
  required,
  isValid,
  errorString,
}) {
  const { str, reactNodeToString } = useI18n();

  return (
    <div className="label-and-error">
      <span className="label">{placeHolder}</span>

      {required && <span className="required" />}

      <span
        aria-live="assertive"
        aria-atomic="true"
        role="alert"
        id={`${reactNodeToString(placeHolder)}-alert`}
        className="input-error-message"
      >
        {isValid === false ? errorString || str(I18N_STR.MISSING) : null}
      </span>
    </div>
  );
}
