import React, { useLayoutEffect, useRef, useState } from 'react';
import classnames from 'classnames';

export function useIsPinned(ref) {
  const [isPinned, setPinned] = useState();

  useLayoutEffect(() => {
    let observer;
    if (ref?.current) {
      observer = new IntersectionObserver(
        ([e]) => {
          setPinned(() => e.intersectionRatio < 1);
        },
        {
          threshold: [1],
        }
      );
      observer.observe(ref.current);
    }

    return () => observer && observer.disconnect();
  }, [ref]);

  return isPinned;
}

export default function FormFooter(props) {
  const guideRef = useRef();
  const isPinned = useIsPinned(guideRef);
  const cssClassNames = props.classNames || '';
  return (
    <>
      <div
        className={classnames('form-footer', 'pin-to-bottom', cssClassNames, {
          'is-pinned': isPinned,
        })}
      >
        {props.children}
      </div>
      <div className="bottom-pin-ref" ref={guideRef} />
    </>
  );
}
