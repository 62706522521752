import { store } from '../../../../state/store';
import { toCognitoPhone } from '../../../utils/AWSCognitoHelper';
import { getLanguageCode, getTimezoneName } from './utils';
import { LANGUAGES } from '../../../i18n/utils';

export function createUserDataFromSelections(
  userCameFromEligibilityApproval = false
) {
  const state = store.getState();
  const {
    userRegistrationData: {
      email,
      password,
      activation_code,
      streetAddress1: streetAddressLine1,
      streetAddress2: streetAddressLine2,
      city,
      state: addressState,
      zip: postalCode,
    },
    userDataForEligibilityReview,
    eligibilityCaseId,
    accessCodeConfig,
  } = state;
  const userRegistrationData = getUserRegistrationData(
    userCameFromEligibilityApproval,
    state
  );
  const userPersonalisationData = getUserPersonalisationData(state);
  const userEligibilityData =
    Object.keys(userDataForEligibilityReview).length > 0
      ? userDataForEligibilityReview
      : null;

  return {
    email: email.toLowerCase().trim(),
    password: password,
    attributes: userRegistrationData,
    userData: userPersonalisationData,
    accessCode: accessCodeConfig.accessCode,
    accessCodeConfig: accessCodeConfig,
    userEligibilityData,
    eligibilityCaseId: eligibilityCaseId || null,
    activationCode: activation_code,
    ...(streetAddressLine1 && {
      shippingAddress: {
        streetAddressLine1: streetAddressLine1.trim(),
        streetAddressLine2: streetAddressLine2.trim(),
        city: city.trim(),
        state: addressState.trim(),
        postalCode: postalCode.trim(),
        country: 'United States',
      },
    }),
  };
}

function getUserRegistrationData(
  userCameFromEligibilityApproval,
  { userRegistrationData, addressForClaims, accessCodeConfig }
) {
  const cognitoData = {};
  const now = new Date();

  cognitoData.address = toCognitoAddress(
    userRegistrationData,
    addressForClaims
  );
  cognitoData.phone_number = toCognitoPhone(
    userRegistrationData.phone_number
  ).trim();
  cognitoData.birthdate =
    userRegistrationData.dateOfBirth || userRegistrationData.birthdate;
  cognitoData.email = userRegistrationData.email.toLowerCase().trim();
  cognitoData.gender = userRegistrationData.gender;
  cognitoData.given_name = userRegistrationData.given_name.trim();
  cognitoData.family_name = userRegistrationData.family_name.trim();
  cognitoData['custom:access_code'] = accessCodeConfig.accessCode.trim();
  cognitoData['custom:consent_signed_at'] = now;
  cognitoData['custom:timezone'] = getTimezoneName(now);
  cognitoData['custom:docs'] = JSON.stringify(accessCodeConfig.docMetaData);
  cognitoData['custom:account_type'] = accessCodeConfig.orgType;
  cognitoData['custom:sms_optin'] = userRegistrationData.smsOptIn.toString(); //cognito takes number|string
  cognitoData['custom:utm_source'] = userRegistrationData.utm_source;
  cognitoData['custom:utm_campaign'] = userRegistrationData.utm_campaign;
  cognitoData.locale = getLanguageCode();

  const attributes = Object.keys(cognitoData).map(key => {
    return { Name: key, Value: cognitoData[key] };
  });

  return attributes;
}

function toCognitoAddress(userRegistrationData, addressForClaims) {
  const prefix = addressForClaims.fieldPrefix;
  if (addressForClaims[`${prefix}streetAddress1`]) {
    return formatAddress(
      addressForClaims[`${prefix}streetAddress1`],
      addressForClaims?.[`${prefix}streetAddress2`],
      addressForClaims[`${prefix}city`],
      addressForClaims[`${prefix}state`],
      addressForClaims[`${prefix}zip`]
    );
  } else if (userRegistrationData.streetAddress1) {
    return formatAddress(
      userRegistrationData.streetAddress1,
      userRegistrationData?.streetAddress2,
      userRegistrationData.city,
      userRegistrationData.state,
      userRegistrationData.zip
    );
  }
  return '';
}

function formatAddress(_streetAddress1, _streetAddress2, _city, _state, _zip) {
  const addressLine1 = escapeCommas(_streetAddress1.trim());
  const addressLine2 =
    _streetAddress2.trim() !== ''
      ? `\\n${escapeCommas(_streetAddress2.trim())}`
      : '';
  const city = escapeCommas(_city);
  return (
    `${addressLine1}${addressLine2},${city},` +
    `${_state.trim()},${_zip.trim()},United States`
  );
}

function escapeCommas(s) {
  if (s) {
    return s.trim().replace(/,/gim, '\\,');
  }
}

export function getUserPersonalisationData({ userSelections }) {
  const userData = {};

  userData.nicotineAddictionTypes = userSelections?.usage
    ?.filter(item => item.selected === true)
    .map(item => item.key);

  return userData;
}
