import { store } from '../../../state/store';
import { ADDICTION_TYPES } from '../../constants';

export const getPrincipalAddictionType = () => {
  if (!store) {
    return ADDICTION_TYPES.NONE;
  }

  const state = store.getState();
  const types = Boolean(state.userSelections.usage)
    ? state.userSelections.usage.reduce((accumulator, item) => {
        if (item.selected) {
          accumulator.push(item.key);
        }
        return accumulator;
      }, [])
    : [];

  if (types.indexOf(ADDICTION_TYPES.SMOKING) > -1) {
    return ADDICTION_TYPES.SMOKING;
  }
  if (types.indexOf(ADDICTION_TYPES.VAPING) > -1) {
    return ADDICTION_TYPES.VAPING;
  }
  if (types.indexOf(ADDICTION_TYPES.CHEWING) > -1) {
    return ADDICTION_TYPES.CHEWING;
  }
  if (types.indexOf(ADDICTION_TYPES.OTHER) > -1) {
    return ADDICTION_TYPES.OTHER;
  }

  return ADDICTION_TYPES.NONE;
};
