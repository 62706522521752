import {
  accessCodesRootEndpoint,
  docsEndpoint,
  userManagementRootEndpoint,
} from '../../../../config';
import { ACTION_TYPES } from '../../../../state/actions';
import { store } from '../../../../state/store';
import { trackRegistrationMilestone } from '../../../utils/googleAnalyticsHelper';
import { validateEmail } from '../../../utils/validation';
import { ORG_TYPES, USER_EMAIL_STATUS_RES, MESSAGE } from '../../../constants';
import { RESULTS } from '../../../strings';
import {
  getMixpanelDistinctId,
  registerAndAliasUserInMixpanel,
  trackSignupErrorInMixpanel,
} from '../../../utils/mixpanelHelper';
import { getUrlParam } from '../../../utils/queryVarsUtils';
import { getPrincipalAddictionType } from '../registration-utils';
import { createUserDataFromSelections } from './userDataUtils';
import type { DateObject } from '../../../utils/validation';
import {
  resendConfirmation,
  toCognitoPhone,
} from '../../../utils/AWSCognitoHelper';
import { sendDownloadAppSms } from '../common-components/sms/send-download-app-sms';
import {
  BRAZE_EVENTS,
  changeBrazeUser,
  logBrazeEvent,
} from '../../../utils/brazeHelper';
import { fetcher } from '../../../utils/fetcher';
import { LANGUAGES, str } from '../../../i18n/utils';
import { I18N_STR } from '../../../i18n/strings';

export function getTimezoneName(time: Date): string {
  /* Creates JSON custom attribute `timezone` with `name` (all browsers besides IE11)
   and `tzOffsetFromUTC` which is transformed to be more intuitive, west of UTC longitude would have
   negative values and east of the UTC longitude would have positive values
   more in https://stackoverflow.com/questions/1091372/getting-the-clients-timezone-in-javascript#34602679
   */
  //$FlowFixMe
  let timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (typeof timezoneName === 'undefined') {
    timezoneName = 'unknown';
  }
  return JSON.stringify({
    name: timezoneName,
    tzOffsetFromUTC: time.getTimezoneOffset() * -1,
  });
}

export function formatDate(d: Date): string {
  if (d instanceof Date && !isNaN(d)) {
    const [yyyy, mm, dd] = d.toISOString().substr(0, 10).split('-');
    return `${mm}/${dd}/${yyyy}`;
  }
  return '';
}

const SPREADSHEET_APP_URL: string =
  'https://script.google.com/macros/s/AKfycbxEO87YDAgTC7teUZqAmtZbJ9amiohacUwlETz6m-uPF8yFo7w/exec';
export type OrgType = 'BUSINESS' | 'CLINICAL';
export type AccessCodeResponse = {
  status: 'success' | 'fail',
  data: {
    status?: 'expired' | 'full' | 'unavailable' | 'available',
    orgType?: null | OrgType,
    accessCode: string,
    raw?: Array<mixed>,
    docMetaData?: any,
    pricing: Pricing,
  },
};

//$FlowFixMe
export function validateAccessCode(code: string): Promise<AccessCodeResponse> {
  return fetcher(`${accessCodesRootEndpoint}/v1/access-codes/${code}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(res => res.json())
    .catch(err => {
      console.log(err);
    });
}

type DocsVersionData = {
  orgType: string,
  dict: {
    CLINICAL_CONSENT: {
      id: string,
    },
  },
};

export function getDocsVersion(data: DocsVersionData) {
  if (data.orgType === ORG_TYPES.CLINICAL) {
    fetch(`${docsEndpoint}/${data.dict.CLINICAL_CONSENT.id}/html`).then(res =>
      res.text()
    );
  }
}

const emailCheckCache = new Map();

export function checkUserStatus(email: string): Promise<any> {
  if (!validateEmail(email)) {
    return USER_EMAIL_STATUS_RES.INVALID_EMAIL;
  }

  const emailFromCache = emailCheckCache.get(email);
  const emailCheck = emailFromCache
    ? emailFromCache
    : fetch(`${userManagementRootEndpoint}/v3/users/${email}/exists`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

  if (!emailFromCache) {
    emailCheckCache.set(email, emailCheck);
  }

  return emailCheck
    .then(res => res.clone().json())
    .then(response => {
      const { status, data } = response;
      return status === 'success' ? data : null;
    })
    .then(userStatus => {
      if (!userStatus) {
        saveEmailExistsCheck(email, USER_EMAIL_STATUS_RES.NOT_FOUND);
        return Promise.resolve(USER_EMAIL_STATUS_RES.NOT_FOUND);
      }
      if (userStatus.verified === true && userStatus.initialized === true) {
        saveEmailExistsCheck(email, USER_EMAIL_STATUS_RES.EXISTS);
        return Promise.resolve(USER_EMAIL_STATUS_RES.EXISTS);
      }
      if (userStatus.verified === false) {
        saveEmailExistsCheck(email, USER_EMAIL_STATUS_RES.NOT_VERIFIED);
        return Promise.resolve(USER_EMAIL_STATUS_RES.NOT_VERIFIED);
      }
      if (userStatus.initialized === false) {
        saveEmailExistsCheck(email, USER_EMAIL_STATUS_RES.NOT_INITIALIZED);
        return Promise.resolve(USER_EMAIL_STATUS_RES.NOT_INITIALIZED);
      }
      saveEmailExistsCheck(email, USER_EMAIL_STATUS_RES.UNDEFINED);
      return Promise.resolve(USER_EMAIL_STATUS_RES.UNDEFINED);
    });
}

function saveEmailExistsCheck(email, status) {
  store.dispatch({
    type: ACTION_TYPES.SAVE_EMAIL_EXISTS_CHECK,
    payload: { email, status },
  });
}

export function handleOnSubmitEmailCheck(email, dispatch) {
  return checkUserStatus(email).then(res => {
    if (res === USER_EMAIL_STATUS_RES.NOT_VERIFIED) {
      resendConfirmation(email.toLowerCase());
      dispatch({
        type: ACTION_TYPES.SET_MESSAGE,
        payload: {
          message: str(I18N_STR.USER_NOT_CONFIRMED),
          type: MESSAGE.TYPE.INFO,
        },
      });
      return false;
    }
    if (res === USER_EMAIL_STATUS_RES.NOT_INITIALIZED) {
      dispatch({
        type: ACTION_TYPES.SET_MESSAGE,
        payload: {
          message: str(I18N_STR.USER_NOT_INITIALIZED),
          type: MESSAGE.TYPE.WARN,
        },
      });
      return false;
    }
    return true;
  });
}

// Product Request: Allow user click next to get an error message if needed
export async function checkEmailCriteriaMet(email: string): Promise<any> {
  const userStatus = await checkUserStatus(email);
  const criteriaMet =
    userStatus === USER_EMAIL_STATUS_RES.NOT_FOUND ||
    userStatus === USER_EMAIL_STATUS_RES.NOT_VERIFIED ||
    userStatus === USER_EMAIL_STATUS_RES.NOT_INITIALIZED;
  return criteriaMet;
}

export function submitToShortlist(
  firstName: string,
  lastName: string,
  email: string,
  accessCode: string,
  intentCount: number = 0,
  maxTries: number = 2
): Promise<any> {
  const numTries = intentCount + 1;
  if (numTries < maxTries) {
    const address = `${SPREADSHEET_APP_URL}?email=${email}&firstName=${firstName}&lastName=${lastName}&accessCode=${accessCode}`;
    return fetch(address, { method: 'get' })
      .then(res => res.json())
      .then(res => {
        if (res.result !== RESULTS.SUCCESS) {
          submitToShortlist(
            firstName,
            lastName,
            email,
            accessCode,
            numTries,
            2
          );
        }
        return res;
      })
      .catch(err => {
        submitToShortlist(firstName, lastName, email, accessCode, numTries, 2);
      });
  } else {
    return Promise.reject();
  }
}

export function isAccessCodeSuccess(res: AccessCodeResponse): boolean {
  const states = ['expired', 'full', 'unavailable', 'available'];
  return res.status === 'success' && states.indexOf(res.data.status) > -1;
}

export function getSignupErrorMessage(err: Error): string {
  let errorMessage = Boolean(err.message)
    ? `${str(I18N_STR.SOMETHING_WRONG_CONTACT_SUPPORT)}: (${err.message})`
    : str(I18N_STR.SOMETHING_WRONG_CONTACT_SUPPORT);
  if (err.code === 'UsernameExistsException') {
    errorMessage = str(I18N_STR.USER_NAME_EXISTS_GOTO_LOGIN);
  }
  return errorMessage;
}

export function dateFromDateObj(dateObject: DateObject) {
  return new Date(dateObject.year, dateObject.month, dateObject.day);
}

export function createAccount() {
  const {
    userRegistrationData: {
      given_name: givenName,
      family_name: familyName,
      smsOptIn,
      phone_number: phoneNumber,
      gender,
      birthdate: birthDate,
    },
    eligibleUserDataFromURLParams: {
      userCameFromEligibilityApproval,
      verificationCode,
    },
    isVirginPulse,
    urlParams,
    brazeDeviceId,
  } = store.getState();

  const {
    email,
    password,
    accessCodeConfig: { accessCode },
    attributes,
    userData,
    shippingAddress,
    userEligibilityData,
    eligibilityCaseId,
  } = createUserDataFromSelections(userCameFromEligibilityApproval);

  let requestBody;
  let endpoint;
  let endPointNeedsCreds = false;

  if (isVirginPulse) {
    endpoint = `${userManagementRootEndpoint}/v3/registration/register/vp`;
    endPointNeedsCreds = true;
    // v3 has other requestBody
    requestBody = {
      username: email,
      password: password,
      accessCode,
      givenName,
      familyName,
      gender,
      birthDate,
      phoneNumber: toCognitoPhone(phoneNumber),
      smsPreferences: {
        marketing: smsOptIn,
        personalized: smsOptIn,
      },
      nicotineTypes: userData.nicotineAddictionTypes,
      mpDistinctId: getMixpanelDistinctId(),
      brazeDeviceId,
      ...(shippingAddress && { shippingAddress }),
      locale: getLanguageCode(),
    };
  } else {
    endpoint = `${userManagementRootEndpoint}/v2/onboarding/sign-up/access-code`;
    requestBody = {
      username: email,
      password: password,
      accessCode,
      attributes: attributes,
      userData: userData,
      mpDistinctId: getMixpanelDistinctId(),
      brazeDeviceId,
      userEligibilityData,
      eligibilityCaseId,
      smsOptInMarketing: smsOptIn,
      smsOptInPersonalized: smsOptIn,
      sku: getSku(),
      ...(shippingAddress && { shippingAddress }),
      ...(userCameFromEligibilityApproval && { verificationCode }),
      locale: getLanguageCode(),
    };
  }

  return fetcher(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    ...(endPointNeedsCreds && { credentials: 'include' }),
    body: JSON.stringify(requestBody),
  })
    .then(res => res.json())
    .then(res => {
      const userId = res.data.UserSub;
      store.dispatch({ type: ACTION_TYPES.SET_REGISTRATION_COMPLETE });

      registerAndAliasUserInMixpanel(userId, requestBody);

      logBrazeEvent(BRAZE_EVENTS.REGISTERED);
      changeBrazeUser(userId);

      trackRegistrationMilestone(
        accessCode,
        urlParams?.utm_source,
        urlParams?.utm_campaign
      );

      if (smsOptIn) {
        sendDownloadAppSms(phoneNumber, false);
      }
      return res;
    })
    .then(res => {
      return res;
    })
    .catch(err => {
      const errMessage = getSignupErrorMessage(err);
      trackSignupErrorInMixpanel({ ...requestBody, password: '' }, errMessage);
      return err;
    });
}

export function getSku() {
  try {
    const state = store.getState();
    const type = getPrincipalAddictionType();
    if (
      Boolean(state.accessCodeConfig) &&
      Boolean(state.accessCodeConfig.availableNicotineAddictionTypes) &&
      Boolean(state.accessCodeConfig.availableNicotineAddictionTypes[type])
    ) {
      const skuId =
        state.accessCodeConfig.availableNicotineAddictionTypes[type][0] ||
        state.skuFromUrl; // @TODO https://carrotsense.atlassian.net/browse/LAUN-328
      const { id, name, pricing } = state.accessCodeConfig.availableSkus[skuId];
      return {
        id,
        name,
        price: Boolean(pricing.discountPrice)
          ? pricing.discountPrice
          : pricing.retailPrice,
        pricing: state.accessCodeConfig.availableSkus[skuId].pricing,
      };
    } else if (
      Boolean(state.accessCodeConfig) &&
      Boolean(state.accessCodeConfig.availableNicotineAddictionTypes) &&
      state.skuFromUrl
    ) {
      const skuId = state.skuFromUrl;
      const { id, name, pricing } = state.accessCodeConfig.availableSkus[skuId];
      return {
        id,
        name,
        price: pricing.discountPrice || pricing.retailPrice,
        pricing: state.accessCodeConfig.availableSkus[skuId].pricing,
      };
    } else {
      return {};
    }
  } catch (e) {
    console.log(e);
    return {};
  }
}

export function getLanguageCode() {
  const { locale } = store.getState();
  return locale && locale.languageCode === LANGUAGES.ES ? 'es-419' : 'en-US';
}

export function getEligibilityParamsFromRedirect() {
  return {
    userCameFromEligibilityApproval: Boolean(getUrlParam('eligibility')),
    email: getUrlParam('email'),
    verificationCode: getUrlParam('verification_code'),
    accessCode: getUrlParam('access_code'),
    nicotineAddictionType: getUrlParam('type'),
    utm_source: getUrlParam('utm_source'),
    utm_campaign: getUrlParam('utm_campaign'),
  };
}

export function isFeatureEnabled(skuFeature) {
  return skuFeature.isEnabled === true;
}
