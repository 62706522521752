import { accessCodesRootEndpoint } from '../../../../config';
import { ACTION_TYPES } from '../../../../state/actions';
import { setCookie } from '../../../utils/cookiesHelper';
import { ACCESS_CODE_COOKIE } from '../../../constants';
import { store } from '../../../../state/store';
import { validateAccessCode } from '../account-creation/utils';
import { str } from '../../../i18n/utils';
import { I18N_STR } from '../../../i18n/strings';

export function getOrgConfig(alias) {
  if (alias) {
    return fetch(
      `${accessCodesRootEndpoint}/v1/organizations/${alias}/web-template-config`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then(res => res.json())
      .catch(err => {
        console.log(err);
      });
  }
}

export function setAccessCodeCookieFromUrl(code) {
  if (code !== '') {
    setCookie(ACCESS_CODE_COOKIE, code);
  }
}

export function getAccessCodeConfig(code) {
  if (!code) {
    return Promise.reject();
  }
  return validateAccessCode(code).catch(err => {
    setErrorMessage(str(I18N_STR.accessCodeValidationError));
    return err;
  });
}

export function getAndSetAccessCodeConfig(code) {
  return getAccessCodeConfig(code).then(res => {
    if (res.status === 'success') {
      store.dispatch({
        type: ACTION_TYPES.SET_ACCESS_CODE_CONFIG,
        payload: res,
      });
    }
    return res;
  });
}

export function setErrorMessage(err) {
  store.dispatch({ type: ACTION_TYPES.SET_MESSAGE, payload: { message: err } });
}
