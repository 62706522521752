import { load } from '@fingerprintjs/botd';
import { store } from '../../state/store';
import { ACTION_TYPES } from '../../state/actions';

export default function detectBots() {
  const botdPromise = load();
  botdPromise
    .then(botd => botd.detect())
    .then(result => {
      const isSuspectedBot = result.bot;
      if (isSuspectedBot) {
        store.dispatch({
          type: ACTION_TYPES.SET_IS_SUSPECTED_BOT,
          payload: true,
        });
      }
    })
    .catch(() => {});
}

detectBots();
