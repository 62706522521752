export const ACTION_TYPES = {
  FETCHING: 'FETCHING',
  DONE_FETCHING: 'DONE_FETCHING',
  ERROR_FETCHING: 'ERROR_FETCHING',
  SET_MESSAGE: 'SET_MESSAGE',
  CLEAR_MESSAGE: 'CLEAR_MESSAGE',
  SET_ACCESS_CODE_CONFIG: 'SET_ACCESS_CODE_CONFIG',
  SET_ORG_CONFIG: 'SET_ORG_CONFIG',
  SELECT_USAGE_TYPE: 'SELECT_USAGE_TYPE',
  SELECT_NONE_USAGE_TYPE: 'SELECT_NONE_USAGE_TYPE',
  RESET_USAGE_TYPE: 'RESET_USAGE_TYPE',
  SELECT_DOB: 'SELECT_DOB',
  SELECT_ONE_PERSONALIZATION_ITEM: 'SELECT_ONE_PERSONALIZATION_ITEM',
  SAVE_USER_DATA_FOR_ELIGIBILITY_REVIEW:
    'SAVE_USER_DATA_FOR_ELIGIBILITY_REVIEW',
  SET_USER_REGISTRATION_FIELD: 'SET_USER_REGISTRATION_FIELD',
  SET_USAGE_TYPE_FROM_ELIGIBILITY_APPROVAL:
    'SET_USAGE_TYPE_FROM_ELIGIBILITY_APPROVAL',
  SET_ELIGIBILITY_CASE_ID: 'SET_ELIGIBILITY_CASE_ID',
  SET_ELIGIBLE_USER_DATA_FROM_URL_PARAMS:
    'SET_ELIGIBLE_USER_DATA_FROM_URL_PARAMS',
  SET_INSURANCE_TYPE: 'SET_INSURANCE_TYPE',
  UPDATE_HEADER_TITLE: 'UPDATE_HEADER_TITLE',
  SET_FLOW_TYPE: 'SET_FLOW_TYPE',
  REQUEST_SMOKING_HISTORY: 'REQUEST_SMOKING_HISTORY',
  SET_USER_SHIPPING_DATA_FIELD: 'SET_USER_SHIPPING_DATA_FIELD',
  SET_SCREEN_READER_PAGE_TITLE: 'SET_SCREEN_READER_PAGE_TITLE',
  SET_REGISTRATION_COMPLETE: 'SET_REGISTRATION_COMPLETE',
  SET_REGISTERED_USER_ID: 'SET_REGISTERED_USER_ID',
  NRT_SET_DEVICE: 'NRT_SET_DEVICE',
  NRT_SET_USER: 'NRT_SET_USER',
  NRT_SET_INITIAL_DATA: 'NRT_SET_INITIAL_DATA',
  NRT_SET_HEALTH_CONDITION: 'NRT_SET_HEALTH_CONDITION',
  NRT_SET_OPTION_SELECTION: 'NRT_SET_OPTION_SELECTION',
  NRT_SET_DOSAGE_SELECTION: 'NRT_SET_DOSAGE_SELECTION',
  NRT_SET_FLAVOR_SELECTION: 'NRT_SET_FLAVOR_SELECTION',
  NRT_SET_BUNDLE_FLAVOR_SELECTION: 'NRT_SET_BUNDLE_FLAVOR_SELECTION',
  NRT_SET_COMPLETED_ORDER: 'NRT_SET_COMPLETED_ORDER',
  USER_CAME_FROM_FIND_EMPLOYER: 'USER_CAME_FROM_FIND_EMPLOYER',
  SET_LOGGED_IN_USER: 'SET_LOGGED_IN_USER',
  SET_USER_PROFILE: 'SET_USER_PROFILE',
  SET_USER_MEMBERSHIP: 'SET_USER_MEMBERSHIP',
  ELIGIBILITY_SUBMITTED: 'ELIGIBILITY_SUBMITTED',
  SAVE_EMAIL_EXISTS_CHECK: 'SAVE_EMAIL_EXISTS_CHECK',
  SET_IS_VIRGIN_PULSE: 'SET_IS_VIRGIN_PULSE',
  SET_BRAZE_DEVICE_ID: 'SET_BRAZE_DEVICE_ID',
  POPULATE_BSP_OFFER_USAGE_TYPES: 'POPULATE_BSP_OFFER_USAGE_TYPES',
  SELECT_BSP_OFFER_USAGE_TYPE: 'SELECT_BSP_OFFER_USAGE_TYPE',
  SET_BSP_OFFER_IS_RETAIL: 'SET_BSP_OFFER_IS_RETAIL',
  SET_BSP_OFFER_PRODUCTS: 'SET_BSP_OFFER_PRODUCTS',
  SELECT_BSP_OFFER_PLAN: 'SELECT_BSP_OFFER_PLAN',
  SET_BSP_OFFER_DATA: 'SET_BSP_OFFER_DATA',
  SET_BSP_OFFER_PROMO_CODE: 'SET_BSP_OFFER_PROMO_CODE',
  SET_BSP_OFFER_STRIPE_CHECKOUT_SESSION:
    'SET_BSP_OFFER_STRIPE_CHECKOUT_SESSION',
  TOGGLE_BSP_OFFER_HAS_PROMO_CODE: 'TOGGLE_BSP_OFFER_HAS_PROMO_CODE',
  TOGGLE_BSP_OFFER_IS_LEGAL_AGE: 'TOGGLE_BSP_OFFER_IS_LEGAL_AGE',
  SET_GOT_MEMBER_ID_FROM_URL_PARAM: 'SET_GOT_MEMBER_ID_FROM_URL_PARAM',
  SET_GOT_GROUP_NUMBER_FROM_URL_PARAM: 'SET_GOT_GROUP_NUMBER_FROM_URL_PARAM',
  SET_GOT_ZIP_CODE_FROM_URL_PARAM: 'SET_GOT_ZIP_CODE_FROM_URL_PARAM',
  SET_GOT_DATE_OF_BIRTH_FROM_URL_PARAM: 'SET_GOT_DATE_OF_BIRTH_FROM_URL_PARAM',
  SET_IS_SUSPECTED_BOT: 'SET_IS_SUSPECTED_BOT',
};

export function setUserRegistrationField(name, value) {
  return {
    type: ACTION_TYPES.SET_USER_REGISTRATION_FIELD,
    payload: { name, value },
  };
}
