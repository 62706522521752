import React from 'react';
import { useLocation } from 'react-router-dom';
import { track, WEBREG_PREFIX } from '../../../utils/mixpanelHelper';

export function EmailSupportLink({ className }) {
  const location = useLocation();
  const address = 'support@pivot.co';
  return (
    <a
      href={`mailto:${address}`}
      onClick={() => {
        track(`${WEBREG_PREFIX}:email-support-link`, {
          'Page Path': location.pathname,
        });
      }}
      className={className}
    >
      {address}
    </a>
  );
}
