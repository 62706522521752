// @flow

import * as React from 'react';
import { STATES } from '../../../constants';

export const StatesOptionsMapper = ({
  excludedStates = [],
}): Array<React.Element<'option'>> => {
  const options = [<option key="-1" value="-1" />];

  Object.keys(STATES).forEach(state => {
    if (excludedStates.includes(state) === false) {
      options.push(
        <option key={state} value={state}>
          {STATES[state]}
        </option>
      );
    }
  });

  return options;
};
