import React from 'react';
import { useI18n } from '../../../i18n/utils';
import { I18N_STR } from '../../../i18n/strings';

export default function PrivacyPolicy() {
  const { str } = useI18n();

  return (
    <div className="privacy-policy">
      <p>{str(I18N_STR.bandPrivacyPolicy)}</p>
    </div>
  );
}
