import React from 'react';
import classnames from 'classnames';
import useNicTypeValues from '../common-utils/useNicTypeValues';
import { CLASS_NAMES_BY_NIC_TYPE } from '../../../constants';
import styles from '../../../../css/UnifiedLanding.module.scss';
import { useI18n } from '../../../i18n/utils';
import { I18N_STR } from '../../../i18n/strings';

export default function NicTypeSwitcher() {
  const { current, otherNicTypes, trackAndNavigateToOtherNicType } =
    useNicTypeValues();
  const { str } = useI18n();

  return (
    <div className={styles.nicTypeSwitcher}>
      <div className={styles.nicTypeSwitcherWrapper}>
        <span>{current.title}</span>
        <div className={styles.nicTypeSwitcherButtons}>
          {otherNicTypes.map(({ key, linkLabel, url }) => (
            <button
              key={key}
              className={classnames(
                styles.nicTypeSwitcherButton,
                styles[CLASS_NAMES_BY_NIC_TYPE[key]]
              )}
              onClick={() => trackAndNavigateToOtherNicType(key, url)}
            >
              <span>{str(I18N_STR.goTo)}</span>
              {linkLabel}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
