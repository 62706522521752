import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import FooterLogo from '../../media/logo-pivot-circle.svg';
import { CURRENT_YEAR, DEVICE_TYPE } from '../constants';
import HelpScoutWidget, { initHelpScoutWidget } from '../utils/helpScoutWidget';
import { track, WEBREG_PREFIX } from '../utils/mixpanelHelper';
import { PAGES } from '../page-urls';
import { useI18n } from '../i18n/utils';
import { I18N_STR } from '../i18n/strings';

export default function Footer() {
  const location = useLocation();
  const isNrt = location.pathname.includes('nrt');
  const urlParams = useSelector(state => state.urlParams);
  const device = useSelector(state => state.device);
  const isDesktop = device === DEVICE_TYPE.DESKTOP;
  const isMobile = !isDesktop;
  const { str } = useI18n();

  if ((isNrt && isMobile) || Boolean(urlParams?.device)) {
    return null;
  }

  return (
    <React.Fragment>
      <footer className="brand-footer">
        <nav>
          <a
            href="https://pivot.co"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-logo"
          >
            <img
              src={FooterLogo}
              alt={`www.pivot.co ${str(I18N_STR.externalLink)}`}
              width="46"
              height="46"
            />
          </a>
          <span className="copyright">
            &copy; {str(I18N_STR.copyright)} {CURRENT_YEAR}{' '}
            {str(I18N_STR.companyName)}
          </span>
          <Link to={PAGES.TERMS_AND_CONDITIONS.url} className="footer-link">
            {str(I18N_STR.terms)}
          </Link>
          <Link to={PAGES.PRIVACY_POLICY.url} className="footer-link">
            {str(I18N_STR.privacy)}
          </Link>
          <button
            className="footer-link"
            onClick={() => {
              initHelpScoutWidget();
              track(`${WEBREG_PREFIX}:contact-us-footer-link`, {
                'Page Path': location.pathname,
              });
            }}
          >
            {str(I18N_STR.contact)}
          </button>
        </nav>
        <HelpScoutWidget />
      </footer>
    </React.Fragment>
  );
}
