// @flow

export const REG_LAST_STEP_NUM = 6;
export const MIN_PASSWORD_LENGTH = 9;
export const MAX_PASSWORD_LENGTH = 99;
export const MIN_PASSWORD_LENGTH_BACK_COMPAT = 8;
export const ACCESS_CODE_COOKIE = 'access_code';
export const CURRENT_YEAR = new Date().getFullYear();
export const MIN_ALLOWED_ADULT_AGE = 18;
export const MAX_ALLOWED_ADULT_AGE = 85;
export const MIN_ALLOWED_MINOR_AGE = 13;
export const MAX_ALLOWED_MINOR_AGE = 17;
export const YEAR_IN_MS = 31536000000; //1000ms*60sec*60min*24hrs*365.25days
export const NRT_INFINITE_ORDERING = -1;
export const RETAIL_ACCESS_CODE = 'sled';
export const DAYS_BEFORE_READY_TO_REORDER = 7;
export const GRACE_PERIOD = 30;
export const PIVOTCORE_ACCESS_CODE = 'pivotcore';

//consts used in API responses
export const ORG_TYPES = {
  CLINICAL: 'CLINICAL',
};

//Banner message consts
export const MESSAGE = {
  TYPE: {
    INFO: 'info',
    WARN: 'warn',
    SUCCESS: 'success',
    ERROR: 'error',
  },
};

export const STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const ADDICTION_TYPES = {
  SMOKING: 'cig',
  CHEWING: 'chew',
  VAPING: 'vape',
  OTHER: 'other',
  NONE: 'none',
};

export const CLASS_NAMES_BY_NIC_TYPE = {
  [ADDICTION_TYPES.SMOKING]: 'cig',
  [ADDICTION_TYPES.VAPING]: 'vape',
  [ADDICTION_TYPES.OTHER]: 'other',
};

export const ACCESS_CODES_API_RES_TYPES = {
  SUCCESS: 'success',
  EXPIRED: 'expired',
  FULL: 'full',
  NOT_STARTED: 'not-started',
  FAIL: 'fail',
  NO_EMPLOYER: 'no-employer',
  UNAVAILABLE: 'unavailable',
};

export const DEVICE_TYPE = {
  SMARTPHONE: 'smartphone',
  NON_SMARTPHONE: 'non-smartphone',
  IOS: 'ios',
  ANDROID: 'android',
  DESKTOP: 'desktop',
};

export const USER_EMAIL_STATUS_RES = {
  EXISTS: 'user-exists',
  NOT_VERIFIED: 'email-not-verified',
  NOT_FOUND: 'user-not-found',
  NOT_INITIALIZED: 'user-not-initialized',
  INVALID_EMAIL: 'invalid-email',
  UNDEFINED: 'undefined',
};

export const PARTNERS = {
  TARGET: {
    accessCodes: ['target'],
    alias: 'target',
    isCigOnly: false,
  },
  BCNC: {
    accessCodes: ['bluecrossnc'],
    alias: 'bluecrossnc',
    isCigOnly: true,
  },
  CDPHP: {
    accessCodes: ['cdphp', 'ualocal7', 'crisafullibros', 'maincareenergy'],
    alias: 'cdphp',
    isCigOnly: false,
  },
  LMC: {
    accessCodes: ['lmc'],
    alias: 'lmc',
    isCigOnly: true,
  },
  CHP: {
    accessCodes: ['chp2020'],
    alias: null,
    isCigOnly: true,
  },
  NYL: {
    accessCodes: ['nyl', 'nyl2020'],
    alias: 'nyl',
    isCigOnly: true,
  },
  FLBLUE: {
    accessCodes: ['floridablue'],
    alias: 'FloridaBlue',
    isCigOnly: false,
  },
  HEALTHPARTNERS: {
    accessCodes: ['healthpartners'],
    alias: 'healthpartners',
    isCigOnly: false,
  },
};

export const ACCESS_CODES_FOR_MINORS = ['aetnabetterhealthlam'];

export const AGE_CATEGORY = {
  ADULT: 'ADULT',
  MINOR: 'MINOR',
};

export const AGE_CATEGORIES = {
  [AGE_CATEGORY.ADULT]: {
    MIN: MIN_ALLOWED_ADULT_AGE,
    MAX: MAX_ALLOWED_ADULT_AGE,
  },
  [AGE_CATEGORY.MINOR]: {
    MIN: MIN_ALLOWED_MINOR_AGE,
    MAX: MAX_ALLOWED_MINOR_AGE,
  },
};
