import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PAGES } from '../../../page-urls';
import { track, WEBREG_PREFIX } from '../../../utils/mixpanelHelper';
import { AjaxifiedButton } from './FormComponents';
import Logo from '../../../../media/pivot-logo-dark.svg';
import { LoginButton } from '../../../components/LoginButton';
import { usePageScrolled } from './usePageScrolled';
import { useI18n } from '../../../i18n/utils';
import { I18N_STR } from '../../../i18n/strings';

export function StickyHeader({
  cssClassNames = '',
  logo,
  showButton,
  buttonCta,
  buttonLabel,
  brandBarCopy = null,
}) {
  const showActionBar = usePageScrolled();
  const location = useLocation();
  const alias = useSelector(state => state?.accessCodeConfig?.alias);
  const homeLink = alias ? `${PAGES.PARTNER_LANDING.url}/${alias}` : '';
  const { str } = useI18n();

  return (
    <header
      className={classNames('brand-bar', 'fixed-brand-bar', cssClassNames, {
        'is-active': showActionBar,
      })}
    >
      <div className="wrapper">
        <a
          href={`${window.location.origin}${homeLink}`}
          className="header-logo"
          aria-hidden="true"
        >
          <img
            src={logo || Logo}
            alt={str(I18N_STR.logoAlt)}
            width="155"
            height="34"
          />
        </a>

        {brandBarCopy && <div className="brand-bar-copy">{brandBarCopy}</div>}

        <LoginButton />

        {showButton && (
          <AjaxifiedButton
            onClickHandler={() => {
              track(`${WEBREG_PREFIX}:button-click`, {
                'Page Path': location.pathname,
                buttonName: 'header',
              });
              buttonCta();
            }}
            label={buttonLabel ?? str(I18N_STR.getStarted)}
          />
        )}
      </div>
    </header>
  );
}
