import React from 'react';
import ImageX1WebP from '../../../../media/landing-page/vape-hero-desktop@1x.webp';
import ImageX2WebP from '../../../../media/landing-page/vape-hero-desktop@2x.webp';
import ImageX1 from '../../../../media/landing-page/vape-hero-desktop@1x.jpg';
import ImageX2 from '../../../../media/landing-page/vape-hero-desktop@2x.jpg';

import ImageMobileX1WebP from '../../../../media/landing-page/vape-hero-mobile@2x.webp';
import ImageMobileXHalfWebP from '../../../../media/landing-page/vape-hero-mobile@1x.webp';
import ImageMobileXHalf from '../../../../media/landing-page/vape-hero-mobile@1x.jpg';
import ImageMobileX1 from '../../../../media/landing-page/vape-hero-mobile@1x.jpg';

import styles from '../../../../css/UnifiedLanding.module.scss';

export default function ResponsiveHeroImageVape() {
  return (
    <picture className={styles.heroImage}>
      {/*webp*/}
      <source
        media="(max-width: 640px) and (-webkit-min-device-pixel-ratio: 2)"
        srcSet={ImageMobileX1WebP}
        type="image/webp"
      />
      <source
        media="(max-width: 640px)"
        srcSet={ImageMobileXHalfWebP}
        type="image/webp"
      />
      <source
        media="(max-width: 1280px)"
        srcSet={ImageX1WebP}
        type="image/webp"
      />
      <source
        media="(min-width: 1281px)"
        srcSet={ImageX2WebP}
        type="image/webp"
      />
      {/*jpeg*/}
      <source
        media="(max-width: 640px) and (-webkit-min-device-pixel-ratio: 2)"
        srcSet={ImageMobileXHalf}
        type="image/webp"
      />
      <source
        media="(max-width: 640px)"
        srcSet={ImageMobileX1}
        type="image/jpeg"
      />
      <source media="(max-width: 1280px)" srcSet={ImageX1} type="image/jpeg" />
      <source media="(min-width: 1281px)" srcSet={ImageX2} type="image/jpeg" />
      <img src={ImageX1} alt="" height="680" width="1440" />
    </picture>
  );
}
