export const SMOKELESS_TOBACCO_TYPES = {
  CHEW: 'chew',
  SNUFF_NOSE: 'snuff-nose',
  SNUFF_MOUTH: 'snuff-mouth',
  DISSOLVABLE: 'dissolvable',
  OTHER_PRODUCT: 'other-product',
};

export const OTHER_TOBACCO_TYPES = {
  CIGARS: 'cigars',
  CIGARILLOS: 'cigarillos',
  PIPE_TOBACCO: 'pipe_tobacco',
  HOOKAH: 'hookah',
};

export const ELIGIBILITY_REGISTRATION_MAP = {
  firstName: 'given_name',
  lastName: 'family_name',
  dateOfBirth: 'birthdate',
  zipCode: 'zip',
  email: 'email',
  gender: 'gender',
};

export const REG_FLOW_TYPES = {
  DTC_RETAIL: 'dtc-retail',
};
