/*global NativeApp*/

import {
  identifyUser,
  NRT_PREFIX,
  resetUser,
  track,
} from '../../utils/mixpanelHelper';
import { callIOSHandler } from '../../utils/native-bridge-utils';
import { store } from '../../../state/store';
import { DEVICE_TYPE } from '../../constants';
import { ACTION_TYPES } from '../../../state/actions';
import {
  checkSessionWithPromise,
  toRegularTelephone,
} from '../../utils/AWSCognitoHelper';
import { Navigate } from 'react-router-dom';
import { PAGES } from '../../page-urls';
import React from 'react';

export function getDeviceType() {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.has('device')
    ? searchParams.get('device')
    : DEVICE_TYPE.DESKTOP;
}

export function appHasAppBootedSuccessfullyHandler() {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('hasAppBootedCallback') === 'true';
}

export function appHasOrderPlacedSuccessfullyHandler() {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('needsOrderSuccessStatus') === 'true';
}

export const getItems = () => {
  const state = store.getState();
  const selectedPackage =
    state.nrt.userAvailableNRTPackages.productOfferings.find(
      product => product.selected
    );

  const selectedPackageName = selectedPackage.item.toLowerCase();
  const isGumAndPatchBundle =
    selectedPackageName.includes('patch') &&
    selectedPackageName.includes('gum');

  return selectedPackage.options.reduce((items, item) => {
    if (!item.selected) {
      return items;
    }

    item.options
      .filter(option => option.selected)
      .forEach(nestedItem => {
        // in gum+patch we need drill further to get the "flavor" product name
        if (isGumAndPatchBundle && nestedItem?.options) {
          nestedItem.options
            .filter(flavor => flavor.selected)
            .forEach(flavor => {
              items.push({
                friendlyName: flavor.friendlyName,
                item: flavor.item,
                quantity: 1,
              });
            });
        } else {
          items.push({
            friendlyName: nestedItem.friendlyName,
            item: nestedItem.item,
            quantity: 1,
          });
        }
      });
    return items;
  }, []);
};

export function getItemsWithFriendlyNames(products, items) {
  return items.map(item => {
    const friendlyProduct = products[item.item];
    return {
      ...item,
      ...friendlyProduct,
    };
  });
}

export function logNRTPageView(pageName) {
  sendMixpanelEvent({
    event: `${NRT_PREFIX}:page-view`,
    parameters: {
      page: pageName,
    },
  });
}

export function sendMixpanelEvent({ event, parameters = {} }) {
  const state = store.getState();
  const device = state.device;
  track(event, { ...parameters, device });
}

export function exitNRTWebview() {
  resetUser();

  const state = store.getState();
  const device = state.device;

  if (device === DEVICE_TYPE.IOS) {
    callIOSHandler('exitOrderForm', null, null);
  }

  if (device === DEVICE_TYPE.ANDROID && typeof NativeApp !== 'undefined') {
    NativeApp.exitOrderForm();
  }
}

export function setOrderPlacedSuccessfullyOnNative() {
  const state = store.getState();
  const device = state.device;
  if (state.nrt.needsOrderSuccessStatus) {
    if (device === DEVICE_TYPE.IOS) {
      callIOSHandler('orderPlacedSuccessfully', null, null);
    }

    if (device === DEVICE_TYPE.ANDROID && typeof NativeApp !== 'undefined') {
      NativeApp.orderPlacedSuccessfully();
    }
  }
}

export function getShippingAddressComponents(formattedAddress) {
  if (!formattedAddress) {
    return;
  }

  const addressObject = {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  };

  const componentsNumberWithAddressLine2 = 6;
  const componentsNumberWithAddressLine2And1ExtraComma =
    componentsNumberWithAddressLine2 + 1;
  const componentsNumberWithAddressLine2And2ExtraCommas =
    componentsNumberWithAddressLine2 + 2;
  const components = formattedAddress
    .split(',')
    .map(c => c.trim().replace(/\\n/gim, ', ').replace(/\//gim, ''));
  const last = components.length - 1;

  if (components.length === componentsNumberWithAddressLine2 - 1) {
    addressObject.addressLine1 = components[0];
  }

  if (components.length === componentsNumberWithAddressLine2) {
    addressObject.addressLine1 = components[0];
    addressObject.addressLine2 = components[1];
  }

  if (components.length === componentsNumberWithAddressLine2And1ExtraComma) {
    addressObject.addressLine1 = `${components[0]}, ${components[1]}`;
    addressObject.addressLine2 = components[2];
  }

  if (components.length === componentsNumberWithAddressLine2And2ExtraCommas) {
    addressObject.addressLine1 = `${components[0]}, ${components[1]}`;
    addressObject.addressLine2 = `${components[2]}, ${components[3]}`;
  }

  addressObject.city = components[last - 3];
  addressObject.state = components[last - 2];
  addressObject.zip = components[last - 1];
  addressObject.country = components[last];

  return addressObject;
}

export async function refreshAuthToken(device) {
  if (device === DEVICE_TYPE.ANDROID) {
    const userObject = NativeApp.identifyUser();
    const userInfo = JSON.parse(userObject);
    const { userId, authToken } = userInfo;
    const payload = {
      userId: userId,
      authToken: authToken,
    };
    store.dispatch({
      type: ACTION_TYPES.NRT_SET_USER,
      payload,
    });
    return Promise.resolve(payload);
  } else if (device === DEVICE_TYPE.IOS) {
    return new Promise((resolve, reject) => {
      callIOSHandler('identifyUser', null, responseData => {
        resolve(responseData);
      });
    }).then(userInfo => {
      const { userId, authToken } = userInfo;
      const payload = {
        userId: userId,
        authToken: authToken,
      };
      store.dispatch({
        type: ACTION_TYPES.NRT_SET_USER,
        payload,
      });
      return payload;
    });
  } else {
    return checkSessionWithPromise().then(userSession => {
      if (!userSession) {
        return <Navigate to={PAGES.LOGIN.url} />;
      } else {
        const {
          cognitoObject: {
            signInUserSession: {
              accessToken: {
                jwtToken,
                payload: { sub: userId },
              },
            },
          },
        } = userSession;

        const payload = {
          userId: userId,
          authToken: jwtToken,
        };

        store.dispatch({
          type: ACTION_TYPES.NRT_SET_USER,
          payload,
        });
        return payload;
      }
    });
  }
}
