import React, { useEffect, useState } from 'react';
import { useI18n } from '../../../i18n/utils';
import { I18N_STR } from '../../../i18n/strings';

export const SocialProof = () => {
  const { str } = useI18n();

  return (
    <div className="social-proof">
      <div className="wrapper">
        <div className="testimonial-and-stats">
          <h2>
            <StatsCounter countTo={91} suffix="%" />
            {str(I18N_STR.socialProofTitle1)}
          </h2>
          <blockquote>
            “{str(I18N_STR.socialProofQuote1)}”<cite>Tiffany C.</cite>
          </blockquote>
        </div>
        <div className="testimonial-and-stats">
          <h2>
            <StatsCounter countTo={86} suffix="%" />
            {str(I18N_STR.socialProofTitle2)}
          </h2>
          <blockquote>
            “{str(I18N_STR.socialProofQuote2)}”<cite>Tony C.</cite>
          </blockquote>
        </div>
        <div className="testimonial-and-stats">
          <h2>
            <StatsCounter countTo={81} suffix="%" />
            {str(I18N_STR.socialProofTitle3)}
          </h2>
          <blockquote>
            “{str(I18N_STR.socialProofQuote3)}”<cite>Peggy S.</cite>
          </blockquote>
        </div>
      </div>
      <span className="program-disclaimer">
        {str(I18N_STR.socialProofDisclaimer)}
      </span>
    </div>
  );
};

export const StatsCounter = ({ countTo, prefix = null, suffix = null }) => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const counterAnimationTimer = setTimeout(() => {
      const newCount = counter + 1;
      if (newCount <= countTo) {
        setCounter(newCount);
      } else {
        clearTimeout(counterAnimationTimer);
      }
    }, 25);
    return () => clearTimeout(counterAnimationTimer);
  }, [counter]);

  return (
    <span className="stats-counter">
      {prefix}
      {counter}
      {suffix && <span className="suffix">{suffix}</span>}
    </span>
  );
};
