import React, { lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Container } from './containers/register/common-components/GatedContainer';
import { PAGES } from './page-urls';
import './utils/googleAnalyticsHelper';
import SuspensePreloader from './containers/register/common-components/SuspensePreloader';
import LandingPage from './containers/register/landing-page';
import LandingSimpleSignup from './containers/register/landing-simple-signup';
import { ScreenReaderPageTitle } from './components/ScreenReaderPageTitle';
import { On404 } from './router/on-404';
import './utils/detectBots';

const PageCoverage = lazy(() => import('./containers/register/page-coverage'));
const PageNoCoverage = lazy(
  () => import('./containers/register/page-no-coverage')
);
const PageThankYou = lazy(() => import('./containers/register/page-thank-you'));
const PageUsage = lazy(
  () => import('./containers/register/page-usage-selection')
);
const PageEligibilityPrimary = lazy(
  () => import('./containers/register/page-eligibility-primary')
);
const PageEligibilitySecondary = lazy(
  () => import('./containers/register/page-eligibility-secondary')
);
const PageEligibilityReview = lazy(
  () => import('./containers/register/page-eligibility-review')
);
const PageApproved = lazy(() => import('./containers/register/page-approved'));

const PageCreateAccountFox = lazy(
  () => import('./containers/register/page-create-account-fox')
);
const PageDownloadApp = lazy(
  () => import('./containers/register/page-download-app')
);
const PageRegistrationComplete = lazy(
  () => import('./containers/register/page-registration-complete')
);
const PageRegistrationError = lazy(
  () => import('./containers/register/page-registration-error')
);
const PageEligibilityTaken = lazy(
  () => import('./containers/register/page-eligibility-taken')
);
const PageCreateAccountForRetail = lazy(
  () => import('./containers/register/page-create-account-retail')
);
const ResetPasswordContainer = lazy(
  () => import('./containers/reset-password/ResetPasswordContainer')
);
const ProfileContainer = lazy(
  () => import('./containers/profile/ProfileContainer')
);
const ShortlistContainer = lazy(
  () => import('./containers/shortlist/ShortlistContainer')
);
const NRT = lazy(() => import('./containers/nrt/NRT'));
const NRTFaq = lazy(() => import('./containers/nrt/NRT-faq'));
const PageReEnrollmentSuccess = lazy(
  () => import('./containers/re-enroll/page-re-enroll-success')
);
const PageReEnrollmentFailed = lazy(
  () => import('./containers/re-enroll/page-re-enroll-failed')
);
const PageReEnrollmentNotAvailable = lazy(
  () => import('./containers/re-enroll/page-re-enroll-not-available')
);
const SubscriptionRenewal = lazy(
  () => import('./containers/profile/subscription-renewal')
);
const ReorderSensor = lazy(() => import('./containers/profile/reorder-sensor'));
const ChangeAddress = lazy(() => import('./containers/profile/change-address'));
const ForgotPasswordContainer = lazy(
  () => import('./containers/forgot-password/ForgotPasswordContainer')
);
const LoginContainer = lazy(() => import('./containers/login/LoginContainer'));
const ReconfirmEmailContainer = lazy(
  () => import('./containers/reconfirm-email/ReconfirmEmailContainer')
);
const PageEligibilityIntro = lazy(
  () => import('./containers/register/page-eligibility-intro')
);
const PageEligibilityApproved = lazy(
  () => import('./containers/register/page-eligibility-approved')
);
const PagePrivacyPolicy = React.lazy(
  () => import('./containers/legal/page-privacy-policy')
);
const PageTermsAndConditions = React.lazy(
  () => import('./containers/legal/page-terms-conditions')
);
const GetAccountCreationSubSection = React.lazy(
  () => import('./router/get-account-creation-subsection')
);

export default function App() {
  return (
    <BrowserRouter>
      <ScreenReaderPageTitle />
      <Routes>
        <Route
          index
          path={PAGES.START.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.START}
                showHeader={false}
                component={() => <LandingPage />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={`${PAGES.PARTNER_LANDING.url}/:partner`}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                showHeader={false}
                page={PAGES.PARTNER_LANDING}
                component={() => <LandingPage />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.PARTNER_LANDING_STREAMLINED.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                showHeader={false}
                page={PAGES.PARTNER_LANDING_STREAMLINED}
                component={() => <LandingPage isStreamlined={true} />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={`${PAGES.PARTNER_LANDING_STREAMLINED.url}/:partner`}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                showHeader={false}
                page={PAGES.PARTNER_LANDING_STREAMLINED}
                component={() => <LandingPage isStreamlined={true} />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={`${PAGES.VIRGIN_PULSE_LANDING.url}/:partner`}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                showHeader={false}
                page={PAGES.VIRGIN_PULSE_LANDING}
                component={() => <LandingPage />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.COVERAGE.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                showFooter={false}
                page={PAGES.COVERAGE}
                component={() => <PageCoverage />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.NO_COVERAGE.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.NO_COVERAGE}
                component={() => <PageNoCoverage />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.THANK_YOU.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.THANK_YOU}
                component={() => <PageThankYou />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.ELIGIBILITY_INTRO.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                gated
                showFooter={false}
                page={PAGES.ELIGIBILITY_INTRO}
                component={() => <PageEligibilityIntro />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.NICOTINE_USAGE_SELECTION.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                gated
                showFooter={false}
                page={PAGES.NICOTINE_USAGE_SELECTION}
                component={() => <PageUsage />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.ELIGIBILITY_PRIMARY.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                gated
                showFooter={false}
                page={PAGES.ELIGIBILITY_PRIMARY}
                component={() => <PageEligibilityPrimary />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.ELIGIBILITY_SECONDARY.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                gated
                showFooter={false}
                page={PAGES.ELIGIBILITY_SECONDARY}
                component={() => <PageEligibilitySecondary />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.REVIEW_ELIGIBILITY.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                gated
                showFooter={false}
                page={PAGES.REVIEW_ELIGIBILITY}
                component={() => <PageEligibilityReview />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.ELIGIBILITY_APPROVED.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                gated
                showFooter={false}
                page={PAGES.ELIGIBILITY_APPROVED}
                component={() => <PageEligibilityApproved />}
              />
            </React.Suspense>
          }
        />
        <Route
          sensitive={false}
          strict={false}
          exact={true}
          path={`${PAGES.CREATE_ACCOUNT.url}`}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <GetAccountCreationSubSection />
            </React.Suspense>
          }
        />
        <Route
          sensitive={false}
          strict={false}
          exact={false}
          path={`${PAGES.CREATE_ACCOUNT.url}/:detailsSection`}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <GetAccountCreationSubSection />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.DOWNLOAD_APP.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                gated
                page={PAGES.DOWNLOAD_APP}
                component={() => <PageDownloadApp />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.COMPLETE_REGISTRATION.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.COMPLETE_REGISTRATION}
                component={() => <PageRegistrationComplete />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.REGISTRATION_ERROR.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.REGISTRATION_ERROR}
                component={() => <PageRegistrationError />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.CONFIRMED.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.CONFIRMED}
                component={() => <PageRegistrationComplete />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.ELIGIBILITY_TAKEN.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.ELIGIBILITY_TAKEN}
                component={() => <PageEligibilityTaken />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.APPROVED.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.APPROVED}
                component={() => <PageApproved />}
              />
            </React.Suspense>
          }
        />
        <Route
          strict={false}
          path={PAGES.AMAZON.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.AMAZON}
                component={() => <PageCreateAccountForRetail />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.APPROVED.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.APPROVED}
                component={() => <PageApproved />}
              />
            </React.Suspense>
          }
        />
        <Route
          exact
          strict={false}
          path={PAGES.AMAZON.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.AMAZON}
                component={() => <PageCreateAccountForRetail />}
              />
            </React.Suspense>
          }
        />
        <Route
          exact
          strict={false}
          path={PAGES.CREATE_ACCOUNT_FOX.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                gated
                page={PAGES.CREATE_ACCOUNT_FOX}
                component={() => <PageCreateAccountFox />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.APPROVED.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.APPROVED}
                component={() => <PageApproved />}
              />
            </React.Suspense>
          }
        />
        <Route
          exact
          strict={false}
          path={PAGES.AMAZON.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.AMAZON}
                component={() => <PageCreateAccountForRetail />}
              />
            </React.Suspense>
          }
        />
        <Route
          exact
          strict={false}
          path={`${PAGES.SIGNUP_ONLY.url}/:code`}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.SIGNUP_ONLY}
                component={() => <LandingSimpleSignup />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.RE_ENROLLMENT.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.RE_ENROLLMENT}
                component={() => <PageReEnrollmentSuccess />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.RE_ENROLLMENT_FAILED.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.RE_ENROLLMENT_FAILED}
                component={() => <PageReEnrollmentFailed />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.RE_ENROLLMENT_NOT_AVAILABLE.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.RE_ENROLLMENT_NOT_AVAILABLE}
                component={() => <PageReEnrollmentNotAvailable />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.LOGIN.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.LOGIN}
                component={() => <LoginContainer />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.FORGOT_PASSWORD.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.FORGOT_PASSWORD}
                component={() => <ForgotPasswordContainer />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.RESET_PASSWORD.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.RESET_PASSWORD}
                component={({ location }) => (
                  <ResetPasswordContainer location={location} />
                )}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.NRT_FAQ.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container page={PAGES.NRT_FAQ} component={() => <NRTFaq />} />
            </React.Suspense>
          }
        />
        <Route
          path={`${PAGES.NRT.url}/*`}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container page={PAGES.NRT} component={() => <NRT />} />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.PROFILE.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.PROFILE}
                component={() => <ProfileContainer />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={`${PAGES.SUBSCRIPTION_RENEWAL.url}/:status?`}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.SUBSCRIPTION_RENEWAL}
                component={() => <SubscriptionRenewal />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.REORDER_SENSOR.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.REORDER_SENSOR}
                component={() => <ReorderSensor />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.CHANGE_ADDRESS.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.CHANGE_ADDRESS}
                component={() => <ChangeAddress />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.RECONFIRM.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.RECONFIRM}
                component={() => <ReconfirmEmailContainer />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.SHORTLIST.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.SHORTLIST}
                component={() => <ShortlistContainer />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.PRIVACY_POLICY.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.PRIVACY_POLICY}
                component={() => <PagePrivacyPolicy />}
              />
            </React.Suspense>
          }
        />
        <Route
          path={PAGES.TERMS_AND_CONDITIONS.url}
          element={
            <React.Suspense fallback={<SuspensePreloader />}>
              <Container
                page={PAGES.TERMS_AND_CONDITIONS}
                component={() => <PageTermsAndConditions />}
              />
            </React.Suspense>
          }
        />
        <Route path="*" element={<On404 />} />
      </Routes>
    </BrowserRouter>
  );
}
