import React from 'react';
import { useLocation } from 'react-router-dom';
import { track, WEBREG_PREFIX } from '../../../utils/mixpanelHelper';
import { initHelpScoutWidget } from '../../../utils/helpScoutWidget';
import { useI18n } from '../../../i18n/utils';
import { I18N_STR } from '../../../i18n/strings';

export function ChatSupportLink({ label }) {
  const location = useLocation();
  const { str } = useI18n();
  return (
    <button
      className="support-chat-launcher"
      onClick={() => {
        initHelpScoutWidget();
        track(`${WEBREG_PREFIX}:email-support-link`, {
          'Page Path': location.pathname,
        });
      }}
    >
      {label || str(I18N_STR.messageSupport)}
    </button>
  );
}
