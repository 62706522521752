import { PAGES } from './page-urls';
import {
  MAX_ALLOWED_ADULT_AGE,
  MAX_ALLOWED_MINOR_AGE,
  MIN_ALLOWED_ADULT_AGE,
  MIN_ALLOWED_MINOR_AGE,
} from './constants';
import { LANGUAGES, str } from './i18n/utils';
import { I18N_STR } from './i18n/strings';

export const STRINGS = {
  EMPTY: '',
  LOADING: 'Loading',
  CONTINUE: 'Continue',
  PLACEHOLDERS: {
    PASSWORD: 'Password',
    RETYPE_PASSWORD: 'Re-type password',
  },
  ERRORS: {
    ACCOUNT_NOT_FOUND: 'We can&apos;t find your account',
    ACCOUNT_NOT_FOUND_CONTACT_SUPPORT:
      'We can&apos;t find your account - please contact support@pivot.co',
    AGE_RANGE_PROBLEM: {
      MINOR: `Sorry, this registration of Pivot only allows people from ages ${MIN_ALLOWED_MINOR_AGE} through ${MAX_ALLOWED_MINOR_AGE} to register.`,
      ADULT: `Sorry, this registration of Pivot only allows people from ages ${MIN_ALLOWED_ADULT_AGE} through ${MAX_ALLOWED_ADULT_AGE} to register.`,
    },
    EMAIL_EXISTS_IN_SYSTEM: `Email is already in system. <a href="${document.location.origin}${PAGES.LOGIN.url}" class="green">Log in</a>`,
    EMAIL_NOT_VALID: 'Email is not a valid address',
    EMAILS_SHOULD_MATCH: 'Emails must match',
    ACCESS_CODE_NOT_RECOGNIZED:
      'Oops! We don&apos;t recognize that one. Please contact us at <a href="mailto:support@pivot.co">support@pivot.co</a> to make sure you have the correct code.',
    ACCESS_CODE_FULL:
      'This access code is no longer active. Please contact us at <a href="mailto:support@pivot.co">support@pivot.co</a> to make sure you have the correct code.',
    ACCESS_CODE_EXPIRED:
      'This access code is no longer active. Please contact us at <a href="mailto:support@pivot.co">support@pivot.co</a> to make sure you have the correct code.',
    ENTER_VALID_ACCESS_CODE: 'Invalid Access Code',
    ENTER_VALID_ADDRESS: 'Invalid address',
    ENTER_VALID_CITY: 'Invalid city',
    ENTER_VALID_CODE: 'Invalid code',
    ENTER_VALID_DATE: 'Invalid date',
    ENTER_VALID_EMAIL: 'Invalid email',
    ENTER_VALID_PASSWORD: 'Invalid password',
    ENTER_VALID_PHONE: 'Invalid phone number',
    ENTER_VALID_ZIP: 'Invalid zip code',
    FIRST_NAME_EMPTY: 'First Name cannot be empty',
    FIRST_NAME_TOO_LONG: '19 character limit',
    INCORRECT_USER_PASS: 'Incorrect username or password',
    INCORRECT_VERIFICATION_CODE:
      'The verification code you entered is incorrect',
    INVALID_INPUT: 'Input is not valid',
    INVALID_INPUT_STRING: 'Sorry, only letters (a-z) and spaces are allowed',
    LAST_NAME_EMPTY: 'Last Name cannot be empty',
    LAST_NAME_TOO_LONG: '20 character limit',
    LIMITS_EXCEEDED: 'Attempt limit exceeded, please try after some time',
    LAMBDA_ERROR_DURING_SIGNUP: 'Lambda error during signup',
    MISSING: 'Please complete this field',
    PASS_NOT_VALID: 'Password is not valid',
    PASS_1_NOT_VALID: 'Password 1 is not valid',
    PASS_2_NOT_VALID: 'Password 2 is not valid',
    PASS_CHANGE_LIMITS_EXCEEDED:
      'You&apos;ve tried to change your password too many times recently - please try again later',
    PASSWORDS_SHOULD_MATCH: 'Passwords must match',
    PHONE_NUMBER_EMPTY: 'Phone number cannot be empty',
    PHONE_NUMBER_LENGTH: 'Phone number must be at least 10 characters',
    SELECT: 'Please select one',
    SERVER_ERROR: 'Server Error',
    SESSION_TIMEOUT: 'Your session timed out - please log in and try again',
    SOMETHING_WRONG: 'Oops, looks like there was an error',
    SOMETHING_WRONG_CONTACT_SUPPORT:
      'Oops, looks like there was an error. Please reach out to <a href="mailto:support@pivot.co">support@pivot.co</a> for help',
    USER_NAME_EXISTS_GOTO_LOGIN: `Looks like you already have an account. Please <a href="${document.location.origin}/login">click here</a> to login or contact us at <a href="mailto:support@pivot.co">support@pivot.co</a>`,
    URL_PARSE_FAILED: 'Parsing return url failed.',
    USER_DISABLED:
      'Your account has been disabled. Please contact your access code provider for more information.',
    USER_NOT_INITIALIZED:
      'Looks like you already have an account. Please log in to the mobile app to complete registration.',
    USER_NOT_CONFIRMED:
      'Looks like you&apos;ve already signed up, but you need to verify your email. We&apos;ve just emailed you a new link.',
    USER_NOT_FOUND: 'An account with this email address does not exist.',
    USER_CONFIRMED: 'User is already confirmed.',
    PAYMENT_FAIL:
      'Processing payment failed, Please reach out to support@pivot.co for help',
  },
  NOTIFICATIONS: {
    EMAIL_SENT_OK: 'Message successfully sent, check your email!',
    PASS_RESET_EMAIL_SENT:
      'An email was sent - you&apos;ll now be redirected to reset your password',
    PASS_UPDATE_OK: 'Password was updated successfully',
    SMS_SENT: 'SMS sent!',
    TEL_UPDATE_OK: 'Telephone was updated successfully',
    THANKS: 'Thank you!',
    USER_RECONFIRM:
      'We&apos;ve just sent you a new link please check your email and verify your account',
  },
  WCAG: {
    SUBMIT_BUTTON_DISABLED:
      'Submit button has been disabled until all fields are completed',
  },
};

export const ERROR_MSGS = {
  INCORRECT_USER_OR_PASS: 'Incorrect username or password.',
  ATTEMPT_LIMIT_EXCEEDED: 'Attempt limit exceeded, please try after some time.',
  SESSION_TIMEOUT: 'Session Timed Out',
  USER_DOESNT_EXIST: 'User does not exist.',
  USER_NOT_CONFIRMED: 'User is not confirmed.',
  USER_DISABLED: 'User is disabled',
};
export const RESULTS = {
  SUCCESS: 'success',
  EMAIL_SENT: 'Email Sent',
  CODES: {
    USER_NOT_FOUND_EXCEPTION: 'UserNotFoundException',
    INVALID_PARAM_EXCEPTION: 'InvalidParameterException',
    LIMIT_EXCEEDED_EXCEPTION: 'LimitExceededException',
  },
  MESSAGES: {
    CANNOT_RESET_PASSWORD:
      'Cannot reset password for the user as there is no registered/verified email or phone_number',
  },
};

export const MONTHS = [
  str(I18N_STR.monthJan),
  str(I18N_STR.monthFeb),
  str(I18N_STR.monthMar),
  str(I18N_STR.monthApr),
  str(I18N_STR.monthMay),
  str(I18N_STR.monthJun),
  str(I18N_STR.monthJul),
  str(I18N_STR.monthAug),
  str(I18N_STR.monthSep),
  str(I18N_STR.monthOct),
  str(I18N_STR.monthNov),
  str(I18N_STR.monthDec),
];
