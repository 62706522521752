/**
 * @param {string} name
 * @returns {string|null}
 */
export function getUrlParam(name) {
  if (name && window.location.search) {
    const searchParams = getUrlSearchParamsFromWindowLocation();

    if (searchParams.get(name)) {
      return replaceSpaceWithPlus(searchParams.get(name));
    }
  }
  return '';
}

export function getAllUrlParams() {
  const searchParams = getUrlSearchParamsFromWindowLocation();
  const payload = {};

  for (let [param, paramValue] of searchParams.entries()) {
    payload[param] = replaceSpaceWithPlus(paramValue);
  }

  return payload;
}

function getUrlSearchParamsFromWindowLocation() {
  const searchParams = isEncoded(window.location.search)
    ? tryDecodeURIComponent(window.location.search)
    : window.location.search;
  return new URLSearchParams(searchParams);
}

function replaceSpaceWithPlus(str) {
  return str.replace(/ /g, '+');
}

function tryDecodeURIComponent(uri) {
  try {
    return decodeURIComponent(uri);
  } catch (err) {
    return uri;
  }
}

function isEncoded(uri = '') {
  try {
    return uri !== decodeURIComponent(uri);
  } catch (err) {
    return false;
  }
}
