import * as React from 'react';
import classNames from 'classnames';
import Spinner from './Spinner';
import { useI18n } from '../i18n/utils';
import { I18N_STR } from '../i18n/strings';

export default function ButtonWithSpinner(props) {
  const { str } = useI18n();
  const {
    className = '',
    disabled = false,
    isSendingData,
    label,
    ariaLabel,
    onClick,
  } = props;

  const handleOnClick = e => {
    onClick(e);
  };

  return (
    <button
      onClick={handleOnClick}
      className={classNames(
        'next-frame-button',
        {
          'is-sending': isSendingData,
          'is-disabled': disabled,
        },
        className
      )}
      disabled={disabled}
      aria-label={
        (isSendingData && str(I18N_STR.disabledDuringSubmission)) ||
        ariaLabel ||
        label
      }
    >
      {isSendingData && <Spinner />}
      {label}
    </button>
  );
}
