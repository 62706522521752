const config = {
  development: {
    mixpanelNotificationEmailPrefix: 'sandbox',
    mixpanelId: '220f5dfe32421d88c2b74c268a15c331',
    googleAnalyticsId: 'UA-100293502-3',
    accessCodes: {
      fox21: 'fox21test',
    },
    aws: {
      identityPoolId: 'us-west-2:0338904d-9bba-4090-9852-7f56f20ec5b9',
      userPoolId: 'us-west-2_ybUxaGP37',
      clientId: '11rsge388bnvlvqrqo2uptc3hf',
    },
    cognitoExtensionsEndpoint:
      'https://tpavx0d9og.execute-api.us-west-2.amazonaws.com/Testing',
    eligibilityRootEndpoint:
      process.env.REACT_APP_ELIGIBILITY_ENDPOINT ||
      `https://api-${
        process.env.REACT_APP_BACKEND_ENV || 'qa01'
      }.carrotsense.com`,
    userManagementRootEndpoint:
      process.env.REACT_APP_USER_MANAGEMENT_ENDPOINT ||
      `https://api-${
        process.env.REACT_APP_BACKEND_ENV || 'qa01'
      }.carrotsense.com`,
    accessCodesRootEndpoint:
      process.env.REACT_APP_ACCESS_CODES_ENDPOINT ||
      `https://api-${
        process.env.REACT_APP_BACKEND_ENV || 'qa01'
      }.carrotsense.com`,
    orderManagementRootEndpoint:
      process.env.REACT_APP_ORDER_MANAGEMENT_ENDPOINT ||
      `https://api-${
        process.env.REACT_APP_BACKEND_ENV || 'qa01'
      }.carrotsense.com`,
    docsEndpoint: 'http://localhost:3700/v1/docs',
    contactInformation: {
      phone: '855-374-7727',
      email: 'support@pivot.co',
    },
    b2cSiteUrl: 'https://pivot-web.webflow.io',
    branchKey: 'key_test_mgE0AkbFqyIR8Yo3XdayIpfeCym9j0TW',
    stripeKey: 'pk_test_HvkyKknFXghcrgT8aCWhAFnN',
    virginPulse: {
      authProvider: 'carrot-auth0',
    },
    brazeKey: 'c8fa5c99-8956-4d3f-86ba-6f242894cb6b',
    brazeBaseUrl: 'sdk.iad-02.braze.com',
    brazeAppLinkRequestSubscriptionGroupId:
      'f57201f8-c11e-4b99-ae12-b5370aeb29c2',
    bspOfferPriceIds: {
      bronzeFree: 'price_1LXToVLlsTihNvnMHmukwAhl',
      silverDiscounted: 'price_1M1bCULlsTihNvnMdEH2jRtv',
    },
    webUiUrl: 'http://localhost:4200',
  },
  sandbox01: {
    mixpanelNotificationEmailPrefix: 'sandbox',
    mixpanelId: '220f5dfe32421d88c2b74c268a15c331',
    googleAnalyticsId: 'UA-100293502-3',
    accessCodes: {
      fox21: 'fox21test',
    },
    aws: {
      identityPoolId: 'us-west-2:6607fb8c-3f4f-4fae-b2ef-fb98cb0e5965',
      userPoolId: 'us-west-2_DFY2zR2lN',
      clientId: '3ab6ttpb6ak5vn2jc3o1g4f8dt',
    },
    cognitoExtensionsEndpoint:
      'https://r8ceye8ay3.execute-api.us-west-2.amazonaws.com/Sandbox',
    eligibilityRootEndpoint: 'https://api-sandbox01.carrotsense.com',
    userManagementRootEndpoint: 'https://api-sandbox01.carrotsense.com',
    accessCodesRootEndpoint: 'https://api-sandbox01.carrotsense.com',
    orderManagementRootEndpoint: 'https://api-sandbox01.carrotsense.com',
    docsEndpoint: 'https://api-sandbox01.carrotsense.com/v1/docs',
    contactInformation: {
      phone: '855-374-7727',
      email: 'support@pivot.co',
    },
    b2cSiteUrl: 'https://pivot-web.webflow.io',
    branchKey: 'key_test_mgE0AkbFqyIR8Yo3XdayIpfeCym9j0TW',
    stripeKey: 'pk_test_HvkyKknFXghcrgT8aCWhAFnN',
    virginPulse: {
      authProvider: 'carrot-auth0',
    },
    brazeKey: 'c8fa5c99-8956-4d3f-86ba-6f242894cb6b',
    brazeBaseUrl: 'sdk.iad-02.braze.com',
    brazeAppLinkRequestSubscriptionGroupId:
      'f57201f8-c11e-4b99-ae12-b5370aeb29c2',
    bspOfferPriceIds: {
      bronzeFree: 'price_1LXToVLlsTihNvnMHmukwAhl',
      silverDiscounted: 'price_1M1bCULlsTihNvnMdEH2jRtv',
    },
    webUiUrl: 'https://web-sandbox01.pivot.co',
  },
  sandbox04: {
    mixpanelNotificationEmailPrefix: 'sandbox',
    mixpanelId: '220f5dfe32421d88c2b74c268a15c331',
    googleAnalyticsId: 'UA-100293502-3',
    accessCodes: {
      fox21: 'fox21test',
    },
    aws: {
      identityPoolId: 'us-west-2:4c0384e3-3451-453b-9f84-b9beadd49a23',
      userPoolId: 'us-west-2_fLgBhQaSx',
      clientId: '2d7q7kk5g7akdgi51ec1c3f76t',
    },
    cognitoExtensionsEndpoint:
      'https://r8ceye8ay3.execute-api.us-west-2.amazonaws.com/Sandbox',
    eligibilityRootEndpoint: 'https://api-sandbox04.carrotsense.com',
    userManagementRootEndpoint: 'https://api-sandbox04.carrotsense.com',
    accessCodesRootEndpoint: 'https://api-sandbox04.carrotsense.com',
    orderManagementRootEndpoint: 'https://api-sandbox04.carrotsense.com',
    docsEndpoint: 'https://api-sandbox04.carrotsense.com/v1/docs',
    contactInformation: {
      phone: '855-374-7727',
      email: 'support@pivot.co',
    },
    b2cSiteUrl: 'https://pivot-web.webflow.io',
    branchKey: 'key_test_mgE0AkbFqyIR8Yo3XdayIpfeCym9j0TW',
    stripeKey: 'pk_test_HvkyKknFXghcrgT8aCWhAFnN',
    virginPulse: {
      authProvider: 'carrot-auth0',
    },
    brazeKey: 'c8fa5c99-8956-4d3f-86ba-6f242894cb6b',
    brazeBaseUrl: 'sdk.iad-02.braze.com',
    brazeAppLinkRequestSubscriptionGroupId:
      'f57201f8-c11e-4b99-ae12-b5370aeb29c2',
    bspOfferPriceIds: {
      bronzeFree: 'price_1LXToVLlsTihNvnMHmukwAhl',
      silverDiscounted: 'price_1M1bCULlsTihNvnMdEH2jRtv',
    },
    webUiUrl: 'https://web-sandbox04.pivot.co',
  },
  qa01: {
    mixpanelNotificationEmailPrefix: 'sandbox',
    mixpanelId: '220f5dfe32421d88c2b74c268a15c331',
    googleAnalyticsId: 'UA-100293502-3',
    accessCodes: {
      fox21: 'fox21test',
    },
    aws: {
      identityPoolId: 'us-west-2:c61d8e45-2d10-49ad-b1c3-fa239b0c6d03',
      userPoolId: 'us-west-2_iN96e8XRc',
      clientId: '7n232jvggeiefbmmfdptcetrel',
    },
    cognitoExtensionsEndpoint:
      'https://r8ceye8ay3.execute-api.us-west-2.amazonaws.com/Sandbox',
    eligibilityRootEndpoint: 'https://api-qa01.carrotsense.com',
    userManagementRootEndpoint: 'https://api-qa01.carrotsense.com',
    accessCodesRootEndpoint: 'https://api-qa01.carrotsense.com',
    orderManagementRootEndpoint: 'https://api-qa01.carrotsense.com',
    docsEndpoint: 'https://api-qa01.carrotsense.com/v1/docs',
    contactInformation: {
      phone: '855-374-7727',
      email: 'support@pivot.co',
    },
    b2cSiteUrl: 'https://pivot-web.webflow.io',
    branchKey: 'key_test_mgE0AkbFqyIR8Yo3XdayIpfeCym9j0TW',
    stripeKey: 'pk_test_HvkyKknFXghcrgT8aCWhAFnN',
    virginPulse: {
      authProvider: 'virginpulse',
    },
    brazeKey: 'c8fa5c99-8956-4d3f-86ba-6f242894cb6b',
    brazeBaseUrl: 'sdk.iad-02.braze.com',
    brazeAppLinkRequestSubscriptionGroupId:
      'f57201f8-c11e-4b99-ae12-b5370aeb29c2',
    bspOfferPriceIds: {
      bronzeFree: 'price_1LXToVLlsTihNvnMHmukwAhl',
      silverDiscounted: 'price_1M1bCULlsTihNvnMdEH2jRtv',
    },
    webUiUrl: 'https://web-qa01.pivot.co',
  },
  production: {
    mixpanelNotificationEmailPrefix: 'prod',
    mixpanelId: '0c5522c377066bf21d7ba2861011cdae',
    googleAnalyticsId: 'UA-100293502-1',
    accessCodes: {
      fox21: 'fox21test',
    },
    aws: {
      identityPoolId: 'us-west-2:46216dea-5dc8-4c05-8045-f518dce01e59',
      userPoolId: 'us-west-2_2HJLUmD3U',
      clientId: '3hmqa3aecpp87l8pdnispnsdhm',
    },
    cognitoExtensionsEndpoint:
      'https://y725vtoju9.execute-api.us-west-2.amazonaws.com/prod',
    eligibilityRootEndpoint: 'https://api.carrotsense.com',
    userManagementRootEndpoint: 'https://api.carrotsense.com',
    accessCodesRootEndpoint: 'https://api.carrotsense.com',
    orderManagementRootEndpoint: 'https://api.carrotsense.com',
    docsEndpoint: 'https://api.carrotsense.com/v1/docs',
    contactInformation: {
      phone: '855-374-7727',
      email: 'support@pivot.co',
    },
    b2cSiteUrl: 'https://pivot.co',
    branchKey: 'key_live_ndvXzbeAuqJI60h8HX4IKndiDqm5jXTI',
    stripeKey: 'pk_live_O38jSLGz1ALM94Vo1WNeMPge',
    virginPulse: {
      authProvider: 'virginpulse',
    },
    brazeKey: '118aaf50-0917-41d4-973a-cf7b8c9ec626',
    brazeBaseUrl: 'sdk.iad-02.braze.com',
    brazeAppLinkRequestSubscriptionGroupId:
      '8a0826ad-fe98-4754-aabc-6ebfcda171ed',
    bspOfferPriceIds: {
      bronzeFree: 'price_1LhGkQLlsTihNvnMR6QoUWBt',
      silverDiscounted: 'price_1M4zglLlsTihNvnMcVBr3PT2',
    },
    webUiUrl: 'https://web.pivot.co',
  },
};

module.exports = config[process.env.REACT_APP_ENV] || config.development;
