import classnames from 'classnames';
import { useI18n } from '../../../i18n/utils';
import { I18N_STR } from '../../../i18n/strings';

export default function StepsProgressBar({
  currentStepToHighlight,
  isPreviousStepComplete = false,
}) {
  const { str, strReplace } = useI18n();

  const PROGRESS_STEPS = [
    str(I18N_STR.progressStepElig),
    str(I18N_STR.progressStepBenefits),
    str(I18N_STR.progressStepWelcome),
  ];

  return (
    <div
      className={classnames(
        'steps-progress-bar',
        `step-${currentStepToHighlight}-active`
      )}
    >
      <span className="screen-reader-text">
        {strReplace(I18N_STR.progressBarCounter, false, [
          currentStepToHighlight.toString(),
          PROGRESS_STEPS.length.toString(),
        ])}
      </span>
      <ul aria-hidden={true}>
        {PROGRESS_STEPS.map((item, i) => {
          const index = i + 1;
          const isActive = currentStepToHighlight >= index;
          const isCompleted = isPreviousStepComplete
            ? index < currentStepToHighlight
            : false;
          return (
            <li
              key={i}
              className={classnames({
                'is-active': isActive,
                'is-completed': isCompleted,
              })}
              aria-hidden={true}
            >
              <span>{item}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
