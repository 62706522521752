/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { track, WEBREG_PREFIX } from './mixpanelHelper';
import { DEVICE_TYPE } from '../constants';
import { usePageScrolled } from '../containers/register/common-components/usePageScrolled';
import { inferDeviceTypeFromUserAgent } from './mobileDeviceHelper';
import { PAGES } from '../page-urls';
import classnames from 'classnames';

export default function HelpScoutWidget(showOnMobile = false) {
  const location = useLocation();
  const shouldShowButtonForScrollPosition = usePageScrolled(false, true);

  const deviceTypeFromUserAgent = inferDeviceTypeFromUserAgent();
  const isPossiblyMobileDevice =
    deviceTypeFromUserAgent !== DEVICE_TYPE.DESKTOP;

  const urlSearchParams = new URLSearchParams(location.search);
  const isMobileReg = urlSearchParams.has('mobile_reg');
  const isMobileDeviceFromNrtUrl = urlSearchParams.has('device');

  const PAGES_WITH_HELPSCOUT_WIDGET = [
    PAGES.START.url,
    PAGES.PARTNER_LANDING.url,
    PAGES.JOIN.url,
  ];

  const isPageWithHelpScoutWidget = PAGES_WITH_HELPSCOUT_WIDGET.includes(
    location.pathname
  );

  useEffect(() => {
    const beacon = document.getElementById('beacon-container');
    if (shouldShowButtonForScrollPosition) {
      beacon?.classList.add('show');
    } else {
      beacon?.classList.remove('show');
    }
  }, [shouldShowButtonForScrollPosition]);

  const shouldHide =
    !isPageWithHelpScoutWidget ||
    isPossiblyMobileDevice ||
    isMobileReg ||
    isMobileDeviceFromNrtUrl;

  return shouldHide && !showOnMobile ? null : (
    <HelpScoutFacade init={initHelpScoutWidget} showOnMobile={showOnMobile} />
  );
}

export function HelpScoutFacade({ init, showOnMobile }) {
  const shouldShowButtonForScrollPosition = usePageScrolled(false, true);
  const [isInitiated, setInitiated] = useState(false);

  return isInitiated || Boolean(window.Beacon) ? null : (
    <div
      id="helpscout-facade"
      className={classnames({
        'show-on-mobile': showOnMobile,
        show: shouldShowButtonForScrollPosition,
      })}
    >
      <button
        onClick={() => {
          setInitiated(true);
          init();
        }}
      >
        <svg width="16" height="24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.98785 17.5656L6.01976 15.4805C6.01976 14.9458 6.12612 14.5288 6.33882 14.2294C6.59407 13.652 7.03012 13.2243 7.64698 12.9463C8.2851 12.6469 9.0083 12.3154 9.81659 11.9519C10.8589 11.5669 11.6671 11.0216 12.2415 10.3159C12.8158 9.58879 13.1029 8.59437 13.1029 7.33264C13.1029 5.9212 12.635 4.81985 11.6991 4.02859C10.7631 3.21595 9.55071 2.80962 8.06175 2.80962C6.59407 2.80962 5.38164 3.10902 4.42445 3.70781C3.48854 4.3066 3.02058 5.26894 3.02058 6.59484H0.5C0.5 4.64877 1.17003 3.15179 2.51009 2.10391C3.87142 1.03464 5.7326 0.5 8.09366 0.5C9.60388 0.5 10.912 0.799396 12.0181 1.39819C13.1455 1.99698 14.0176 2.82032 14.6344 3.8682C15.2513 4.91608 15.5384 6.09228 15.4959 7.39679C15.4746 9.10763 15.0705 10.487 14.2835 11.5349C13.4964 12.5614 12.3372 13.3954 10.8057 14.037C10.1463 14.3364 9.64642 14.5716 9.30609 14.7427C8.96576 14.9138 8.73178 15.1062 8.60416 15.3201C8.47653 15.5126 8.40209 15.7799 8.38082 16.122C8.38082 16.4642 8.38082 16.9454 8.38082 17.5656H5.98785ZM7.16838 23.5C6.7217 23.5 6.32819 23.3396 5.98785 23.0188C5.66879 22.6767 5.50926 22.281 5.50926 21.8319C5.50926 21.3615 5.66879 20.9658 5.98785 20.645C6.32819 20.3243 6.7217 20.1639 7.16838 20.1639C7.61507 20.1639 7.99794 20.3243 8.317 20.645C8.63606 20.9658 8.80623 21.3615 8.8275 21.8319C8.8275 22.281 8.65734 22.6767 8.317 23.0188C7.99794 23.3396 7.61507 23.5 7.16838 23.5Z"
            fill="#FFF"
          />
        </svg>
      </button>
    </div>
  );
}

export function initHelpScoutWidget() {
  if (window.Beacon) {
    window.Beacon('open');
  } else {
    includeWidgetScript();
    registerEvents();
  }
}

const includeWidgetScript = () => {
  !(function (e, t, n) {
    function a() {
      const e = t.getElementsByTagName('script')[0];
      const n = t.createElement('script');
      n.type = 'text/javascript';
      n.async = !0;
      n.src = 'https://beacon-v2.helpscout.net';
      e.parentNode.insertBefore(n, e);
    }
    if (
      ((e.Beacon = n =
        function (t, n, a) {
          e.Beacon.readyQueue.push({ method: t, options: n, data: a });
        }),
      (n.readyQueue = []),
      'complete' === t.readyState)
    )
      return a();
    e.attachEvent
      ? e.attachEvent('onload', a)
      : e.addEventListener('load', a, !1);
  })(window, document, window.Beacon || function () {});
};

const registerEvents = () => {
  window.Beacon('init', '22a5b985-09ad-4ccd-833d-bdd9f72e7789');
  window.Beacon('on', 'open', trackInMixpanel);
  window.Beacon('on', 'ready', () => {
    window.Beacon('open');
  });
};

const trackInMixpanel = () => {
  track(`${WEBREG_PREFIX}:chat-support-bubble`, {
    'Page Path': location.pathname,
  });
};
