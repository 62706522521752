import React from 'react';
import { useLocation } from 'react-router-dom';
import { track, WEBREG_PREFIX } from '../../../utils/mixpanelHelper';
import { ChatSupportLink } from './ChatSupportLink';
import {
  AccessCodeInputTextContainer,
  AjaxifiedButton,
} from './FormComponents';
import { useI18n } from '../../../i18n/utils';
import { I18N_STR } from '../../../i18n/strings';

export function AccessCodeForm({
  accessCodeFromUrl,
  accessCode,
  setAccessCode,
  clickHandler,
  buttonLabel,
  _ref,
}) {
  const location = useLocation();
  const { str } = useI18n();

  const keyPressHandler = keyCode => {
    if (keyCode === 'Enter') {
      onClickHandler();
    }
  };

  const onClickHandler = () => {
    clickHandler(accessCode);
    track(`${WEBREG_PREFIX}:button-click`, { 'Page Path': location.pathname });
  };

  const SubmitButton = ({ label }) => (
    <AjaxifiedButton
      label={label}
      disabled={accessCode.length < 3}
      onClickHandler={onClickHandler}
      className="get-started-btn"
    />
  );

  return accessCodeFromUrl ? (
    <SubmitButton label={str(I18N_STR.getStarted)} />
  ) : (
    <div className="access-code-container">
      <AccessCodeInputTextContainer
        value={accessCode}
        updateHandler={setAccessCode}
        onKeyPress={keyPressHandler}
        placeHolder={str(I18N_STR.accessCode)}
        _ref={_ref}
      />
      <SubmitButton label={buttonLabel || str(I18N_STR.getStarted)} />
      <div className="support-copy">
        {str(I18N_STR.accessCodeSupportMessage)}
        {/*TODO missing <ChatSupportLink /> interpolation
        If you don&apos;t know this, <ChatSupportLink /> and provide the name of
        your employer or health plan. You may be eligible for free access to
        Pivot.*/}
      </div>
    </div>
  );
}
