import * as braze from '@braze/web-sdk';
import { store } from '../../state/store';
import {
  brazeKey,
  brazeBaseUrl,
  brazeAppLinkRequestSubscriptionGroupId,
  userManagementRootEndpoint,
} from '../../config';
import { ACTION_TYPES } from '../../state/actions';

const BRAZE_PREFIX = 'web_reg_';

export const BRAZE_EVENTS = {
  PAGE_VIEW: `${BRAZE_PREFIX}page_view`,
  CLICKED_CTA: `${BRAZE_PREFIX}clicked_cta`,
  CLICKED_INTERACTIVE_CTA_CARD: `${BRAZE_PREFIX}clicked_interactive_cta_card`,
  CLICKED_NICOTINE_TYPE: `${BRAZE_PREFIX}clicked_nicotine_type`,
  ELIGIBILITY_SUBMITTED: `${BRAZE_PREFIX}eligibility_submitted`,
  ELIGIBILITY_PASSED: `${BRAZE_PREFIX}eligibility_passed`,
  // no prefix so we can track the 'registered' event consistenly across our apps,
  // so we can exclude users from marketing emails
  REGISTERED: 'registered',
  REQUEST_APP_LINK_SMS: 'request_app_link_sms',
  SNOOZED: `${BRAZE_PREFIX}snoozed`,
  SNOOZED_ONBOARD: `${BRAZE_PREFIX}snoozed_onboard`,
};

export const BRAZE_IDENTIFIERS = {
  EMAIL_ADDRESS: 'email_address',
  MARKETING_ID: 'marketing_id',
  DEVICE_ID: 'device_id',
};

export const BRAZE_ATTRIBUTES = {
  // native
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  DATE_OF_BIRTH: 'dob',
  GENDER: 'gender',
  PHONE: 'phone',
  EMAIL: 'email',
  // custom
  ACCESS_CODE: 'access_code',
  UTM_SOURCE: 'utm_source',
  UTM_MEDIUM: 'utm_medium',
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_CONTENT: 'utm_content',
  UTM_TERM: 'utm_term',
  TOBACCO_TYPES: 'tobacco_types',
  SHIPPING_ADDRESS_LINE_1: 'shipping_address_line_1',
  SHIPPING_ADDRESS_LINE_2: 'shipping_address_line_2',
  SHIPPING_ADDRESS_CITY: 'shipping_address_city',
  SHIPPING_ADDRESS_STATE: 'shipping_address_state',
  SHIPPING_ADDRESS_ZIP: 'shipping_address_zip',
  LAST_UPDATED: 'last_updated',
  LAST_PAGE_VIEWED: `${BRAZE_PREFIX}last_page_viewed`,
  INTERACTIVE_CTA_CARD_CLICKS: `${BRAZE_PREFIX}interactive_cta_card_clicks`,
  NICOTINE_TYPE_CLICKS: `${BRAZE_PREFIX}nicotine_type_clicks`,
  URL_PARAMS: `${BRAZE_PREFIX}url_params`,
  REF_ID: `${BRAZE_PREFIX}ref_id`,
};

export const BRAZE_GENDERS = {
  FEMALE: 'F',
  MALE: 'M',
  OTHER: 'O',
};

braze.initialize(brazeKey, {
  baseUrl: brazeBaseUrl,
});

braze.openSession();

export const trackPageViewInBraze = path => {
  logBrazeEvent(BRAZE_EVENTS.PAGE_VIEW, { path });
  setBrazeAttribute(BRAZE_ATTRIBUTES.LAST_PAGE_VIEWED, path);
};

export const logBrazeEvent = (eventName, eventProperties = {}) => {
  const {
    urlParams: { utm_source, utm_campaign },
  } = store.getState();
  const propertiesWithUtms = {
    ...eventProperties,
    utm_source,
    utm_campaign,
  };

  braze.logCustomEvent(eventName, eventProperties);
};

export const setBrazeAttribute = (key, value, addToArray = false) => {
  const user = braze.getUser();

  switch (key) {
    case BRAZE_ATTRIBUTES.FIRST_NAME:
      user.setFirstName(value);
      break;
    case BRAZE_ATTRIBUTES.LAST_NAME:
      user.setLastName(value);
      break;
    case BRAZE_ATTRIBUTES.DATE_OF_BIRTH:
      const { year, month, day } = value;
      user.setDateOfBirth(year, month, day);
      break;
    case BRAZE_ATTRIBUTES.GENDER:
      user.setGender(value);
      break;
    case BRAZE_ATTRIBUTES.PHONE:
      user.setPhoneNumber(value);
      break;
    case BRAZE_ATTRIBUTES.EMAIL:
      user.setEmail(value);
      break;
    default:
      if (addToArray) {
        user.addToCustomAttributeArray(key, value);
      } else {
        user.setCustomUserAttribute(key, value);
      }
  }
  user.setCustomUserAttribute(
    BRAZE_ATTRIBUTES.LAST_UPDATED,
    new Date().getTime()
  );
};

export const setBrazeAlias = (aliasType, aliasValue) => {
  braze.getUser().addAlias(aliasValue, aliasType);
};

export const setDeviceBrazeAlias = aliasType => {
  getBrazeDeviceId(deviceId => {
    setBrazeAlias(BRAZE_IDENTIFIERS.DEVICE_ID, deviceId);
  });
};

export const getBrazeDeviceId = callback => {
  const { brazeDeviceId } = store.getState();
  const isCallbackAFunction = typeof callback === 'function';

  if (brazeDeviceId && isCallbackAFunction) {
    callback(brazeDeviceId);
    return;
  }

  braze.getDeviceId(deviceId => {
    store.dispatch({
      type: ACTION_TYPES.SET_BRAZE_DEVICE_ID,
      payload: deviceId,
    });
    if (isCallbackAFunction) {
      callback(deviceId);
    }
  });
};

export function mergeBrazeProfiles(
  identifierType,
  identifierValue,
  customAttributeRequired = '',
  callback = null
) {
  braze.requestImmediateDataFlush(success => {
    if (!success) {
      return;
    }

    getBrazeDeviceId(deviceId => {
      const REQUEST_URI = `${userManagementRootEndpoint}/v1/braze/merge-profiles`;
      const body = {
        destinationIdentifierType: BRAZE_IDENTIFIERS.DEVICE_ID,
        destinationIdentifierValue: deviceId,
        sourceIdentifierType: identifierType,
        sourceIdentifierValue: identifierValue,
        customAttributeRequired,
      };

      fetch(REQUEST_URI, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then(response => {
          const { status } = response;
          if (status === 201 && typeof callback === 'function') {
            callback();
          }
        })
        .catch(error => {
          console.error(error);
        });
    });
  });
}

export const changeBrazeUser = userId => {
  braze.changeUser(userId);
};

export function addToAppLinkRequestSubscriptionGroup() {
  return braze
    .getUser()
    .addToSubscriptionGroup(brazeAppLinkRequestSubscriptionGroupId);
}
