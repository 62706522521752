const DAY_IN_MILLISECONDS = 86400000;

/**
 * @param {string} name
 * @param {string} value
 * @param {number} expirationDays
 * */
export function setCookie(name, value, expirationDays = 1) {
  const d = new Date();
  const days = DAY_IN_MILLISECONDS * expirationDays;
  d.setTime(d.getTime() + days);
  document.cookie = `${name}=${value};expires=${d.toUTCString()};domain=.pivot.co;path=/`;
}

/**
 * @param {string} name
 * @returns {string}
 */
export function getCookie(name) {
  const cookies = document.cookie.split(';').filter(cookie => {
    return cookie.indexOf(`${name}=`) > -1;
  });
  if (cookies.length > 0) {
    return cookies[0].replace(`${name}=`, '').trim();
  }
  return '';
}
