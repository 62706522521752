/*global SwiftWebViewBridge*/
/*global WKWVJBCallbacks*/
/*global WKWebViewJavascriptBridge*/
/*global webkit*/
/*global NativeApp*/

import { appHasAppBootedSuccessfullyHandler } from '../containers/nrt/utils';

let iOSBridge;

export function initBridge() {
  if (isLegacyBridge()) {
    initLegacyIOSBridge();
  } else {
    initWKWebViewJavascriptBridge();
  }
}

export function callIOSHandler(handler, payload, callback = null) {
  if (isLegacyBridge()) {
    window.SwiftWebViewBridge.callSwiftHandler(handler, payload, callback);
  } else {
    iOSBridge.callHandler(handler, payload, callback);
  }
}

function isLegacyBridge() {
  const legacyBridge = 'legacy';
  const newestBridge = 'wkwv1.2';
  let bridgeVersion = window.iosBridgeType;
  if (!bridgeVersion) {
    const searchParams = new URLSearchParams(window.location.search);
    bridgeVersion =
      searchParams.get('bridgeVersion') === newestBridge
        ? newestBridge
        : legacyBridge;
    window.iosBridgeType = bridgeVersion;
  }
  return bridgeVersion === legacyBridge;
}

// new bridge
function connectWKWebViewJavascriptBridge(callback) {
  if (!callback) {
    return;
  }
  if (window.WKWebViewJavascriptBridge) {
    return callback(WKWebViewJavascriptBridge);
  }
  if (window.WKWVJBCallbacks) {
    return window.WKWVJBCallbacks.push(callback);
  }
  window.WKWVJBCallbacks = [callback];
  try {
    window.webkit.messageHandlers.iOS_Native_InjectJavascript.postMessage(null);
  } catch (err) {
    console.log(err);
  }
}

function initWKWebViewJavascriptBridge() {
  // https://github.com/Lision/WKWebViewJavascriptBridge
  connectWKWebViewJavascriptBridge(bridge => {
    iOSBridge = bridge;
    const SwiftWebViewBridgeReady = new CustomEvent('SwiftWebViewBridgeReady');
    document.dispatchEvent(SwiftWebViewBridgeReady);
  });
}

// old bridge
function connectLegacyWebViewBridge(callback) {
  if (!callback) {
    return;
  }
  if (window.SwiftWebViewBridge) {
    callback(SwiftWebViewBridge);
  } else {
    document.addEventListener(
      'SwiftWebViewBridgeReady',
      function () {
        callback(SwiftWebViewBridge);
      },
      false
    );
  }
}

export function initLegacyIOSBridge() {
  // MARK: Call the Initial Setup function and add in appropriate listeners
  connectLegacyWebViewBridge(bridge => {
    bridge.init((message, responseCallback) => responseCallback(null));
  });
}

export function emitIOSAppBootedEvent() {
  if (appHasAppBootedSuccessfullyHandler()) {
    try {
      callIOSHandler('appBootedSuccessfully', null, null);
    } catch (err) {
      console.log('Error booting IOS DOMContentLoaded:', err);
    }
  }
}

export function emitAndroidAppBootedEvent() {
  try {
    NativeApp.appBootedSuccessfully();
  } catch (err) {
    console.log('Error booting Android DOMContentLoaded:', err);
  }
}
